import * as React from 'react'
function SvgNewLine(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='1em' height='1em' version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1280 1280' {...props}>
      <g transform='translate(0.000000,1280.000000) scale(0.100000,-0.100000)' fill='currentColor' stroke='none'>
        <path d='M10880 8320 l0 -1920 -3200 0 -3200 0 0 1280 0 1280 -1915 -1596 c-1053 -878 -1915 -1599 -1915 -1604 0 -5 862 -726 1915 -1604 l1915 -1596 0 1280 0 1280 3840 0 3840 0 0 2560 0 2560 -640 0 -640 0 0 -1920z' />
      </g>
    </svg>
  )
}
const NewLineIcon = React.memo(SvgNewLine)
export default NewLineIcon
