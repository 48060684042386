import { FC } from 'react'
import Order from 'src/components/account/tabs/order/Order'
import { CenteredContent } from 'src/sdk/components/layout'
import { IDrawerDetailView } from '../DrawerDetailProvider'

const OrderDrawer: FC<IDrawerDetailView> = ({ id }) => (
  <CenteredContent>
    <Order id={id} />
  </CenteredContent>
)

export default OrderDrawer
