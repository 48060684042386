import { Image as Wrapped, ImageProps } from 'antd'
import { FC, useEffect, useState } from 'react'
import { withPlaceholder } from '../image'
import { ReactSVG } from 'react-svg'
import './Image.less'
import { withPrefix } from 'src/sdk/contexts/Config'
import { SectionLoader } from '../loader'

export type ImageCmpProps = Omit<ImageProps, 'src'> & {
  src?: string | Promise<string>
  type?: Design.PlaceholderIcon
}

const ImageExists = async (src: string | Promise<string> | undefined) => {
  return await Promise.resolve(src).then((src: any) => {
    return new Promise<string>((resolve, reject) => {
      if (!src) reject()
      const resolvedSrc = src.default ? src.default : src
      const image = new window.Image()
      image.src = resolvedSrc
      if (image.complete) {
        return resolve(resolvedSrc)
      }
      image.onload = () => resolve(resolvedSrc)
      image.onerror = () => reject(resolvedSrc)
    })
  })
}

const Image: FC<ImageCmpProps> = ({ src, alt, placeholder, type = 'empty', preview = false, ...baseProps }) => {
  const [fulfilledSrc, setFulfilledSrc] = useState<string>()
  const [fulfilledPlaceholderSrc, setFulfilledPlaceholderSrc] = useState<string>()
  const [using, setUsing] = useState<'src' | 'placeholder'>('src')

  useEffect(() => {
    // Try loading the original source
    ImageExists(src)
      .then(setFulfilledSrc)
      .catch(() => {
        // If original source fails - load placeholder and set as source
        ImageExists(withPlaceholder(type))
          .then((src) => {
            src && setFulfilledSrc(src)
            setUsing('placeholder')
          })
          .catch((src) => {
            // If type was overridden and failed
            Promise.resolve(withPlaceholder('empty')).then(setFulfilledPlaceholderSrc)
          })
      })

    // Try loading the placeholder
    ImageExists(withPlaceholder(type))
      .then((src) => {
        setFulfilledPlaceholderSrc(src)
      })
      .catch(() => {
        // If type was overridden and failed
        Promise.resolve(withPlaceholder('empty')).then(setFulfilledPlaceholderSrc)
      })
  }, [])
  return (
    <Wrapped
      className={withPrefix(`image-${using}`)}
      preview={preview}
      alt={fulfilledSrc ? alt : undefined}
      src={fulfilledSrc}
      onError={() => fulfilledSrc}
      placeholder={
        placeholder ? (
          placeholder
        ) : fulfilledPlaceholderSrc ? (
          <ReactSVG src={fulfilledPlaceholderSrc} />
        ) : (
          <SectionLoader />
        )
      }
      {...baseProps}
    />
  )
}

export { Image, ImageExists }
