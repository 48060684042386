import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr'
import Cookies from 'js-cookie'
import {
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { HubName, HubPublish, HubResponse } from '../datasource/hubs/hub'
import { toCamelCase, toSnakeCase } from '../helpers/utils'
import useBase64 from '../hooks/useBase64'
const HubEnabled = false

export type HubState = {
  connected: boolean
  connecting: boolean
}

export type SubscribeHandler<T> = (response: HubResponse<T>) => void

type HandlerState = {
  hubName: HubName
  handler: SubscribeHandler<any>
}

type HubContextProps = {
  hubState: HubState
  subscribe: (hubName: HubName, handle: SubscribeHandler<any | undefined>) => void
  unSubscribe: (hubName: HubName) => void
  publish: (publish: HubPublish) => Promise<void>
  queue: HandlerState[]
}

const defaultState: HubState = {
  connected: false,
  connecting: false,
}

const defaultValues: HubContextProps = {
  hubState: defaultState,
  // connect: () => {},
  subscribe: (hubName, handler) => {},
  unSubscribe: () => {},
  publish: (publish) => {
    return new Promise<void>(() => {})
  },
  queue: [],
}

const Hub = createContext<HubContextProps>(defaultValues)

const GetWSToken = () => {
  const { decode } = useBase64()
  const cookie = Cookies.get('pv.ws')

  return cookie ? decode(cookie) : ''
}

function HubProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  // const connectionUrl = `${window.location.origin}/api/hubs`
  const connectionUrl = 'https://localhost:5001/api/hubs'
  const [hubState, setHubState] = useState<HubState>(defaultState)
  const [monitoring, setMonitoring] = useState(false)
  const [runningQueue, setRunningQueue] = useState(false)
  const [subscribeHandlers, setSubscribeHandlers] = useState<HandlerState[]>([])
  const [queue, setQueue] = useState<HandlerState[]>([])

  const [connection] = useState<HubConnection>(
    new HubConnectionBuilder()
      .withUrl(connectionUrl, {
        skipNegotiation: false,
        transport: HttpTransportType.WebSockets,
        accessTokenFactory: GetWSToken,
        // 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlIjoiQ3VzdG9tZXIiLCJjdXN0b21lcl9ubyI6IjI2OTc3NzEiLCJjb21wYW55X25vIjoiMTA4NyIsInNlc3Npb25faWQiOiJlNTAxMTZmMS1hZjg0LTQyMzgtODliMS02M2ZjZjQzODJhYTQiLCJyX2V4cCI6Ijg2NDAwIiwidG9rZW5fdHlwZSI6ImN1c3RvbWVyIiwibmJmIjoxNjU3MDQ1ODQyLCJleHAiOjE2NTcwNDc2NDIsImlhdCI6MTY1NzA0NTg0MiwiaXNzIjoiUGVvcGxlVmluZSJ9.ppCzYOYpHkZPq7-L4NehAFdN6LNYCRIwQk247H7pTIU',
      })
      .withAutomaticReconnect()
      .build(),
  )

  function disconnected() {
    setHubState({
      connected: false,
      connecting: false,
    })
  }

  function connected() {
    setHubState({
      connected: true,
      connecting: false,
    })
  }

  function connecting() {
    setHubState({
      connected: false,
      connecting: true,
    })
  }

  const getQueue = () => {
    return queue
  }

  const connect = () => {
    return new Promise<HubConnection>((resolve, reject) => {
      if (hubState.connected || connection?.state === HubConnectionState.Connected) return resolve(connection)

      if (connection.state !== HubConnectionState.Disconnected) return

      connecting()
      // Update state when reconnecting
      connection?.onreconnecting(() => connecting())
      // Update state when reconnected
      connection?.onreconnected(() => connected())
      // Update state when connection is closed
      connection?.onclose(() => disconnected())

      connection
        ?.start()
        .then(connected)
        .then(() => {
          console.log('Connected... Connection ID: ', connection.connectionId)
          resolve(connection)
          // monitorQueue()
        })
        .catch((error) => {
          console.log('Hub Connection Error: ', error)
          disconnected()
          reject()
        })
    })
  }

  const subscribe = (hubName: HubName, handler: SubscribeHandler<any>) => {
    if (!HubEnabled) return
    setQueue((prevState) => [
      ...prevState,
      {
        hubName: hubName,
        handler: handler,
      } as HandlerState,
    ])
  }

  const attachHandlers = () => {
    const queueItem = (qItem: HandlerState) => {
      return new Promise((resolve, reject) => {
        if (connection?.state !== HubConnectionState.Connected) reject()

        if (!subscribeHandlers?.find((handler) => handler.hubName === qItem.hubName)) {
          // Subscribe and set handler
          connection
            ?.invoke('Subscribe', qItem.hubName)
            .then(() => {
              connection.on(`${qItem.hubName}`, (response: HubResponse<any>) => {
                qItem.handler(toCamelCase(response))
              })
              setQueue((prevState) => [...prevState.filter((item) => item.hubName === qItem.hubName)])
              setSubscribeHandlers((prevState) => [...prevState, qItem])
              resolve(true)
            })
            .catch((err) => {
              console.log('hub error', err)
              reject()
            })
        } else {
          // Already subscribed, only set handler
          connection?.on(`${qItem.hubName}`, (response: HubResponse<any>) => {
            qItem.handler(toCamelCase(response))
          })
          setQueue((prevState) => [...prevState.filter((item) => item.hubName === qItem.hubName)])
          setSubscribeHandlers((prevState) => [...prevState, qItem])
          resolve(true)
        }
      })
    }

    if (queue.length > 0) {
      Promise.all(queue.map(queueItem)).finally(() => setRunningQueue(false))
    }
  }

  const unSubscribe = (hubName: HubName) => {
    if (!hubState.connected || !connection) return
    connection.invoke('Unsubscribe', hubName).then((response) => {
      setSubscribeHandlers(subscribeHandlers.filter((handler) => handler.hubName !== hubName))
    })
  }

  const publish = (publish: HubPublish) => {
    return new Promise<void>((resolve, reject) => {
      if (!hubState.connected || !connection) return reject('Not Connected')
      const data = toSnakeCase(publish)
      console.log(data)
      return connection.send(publish.eventName, data).then(resolve).catch(reject)
    })
  }

  useEffect(() => {
    if (HubEnabled) connect()
  }, [])

  useEffect(() => {
    const interval = setInterval(attachHandlers, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [queue])

  return (
    <Hub.Provider
      value={{
        hubState,
        subscribe,
        unSubscribe,
        publish,
        queue,
      }}
    >
      {children}
    </Hub.Provider>
  )
}

const useHub: () => HubContextProps = () => useContext(Hub)

export { HubProvider, useHub }
