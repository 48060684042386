import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'
export interface DrawerState {
    key: string
    visible: boolean
    allowMultiple?: boolean
    parentKey?: string
}
const drawerSlice = createSlice({
    name: 'drawer',
    initialState: {
        drawers: [] as DrawerState[]
    },
    reducers: {
        addDrawer(state, action: { payload: DrawerState }) {
            state.drawers.push(action.payload)
        },
        updateDrawer(state, action: { payload: DrawerState }) {
            const index = state.drawers.findIndex((drawer) => drawer.key === action.payload.key)
            if (index !== -1) {
                if (action.payload.visible) {
                    state.drawers.filter(d => !d.allowMultiple && d.visible).forEach((drawer, i) => {
                        if (i !== index && drawer.key !== action.payload.parentKey) {
                            drawer.visible = false
                        }
                    })
                } else if (!action.payload.visible) {
                    state.drawers.filter(d => d.parentKey === action.payload.key).forEach((drawer, i) => {
                        drawer.visible = false
                    })
                }
                state.drawers[index] = action.payload
            }
        }
    },
})

export const { addDrawer, updateDrawer } = drawerSlice.actions
export const drawersSelector = (state: RootState) => state.drawer.drawers
export default drawerSlice.reducer
