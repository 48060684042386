import { RestEndpoint } from '@rest-hooks/rest'
import { Modal, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useController } from 'rest-hooks'
import { TransactionList } from 'src/components/account/tabs/order/TransactionList'
import { RouteMap } from 'src/containers/RouteMap'
import { Button } from 'src/sdk/components/form'
import { CenteredContent, VerticalSpace } from 'src/sdk/components/layout'
import { OverlayLoader } from 'src/sdk/components/loader'
import { ModalFunc } from 'src/sdk/components/modal/Modal'
import { Money } from 'src/sdk/components/text'
import { DynamicDrawerProvider, useDynamicDrawer } from 'src/sdk/contexts/DynamicDrawer'
import { useNotification } from 'src/sdk/contexts/Notification'
import { AccountAppointment, AccountReservation, CancelBookingResponse } from 'src/sdk/datasource/account/account'
import { ScheduleType } from 'src/sdk/datasource/scheduler'
import { Capitalize } from 'src/sdk/helpers/strings'

type BookingCancelProps = {
  bookingId: Data.ID
  type: ScheduleType
}

type BookingCancelModalProps = {
  id: Data.ID
  type: ScheduleType
  onClose?: () => void
  onLoading?: (loading: boolean) => void
}

const BookingCancelModal: FC<BookingCancelModalProps> = ({ id, type, onClose, onLoading }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [loading, setLoading] = useState(false)
  const [cancelledResponse, setCancelledResponse] = useState<CancelBookingResponse>()

  const cancelRequest = () => {
    onLoading && onLoading(true)
    setLoading(true)
    let endpoint: RestEndpoint
    switch (type) {
      case 'appointment':
        endpoint = AccountAppointment.cancel()
        break
      case 'reservation':
        endpoint = AccountReservation.cancel()
        break
    }
    fetch(endpoint, id)
      .then((response) => {
        setCancelledResponse(response)
      })
      .catch((error) => {
        notifyOnError({
          ...error,
          error: 'Cancel Error',
        })
        throw error
      })
      .finally(() => {
        onLoading && onLoading(false)
        setLoading(false)
      })
  }

  const CancelConfirm = () => (
    <VerticalSpace size={'large'} className='cancel-dialog'>
      <Typography.Title level={5} type={'secondary'}>
        Are you sure you want to cancel this {type}?
      </Typography.Title>
      <Button block type={'ghost'} disabled={loading} onClick={onClose}>
        Dismiss
      </Button>
      <Button block loading={loading} theme={'error'} onClick={cancelRequest}>
        Cancel {Capitalize(type)}
      </Button>
    </VerticalSpace>
  )

  const CancelComplete: FC<Data.Source<CancelBookingResponse>> = ({ data }) => {
    const { amount, orderId, refunds, success, message } = data

    return (
      <VerticalSpace>
        {success ? (
          <Typography.Title level={5} type={'secondary'}>
            {type === 'reservation'
              ? data.reservation
                ? `Your ${data.reservation.title} reservation has been cancelled`
                : 'Your reservation has been cancelled'
              : data.appointment
              ? `Your ${data.appointment.title} appointment has been cancelled`
              : 'Your appointment has been cancelled'}
          </Typography.Title>
        ) : (
          <Typography.Title level={5} type={'danger'}>
            {message}
          </Typography.Title>
        )}

        {amount > 0 && (
          <Typography.Text>
            You will receive a total refund of <Money>{amount}</Money>
          </Typography.Text>
        )}

        <TransactionList data={refunds} />
        {orderId && (
          <Button block type={'ghost'}>
            <Link onClick={onClose} to={`${RouteMap.accountOrders}/${orderId}`}>
              View Order Details
            </Link>
          </Button>
        )}
      </VerticalSpace>
    )
  }

  return (
    <OverlayLoader loading={loading}>
      {cancelledResponse ? <CancelComplete data={cancelledResponse} /> : <CancelConfirm />}
    </OverlayLoader>
  )
}

const BookingCancelButton: FC<BookingCancelProps> = ({ bookingId, type }) => {
  const { setModal, setModalVisible } = useDynamicDrawer()
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    setModal({
      content: (
        <CenteredContent>
          <BookingCancelModal
            id={bookingId}
            type={type}
            onClose={() => setModalVisible(false)}
            onLoading={setLoading}
          />
        </CenteredContent>
      ),
      centered: true,
      footer: null,
      closable: !loading,
      maskClosable: !loading,
    })
  }
  return (
    <Button block theme={'error'} onClick={handleClick}>
      Cancel {Capitalize(type)}
    </Button>
  )
}

export default BookingCancelButton
