import { Form as AbstractForm } from 'antd'
import { FormInstance, FormProps, Rule } from 'antd/lib/form'
import { useEffect } from 'react'
import './Form.less'

export type FormRuleProps<T> = {
  [key in keyof T]: Rule[]
}

type Props<T> = FormProps<T> & {
  onValidate?: (valid: boolean, values: T) => void
}

function Form<T>({ onValidate, onChange, form, ...props }: Props<T>) {
  const handleChange = () => {
    onValidate &&
      form
        ?.validateFields()
        .then((values) => {
          onValidate(true, values)
        })
        .catch((err) => {
          const values = form?.getFieldsValue()
          values && onValidate(false, values)
        })
  }

  // Trigger validation after initial values are set
  useEffect(() => {
    if (props.initialValues) {
      form?.resetFields()
      handleChange()
    }
  }, [props.initialValues])

  return (
    <AbstractForm<T>
      form={form}
      onChange={(evt) => {
        // Trigger original onChange event
        onChange && onChange(evt)
        // Trigger custom onChange event
        handleChange()
      }}
      size={'middle'}
      {...props}
    >
      {props.children}
    </AbstractForm>
  )
}

export { Form }
