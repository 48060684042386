import { combineReducers } from '@reduxjs/toolkit'
import app from './appSlice'
import checkout from './checkoutSlice'
import chat from './chatSlice'
import drawer from './drawerSlice'

const rootReducer = combineReducers({
  app,
  checkout,
  drawer,
  chat
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
