import moment from 'moment'

export const getMediaUrl = (url = ''): string => {
  if (url !== '' && !url.startsWith('http') && !url.startsWith('https')) {
    url = url.startsWith('/') ? `/media${url}` : `/media/${url}`
  }
  return url
}

type DataConditionType = 'string' | 'number' | 'date'
type DataConditionCompare = '>' | '>=' | '<' | '<=' | '===' | '!==' | 'includes' | 'empty'

export type DataCondition = {
  condition: DataConditionCompare
  property: string
  value: any
  type?: DataConditionType
  or?: DataCondition
}

export const compareData = (data: any, conditions: DataCondition[]) => {
  const convertType = (value: any, type?: DataConditionType) => {
    if (!type) return value
    switch (type) {
      case 'date':
        return moment(value)
      case 'number':
        return Number(value)
      case 'string':
        return String(value)
    }
  }

  function compareItem(condition: DataCondition) {
    const property = condition.property.split('.')
    if (data[property[0]] === undefined) return false
    const value = convertType(data[property[0]], condition.type)
    const comapreValue = convertType(condition.value, condition.type)
    switch (condition.condition) {
      case '<':
        return condition.or ? compareItem(condition.or) || value < comapreValue : value < comapreValue
      case '<=':
        return condition.or ? compareItem(condition.or) || value <= comapreValue : value <= comapreValue
      case '>':
        return condition.or ? compareItem(condition.or) || value > comapreValue : value > comapreValue
      case '>=':
        return condition.or ? compareItem(condition.or) || value >= comapreValue : value >= comapreValue
      case '===':
        return condition.or ? compareItem(condition.or) || value === comapreValue : value === comapreValue
      case '===':
        return condition.or ? compareItem(condition.or) || value !== comapreValue : value !== comapreValue
      case 'includes':
        if (Array.isArray(comapreValue) && Array.isArray(value)) {
          return condition.or
            ? compareItem(condition.or) ||
                value.some((v) => {
                  const value = property.length > 1 ? v[property[1]] : v
                  if (value === undefined) return false
                  return comapreValue.includes(value)
                })
            : value.some((v) => {
                const value = property.length > 1 ? v[property[1]] : v
                if (value === undefined) return false
                return comapreValue.includes(value)
              })
        } else if (Array.isArray(comapreValue)) {
          return condition.or ? compareItem(condition.or) || comapreValue.includes(value) : comapreValue.includes(value)
        } else if (Array.isArray(value)) {
          return condition.or ? compareItem(condition.or) || value.includes(comapreValue) : value.includes(comapreValue)
        } else {
          return false
        }
      case 'empty':
        return typeof value === 'object' && value.length === 0
      default:
        return value === comapreValue
    }
  }

  return conditions.every((item) => {
    return compareItem(item)
  })
}
