import { CSSProperties, FC } from 'react'
import { ReactSVG } from 'react-svg'
import { withPrefix } from 'src/sdk/contexts/Config'
import { PaymentMethodBrand } from 'src/sdk/datasource/config'
import IvyIcon from '../icon'
import { HorizontalSpace } from '../layout'
import { Flex } from '../layout/Grid'
import './PaymentBrand.less'

type PaymentBrandType = 'full' | 'small'

export const PaymentBrands = [
  'alipay',
  'amex',
  'barclays-bank-plc',
  'diners',
  'discover',
  'elo',
  'hiper',
  'hipercard',
  'jcb',
  'maestro',
  'mastercard',
  'mastercard-icon',
  'paypal',
  'paypal-icon',
  'unionpay',
  'visa',
  'visa-icon',
  'ally-bank',
  'bank-of-america',
  'truist',
  'bbva-compass',
  'capital-one-bank',
  'citibank',
  'citizens-bank',
  'fifth-third-bank',
  'hsbc-bank-usa',
  'jp-morgan-chase-bank',
  'keybank',
  'navy-federal-credit-union',
  'pnc-bank',
  'regions-bank',
  'td-bank',
  'us-bank',
  'wells-fargo-bank',
] as const

export type PaymentBrandProps = {
  type?: PaymentBrandType
  brand: PaymentMethodBrand
  color?: 'default' | 'white'
  wrapperStye?: React.CSSProperties
  fallbackType?: 'creditcard' | 'bank'
  size?: number
}
const PaymentBrand: FC<PaymentBrandProps> = ({
  brand,
  wrapperStye,
  color = 'default',
  type = 'full',
  fallbackType,
  size,
}) => {
  const cleanedBrand = brand?.split(',')[0].replaceAll(' ', '-').toLowerCase() as PaymentMethodBrand
  const brandPath = `/assets/filled/payment-brand/${cleanedBrand}`
  const className = withPrefix(`payment-brand${color !== 'default' ? ` payment-brand-${color}` : ''}`)
  const source = `${brandPath}${type === 'small' ? '-icon' : ''}.svg`

  return PaymentBrands.includes(cleanedBrand) ? (
    <ReactSVG width={size} height={size} className={className} style={wrapperStye} src={source} />
  ) : (
    <IvyIcon size={size} type={`application/${fallbackType as Design.ApplicationType}`} />
  )
}

export type PaymentBrandListProps = {
  brands: PaymentMethodBrand[]
  style?: CSSProperties
} & Omit<PaymentBrandProps, 'brand'>

const PaymentBrandList: FC<PaymentBrandListProps> = ({ brands, style, ...props }) => {
  return (
    <Flex direction={'horizontal'} align={'center'} className={withPrefix('payment-brand-list')} style={style}>
      {brands.map((brand) => {
        const type = brand === 'visa' || brand === 'amex' ? 'full' : 'small'
        return <PaymentBrand key={brand} brand={brand} type={type} {...props} />
      })}
    </Flex>
  )
}

export { PaymentBrand, PaymentBrandList }
