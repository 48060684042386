import { FC } from 'react'
import { Descriptions as Wrapper, DescriptionsProps } from 'antd'
import './Descriptions.less'
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item'

const Descriptions = ({ ...props }: DescriptionsProps) => {
  return Wrapper({ ...props })
}

const DescriptionsItem: FC<DescriptionsItemProps> = ({ ...props }) => {
  return <Wrapper.Item {...props} />
}
export { Descriptions as default, DescriptionsItem }
