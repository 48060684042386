import { Entity } from "@rest-hooks/rest"

class Passbook extends Entity {
    pk(parent?: any, key?: string | undefined): string | undefined {
        throw new Error("Method not implemented.")
    }
    readonly appleWallet: string = ''
    readonly googleWallet: string = ''
    readonly wallet: string = ''
}

export { Passbook }