import { FC, lazy, useEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { ApiErrorBoundary } from 'src/sdk/contexts/ApiErrorBoundary'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useAuth } from '../sdk/contexts/Auth'
import { RouteMap } from './RouteMap'
import { AuthenticatedRoutes } from './routing/AuthenticatedRoutes'

export const Routing: FC = () => {
  const location = useLocation()
  const { authenticated, logout } = useAuth()

  useEffect(() => {
    // Notify app that main view is ready
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'done' }))
  }, [])

  useEffect(() => {
    document.body.classList.remove(withPrefix('scrolling-effect'))
    document.body.style.overflowY = ''
  }, [location])

  return (
    <ApiErrorBoundary pathname={location.pathname}>
      {authenticated ? (
        <AuthenticatedRoutes />
      ) : (
        <Switch>
          <Route exact path={RouteMap.userLogout} render={logout} />,
          <Route
            exact
            path={RouteMap.generalError}
            component={lazy(() => import('../components/errors/GeneralError'))}
          />
          <Route
            exact
            path={RouteMap.passbookDownload}
            component={lazy(() => import('../components/passbook/PassDownload'))}
          />
          <Route
            exact
            path={RouteMap.forgotPassword}
            component={lazy(() => import('../components/user/password/reset/ForgotPassword'))}
          />
          <Route
            exact
            path={RouteMap.guestPassShare}
            component={lazy(() => import('../components/public/guest-pass/GuestPass'))}
          />
          <Route
            exact
            path={RouteMap.guestTicket}
            component={lazy(() => import('../components/public/guest-ticket/GuestTicket'))}
          />
          <Route
            exact
            path={RouteMap.guestVoucher}
            component={lazy(() => import('../components/public/guest-voucher/GuestVoucher'))}
          />
          <Route
            path={`${RouteMap.userPasswordReset}/:id`}
            component={lazy(() => import('../components/user/password/reset/PasswordReset'))}
          />
          <Route
            exact
            path={`${RouteMap.register}`}
            component={lazy(() => import('../components/user/CreateAccount'))}
          />
          <Route
            exact
            path={RouteMap.activate}
            component={lazy(() => import('../components/user/activate/verify/VerifyEmailCode'))}
          />
          <Route
            path={`${RouteMap.verifyEmail}/:id?`}
            component={lazy(() => import('../components/user/verifyEmail/VerifyEmail'))}
          />
          <Route
            exact
            path={RouteMap.calendarRedirect}
            component={lazy(() => import('../components/redirect/CalendarRedirect'))}
          />
          <Route exact path={RouteMap.actions} component={lazy(() => import('../components/actions/Actions'))} />
          <Route
            path={['/survey/:id', '/surveys/:id', '/form/:id']}
            component={lazy(() => import('../components/surveys/SurveyView'))}
          />
          <Route path={['/schedule/*']} component={lazy(() => import('../components/public/vanity/VanityRoute'))} />
          <Route exact path={`/privacy`} component={lazy(() => import(`../components/privacy/PrivacyPolicy`))} />
          <Route exact path={`/terms`} component={lazy(() => import(`src/components/index/Terms`))} />
          <Route exact path={`/cookie`} component={lazy(() => import(`src/components/index/Cookie`))} />
          <Route exact path={`/faq`} component={lazy(() => import(`src/components/faq/Faq`))} />
          <Route path={['*', '/login']} component={lazy(() => import('../components/user/Login'))} />
        </Switch>
      )}
    </ApiErrorBoundary>
  )
}
