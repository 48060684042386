import { ReactText } from 'react'

export { Avatar } from './Avatar'
export { Image } from './Image'

// export type PlaceholderType =
//   | 'account-perks'
//   | 'dining-desserts-item'
//   | 'dining-drinks-item'
//   | 'dining-fish'
//   | 'dining-mains'
//   | 'dining-meat'
//   | 'dining-starters'
//   | 'directory-company'
//   | 'directory-member-female'
//   | 'directory-member-male'
//   | 'event-items'
//   | 'reservation-rooms'
//   | 'shop-clothes'
//   | 'empty'
//   | 'shop-items'
/**
 'desserts': Desserts,
 'drinks': Drinks,
 'fish and seafood': Seafood,
 'mains': Mains,
 'meat': Meat,
 'branded clothes': Clothes,
 'branded items': Items,
 'starters': Starters,
 */

export type PlaceholderProps = {
  src?: string | Promise<string>
  placeholderSrc?: string | Promise<string>
  text?: ReactText
}

export const withPlaceholder = (placeholder: Design.PlaceholderIcon): Promise<string> => {
  return import(`src/sdk/images/placeholders/${placeholder}.svg`)
}
