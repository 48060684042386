import { FC } from 'react'
import classNames from 'classnames'
import { LinkProps } from 'antd/lib/typography/Link'
import { Link } from 'react-router-dom'
import { TextLink } from './TextLink'
import { FormatPhoneNumber } from 'src/sdk/helpers/strings'

type PhoneLinkProps = {
  title?: string
  phoneNumber?: string
} & Pick<LinkProps, 'target' | 'underline' | 'strong' | 'className'>

export const PhoneLink: FC<PhoneLinkProps> = ({ title, phoneNumber, className = '', underline, strong, children }) => {
  className = classNames({
    'ant-link-underline': underline,
    'ant-link-strong': strong,
    'ant-typography-link': true,
  }).concat(className)

  return (
    <a className={className} href={`tel:${phoneNumber ? phoneNumber : title}`}>
      {title ? title : phoneNumber && FormatPhoneNumber(phoneNumber)}
    </a>
  )
}
