import { List } from 'antd'
import { ListItemMetaProps } from 'antd/lib/list'
import { FC } from 'react'
import IvyIcon from 'src/sdk/components/icon'
import { ProductResource } from 'src/sdk/datasource/product'

const OrderProductDescription: FC<Data.Source<ProductResource>> = ({ data }) => {
  const details: ListItemMetaProps[] = [
    data.name
      ? {
          description: data.name,
          avatar: <IvyIcon size={15} type={'application/shopping'} />,
        }
      : {},
  ]

  return (
    <List
      style={{ width: '100%' }}
      bordered={false}
      split={false}
      dataSource={details}
      renderItem={(item) => (
        <List.Item style={{ padding: '2px 0px', border: 'none' }}>
          <List.Item.Meta style={{ marginBottom: 0 }} {...item} />
        </List.Item>
      )}
    />
  )
}

export default OrderProductDescription
