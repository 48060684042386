import { AutoComplete as Wrapper, AutoCompleteProps, Typography } from 'antd'
import { FC, ReactNode, useEffect } from 'react'
import { useState } from 'react'
import { useHistory } from 'react-router'
import { withPrefix } from 'src/sdk/contexts/Config'
import { CategoryEntity } from 'src/sdk/datasource/category'
import { Item } from '../form'
import './Search.less'

export type SearchProps<T> = {
  placeholder?: string
  hideResults?: boolean
  label?: string
  onSearch: (search: string) => Promise<Data.Searchable[]>
  afterSearch?: (data: T[]) => void
  urlPrefix?: string
  mapping: {
    title: string
    urlParam: ((data: T) => string) | string
  }
}

function Search<T = Data.Searchable>({
  placeholder = 'Search...',
  hideResults,
  onSearch,
  afterSearch,
  urlPrefix,
  mapping,
  label,
}: SearchProps<T>) {
  const history = useHistory()
  const [data, setData] = useState<Data.Searchable[]>()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [dataList, setDataList] = useState<any[]>([])

  const handleChange = (value) => {
    if (!value || value.trim() === '') {
      setDataList([])
    }
  }

  const handleSearch = (searchQuery: string) => {
    if (!searchQuery) return
    setLoading(true)
    onSearch(searchQuery)
      .then((response) => {
        afterSearch && afterSearch(response)
        return response
      })
      .then(setData)
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (!data) return
    if (data.length === 0) {
      if (hideResults) {
        return
      }
      setDataList([
        {
          label: <Typography.Text type={'danger'}>No matches found</Typography.Text>,
        },
      ])
      return
    }
    const categories = CategoryEntity.extract(data)
    const uncategorized = data
      .filter((item) => item.categories.length === 0)
      .map((item) => {
        const title = Object.keys(item).includes(mapping.title) ? item[mapping.title] : ''
        let value = ''
        if (typeof mapping.urlParam === 'string') {
          value = mapping.urlParam && Object.keys(item).includes(mapping.urlParam) ? item[mapping.urlParam] : ''
        } else {
          value = mapping.urlParam(item)
        }

        return {
          label: title,
          value: value,
        }
      })

    const options = categories.map((category) => {
      return {
        label: <Typography.Text type={'secondary'}>{category.name}</Typography.Text>,
        options: data
          .filter((item) => item.categories.find((it: CategoryEntity) => it.name === category.name) !== undefined)
          .map((item) => {
            const title = Object.keys(item).includes(mapping.title) ? item[mapping.title] : ''
            let value = ''
            if (typeof mapping.urlParam === 'string') {
              value = Object.keys(item).includes(mapping.urlParam) ? item[mapping.urlParam] : ''
            } else {
              value = mapping.urlParam(item)
            }

            return {
              key: `${category.name}-${item.id}`,
              title: title,
              value: value,
              label: title,
            }
          }),
      }
    })

    setDataList([...options, ...uncategorized])
  }, [data])

  useEffect(() => {
    setOpen(dataList.length > 0)
  }, [dataList])

  return (
    <Item label={label} className={withPrefix('autocomplete-wrapper')}>
      <Wrapper
        options={dataList}
        open={dataList.length > 0 && open}
        onChange={handleChange}
        onSearch={handleSearch}
        onFocus={() => {
          setOpen(true)
        }}
        onBlur={() => {
          setOpen(false)
        }}
        onSelect={(value) => {
          history.push(`${urlPrefix}/${value}`)
        }}
      />
    </Item>
  )
}

type IAutoComplete<T> = {
  label?: string
} & AutoCompleteProps

function AutoComplete<T>({ label, ...props }: IAutoComplete<T>) {
  return (
    <Item label={label} className={withPrefix('autocomplete-wrapper')}>
      <Wrapper {...props} />
    </Item>
  )
}

export { Search, AutoComplete }
