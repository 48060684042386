import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer'
import moment from 'moment'
import { FC } from 'react'
import { IPdfDocument } from 'src/sdk/components/pdf/DownloadPdfButton'
import { PDFelement } from 'src/sdk/components/pdf/PdfElement'
import { OrderItem } from 'src/sdk/datasource/order'
import { StatementEntity, TransactionEntity } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'

const { Styles, HorizontalLine, TextItem, Table, TableRow, TableHeading, TableData, Vertical, Horizontal } = PDFelement

interface IStatementProps extends IPdfDocument {
  statement: StatementEntity
  transactions?: TransactionEntity[]
  logo?: string
}

export const StatementPDF: FC<IStatementProps> = ({
  statement,
  transactions,
  dateFormatter,
  intlFormatter,
  company,
  logo,
}) => {
  const customer = transactions?.[0]?.customer
  const billingAddress = transactions?.[0]?.billingAddress
  Font.register({
    family: 'Jost',
    src: 'https://fonts.gstatic.com/s/jost/v6/92zPtBhPNqw79Ij1E865zBUv7mwjIgVG.ttf',
  })
  Font.register({
    family: 'Roboto',
    src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Me5Q.ttf',
  })
  Font.register({
    family: 'Roboto-Heavy',
    src: 'https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmWUlvAw.ttf',
  })

  const BorderedTitle: FC<{ title: string }> = ({ title }) => (
    <Horizontal align={'center'} width={'100%'}>
      <Text style={Styles.lineTitle}>{title}</Text>
      <View style={{ height: 2, backgroundColor: '#000000', flex: '1 1 auto' }} />
    </Horizontal>
  )

  const OrderItemDetail: FC<Data.Source<OrderItem>> = ({ data }) => {
    return (
      <Horizontal key={data.id} gap={24}>
        {data.type !== 'product' && <TextItem label>{Capitalize(data.type)}:</TextItem>}
        <TextItem>{data.title}</TextItem>
      </Horizontal>
    )
  }

  const TransactionStatus = (status: string, title?: string) => {
    switch (status) {
      case 'processed':
        return 'Paid'
      case 'invoiced':
        return 'Due'
      case 'refunded':
        return 'Refunded'
      default:
        return ''
    }
  }
  const ItemDescription: FC<Data.Source<TransactionEntity>> = ({ data }) => {
    if (data.type === 'order' && data.order) {
      let title = `Order #${data.order.id}`
      switch (data.order.via) {
        case 'connectedpos':
          title = 'Dining'
          break
        case 'pms':
          title = 'Hotel'
          break
        case 'spa':
          title = 'Spa'
          break
      }
      if (data.order.businessLocation) {
        title = `${title} at ${data.order.businessLocation.name}`
      }
      return (
        <Vertical>
          <TextItem label>{title}</TextItem>
          <Vertical gap={16}>
            {data.order.items
              .filter((i) => i.price > 0)
              .map((item) => (
                <OrderItemDetail key={item.id} data={item} />
              ))}
          </Vertical>
        </Vertical>
      )
    }
    let description: string = data.type.replace('_', ' ')
    if (data.description) {
      description = data.description
    } else if (data.data && data.data.some((d) => d.referenceType === data.type)) {
      description = data.data.find((d) => d.referenceType === data.type)?.referenceItem ?? description
    } else if (data.orderId) {
      description = `Order #${data.orderId}`
    }
    if (description === '') {
      description = data.type
    }
    return <TextItem>{Capitalize(description)}</TextItem>
  }

  const RefundTransactionRow: FC<Data.Source<TransactionEntity>> = ({ data }) => (
    <TableRow key={data.id}>
      <TableData width={'15%'}>
        <TextItem>{dateFormatter(data.createdOn, { formatMatcher: 'best fit' })}</TextItem>
      </TableData>
      <TableData width={'15%'}>
        <TextItem>{TransactionStatus(data.status)}</TextItem>
      </TableData>
      <TableData width={'55%'}>
        <Vertical>
          <TextItem>Transaction ID: {data.id}</TextItem>
          <TextItem>
            A refund in the amount of {intlFormatter.currency(data.amount)} was issued to {Capitalize(data.brand)} **
            {data.lastFour}
          </TextItem>
        </Vertical>
      </TableData>
      <TableData width={'15%'}>
        <TextItem>{intlFormatter.currency(data.amount)}</TextItem>
      </TableData>
    </TableRow>
  )

  const RefundsAndCredits = () => {
    const refunds = transactions
      ?.map((t) => t.linked)
      .flat()
      .filter((t) => t.status === 'refunded')
    return refunds && refunds.length > 0 ? (
      <Vertical style={{ marginTop: 16 }}>
        <BorderedTitle title={'Refunds & Credits'} />
        <Table>
          <TableRow>
            <TableHeading width={'15%'}>
              <Text>Date</Text>
            </TableHeading>
            <TableHeading width={'15%'}>
              <Text>Status</Text>
            </TableHeading>
            <TableHeading width={'55%'}>
              <Text>Description</Text>
            </TableHeading>
            <TableHeading width={'15%'}>
              <Text>Amount</Text>
            </TableHeading>
          </TableRow>
          {refunds?.map((t) => (
            <RefundTransactionRow key={t.id} data={t} />
          ))}
          <TableRow>
            <TableData width={'15%'} />
            <TableData width={'15%'} />
            <TableData width={'55%'}>
              <TextItem label size={'12px'} align={'right'}>
                Total
              </TextItem>
            </TableData>
            <TableData width={'15%'}>
              <TextItem label size={'12px'}>
                {intlFormatter.currency(statement.refunded)}
              </TextItem>
            </TableData>
          </TableRow>
        </Table>
      </Vertical>
    ) : null
  }

  const InvoiceTransactionRow: FC<Data.Source<TransactionEntity>> = ({ data }) => (
    <TableRow key={data.id}>
      <TableData width={'15%'}>
        <TextItem>{dateFormatter(`${data.chargedOn}Z`, { formatMatcher: 'best fit' })}</TextItem>
      </TableData>
      <TableData width={'15%'}>
        <TextItem>{TransactionStatus(data.status)}</TextItem>
      </TableData>
      <TableData width={'55%'}>
        <ItemDescription data={data} />
      </TableData>
      <TableData width={'15%'}>
        <TextItem>{intlFormatter.currency(data.amount)}</TextItem>
      </TableData>
    </TableRow>
  )

  const OpenInvoices = () => {
    const invoices = transactions?.filter((t) => t.status === 'invoiced' && t.amountDue > 0)
    return invoices && invoices.length > 0 ? (
      <Vertical style={{ marginTop: 16 }}>
        <BorderedTitle title={'House Account Open Balance'} />
        <Table>
          <TableRow>
            <TableHeading width={'15%'}>
              <Text>Date</Text>
            </TableHeading>
            <TableHeading width={'15%'}>
              <Text>Status</Text>
            </TableHeading>
            <TableHeading width={'55%'}>
              <Text>Description</Text>
            </TableHeading>
            <TableHeading width={'15%'}>
              <Text>Amount</Text>
            </TableHeading>
          </TableRow>
          {invoices?.map((t) => (
            <InvoiceTransactionRow key={t.id} data={t} />
          ))}
          <TableRow>
            <TableData width={'15%'} />
            <TableData width={'15%'} />
            <TableData width={'55%'}>
              <TextItem label size={'12px'} align={'right'}>
                Total
              </TextItem>
            </TableData>
            <TableData width={'15%'}>
              <TextItem label size={'12px'}>
                {intlFormatter.currency(statement.periodDue)}
              </TextItem>
            </TableData>
          </TableRow>
        </Table>
      </Vertical>
    ) : null
  }

  const TransactionRow: FC<Data.Source<TransactionEntity>> = ({ data }) => (
    <TableRow key={data.id}>
      <TableData width={'15%'}>
        <TextItem>{dateFormatter(`${data.chargedOn}Z`, { formatMatcher: 'best fit' })}</TextItem>
      </TableData>
      <TableData width={'15%'}>
        <TextItem>{TransactionStatus(data.status)}</TextItem>
      </TableData>
      <TableData width={'35%'}>
        <View>
          <ItemDescription data={data} />
        </View>
      </TableData>
      <TableData width={'20%'}>
        <TextItem>
          {data.paymentType === 'invoice' ? 'House Account' : `${Capitalize(data.brand)} **${data.lastFour}`}
        </TextItem>
      </TableData>
      <TableData width={'15%'}>
        <TextItem>{intlFormatter.currency(data.amount)}</TextItem>
      </TableData>
    </TableRow>
  )

  const HeaderRow: FC = () => {
    return (
      <Vertical width={'100%'}>
        <Horizontal width={'100%'} justify={'space-between'}>
          <Vertical>
            {logo && (
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignContent: 'flex-start',
                  maxWidth: 150,
                  height: 50,
                }}
              >
                <Image
                  source={logo}
                  style={{ height: '100%', width: '100%', objectFit: 'contain', objectPosition: 'top' }}
                />
              </View>
            )}
            <Vertical>
              <TextItem label size={'10px'}>
                {company.name}
              </TextItem>
              <TextItem>{company.address}</TextItem>
              <TextItem>
                {company.city}, {company.state} {company.zipCode}
              </TextItem>
            </Vertical>
            {customer && (
              <Vertical style={{ marginTop: 16 }}>
                <TextItem label size='10px'>
                  Billed To
                </TextItem>
                <TextItem label size={'10px'}>
                  {customer.fullName}
                </TextItem>
                {billingAddress && (
                  <>
                    <TextItem>{billingAddress.address}</TextItem>
                    <TextItem>
                      {billingAddress.city}, {billingAddress.state} {billingAddress.zipCode}
                    </TextItem>
                  </>
                )}
              </Vertical>
            )}
          </Vertical>

          <Vertical width={'30%'} gap={16}>
            <TextItem>
              {moment(statement.startDate).format('MMMM Do YYYY')} through{' '}
              {moment(statement.endDate).format('MMMM Do YYYY')}
            </TextItem>
            <HorizontalLine width={'100%'} />
            <TextItem label size='10px'>
              Statement Summary
            </TextItem>
            <HorizontalLine width={'100%'} />
            <Horizontal justify={'space-between'}>
              <TextItem label>Total Transactions:</TextItem>
              <TextItem>{statement.count}</TextItem>
            </Horizontal>

            <Horizontal justify={'space-between'}>
              <TextItem label>Charges Processed:</TextItem>
              <TextItem>{intlFormatter.currency(statement.charged)}</TextItem>
            </Horizontal>

            <Horizontal justify={'space-between'}>
              <TextItem label>Refunds:</TextItem>
              <TextItem>{intlFormatter.currency(statement.refunded * -1)}</TextItem>
            </Horizontal>

            <Horizontal justify={'space-between'}>
              <TextItem label>Total Charges this Period:</TextItem>
              <TextItem>{intlFormatter.currency(statement.total)}</TextItem>
            </Horizontal>

            <Horizontal justify={'space-between'}>
              <TextItem label>Amount Due This Period:</TextItem>
              <TextItem>{intlFormatter.currency(statement.periodDue)}</TextItem>
            </Horizontal>
            <Horizontal justify={'space-between'}>
              <TextItem label>Previous Outstanding Balance:</TextItem>
              <TextItem>{intlFormatter.currency(statement.totalDue - statement.periodDue)}</TextItem>
            </Horizontal>
            <Horizontal justify={'space-between'}>
              <TextItem label>Total Outstanding Balance:</TextItem>
              <TextItem>{intlFormatter.currency(statement.totalDue)}</TextItem>
            </Horizontal>
          </Vertical>
        </Horizontal>
      </Vertical>
    )
  }

  const TransactionTable: FC = () => (
    // <Vertical style={{ marginTop: 16 }} width={'100%'}>
    <View>
      <View>
        <BorderedTitle title={'Charges'} />
      </View>
      <View>
        <Table>
          <TableRow>
            <TableHeading width={'15%'}>
              <Text>Date</Text>
            </TableHeading>
            <TableHeading width={'15%'}>
              <Text>Status</Text>
            </TableHeading>
            <TableHeading width={'35%'}>
              <Text>Description</Text>
            </TableHeading>
            <TableHeading width={'20%'}>
              <Text>Payment Type</Text>
            </TableHeading>
            <TableHeading width={'15%'}>
              <Text>Amount</Text>
            </TableHeading>
          </TableRow>
          {transactions
            ?.filter((t) => t.status !== 'invoiced' && t.status !== 'refunded')
            ?.map((item) => (
              <TransactionRow key={item.id} data={item} />
            ))}
          <TableRow>
            <TableData width={'15%'} />
            <TableData width={'15%'} />
            <TableData width={'35%'} />
            <TableData width={'20%'}>
              <TextItem label size={'12px'}>
                Total
              </TextItem>
            </TableData>
            <TableData width={'15%'}>
              <TextItem label size={'12px'}>
                {intlFormatter.currency(statement.charged)}
              </TextItem>
            </TableData>
          </TableRow>
        </Table>
      </View>
    </View>
  )

  return (
    <Document>
      <Page size='A4' style={Styles.body}>
        <Vertical>
          <Vertical>
            <HeaderRow />
            <Vertical style={{ marginTop: 16 }}>
              <Text style={Styles.title}>
                {statement.startMonth()} {statement.startYear()} Statement
              </Text>
              <TransactionTable />
              <RefundsAndCredits />
              <OpenInvoices />
            </Vertical>
          </Vertical>
        </Vertical>
        <Text
          style={Styles.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed
        />
      </Page>
    </Document>
  )
}
