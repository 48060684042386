import { Typography } from 'antd'
import { FC, ReactNode } from 'react'
import { BaseAddress } from 'src/sdk/datasource/address'
import { TextProps } from 'antd/lib/typography/Text'
import { HorizontalSpace, VerticalSpace } from '../layout'

type AddressProps = {
  title?: string | ReactNode
  justify?: 'center' | 'left' | 'right' | 'space-between'
} & Data.Source<BaseAddress> &
  TextProps

export const Address: FC<AddressProps> = ({ data, title, justify, ...props }) => (
  <VerticalSpace size={0} justify={justify}>
    {title &&
      (typeof title === 'string' ? (
        <Typography.Title style={{ marginBottom: 0 }} level={5}>
          {title}
        </Typography.Title>
      ) : (
        title
      ))}
    {data.address && (
      <Typography.Text {...props}>
        <HorizontalSpace justify={justify}>
          {data.address}
          {data.address2 && `| ${data.address2}`}
        </HorizontalSpace>
      </Typography.Text>
    )}
    {data.address3 && <Typography.Text {...props}>{data.address3}</Typography.Text>}
    <Typography.Text {...props}>
      {data.city}
      {data.state && `, ${data.state}`} {data.zipCode}
    </Typography.Text>
  </VerticalSpace>
)
