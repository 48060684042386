import { Checkbox as Wrapped, CheckboxProps } from 'antd'
import { FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import './Checkbox.less'

const Checkbox: FC<CheckboxProps> = (props) => {
  return <Wrapped className={withPrefix('custom-checkbox')} {...props} />
}

export { Checkbox }
