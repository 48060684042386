import { Col, Drawer, Layout, Row } from 'antd'
import { FC, useEffect, useState } from 'react'
import MediaQuery from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'
import IvyIcon from 'src/sdk/components/icon'
import { usePublicConfig, withPrefix } from '../../contexts/Config'
import Logo from '../logo'
import Navigation from '../menu'
import { Breakpoint, BreakpointMax, BreakpointMin } from '../screen/Breakpoint'
import { Flex, HorizontalSpace } from './Grid'
import { Sider } from './Sider'
import { useMediaQuery } from 'react-responsive'

import './Header.less'
import useLogoType from 'src/sdk/hooks/useLogoType'
import { useNativeApp } from 'src/sdk/contexts/NativeApp'
import { appSelector, setMenuDrawerVisible } from 'src/sdk/store/appSlice'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'src/sdk/store'

const HorizontalMenu: FC = () => {
  const {
    props: {
      navigation: { mode, theme, logo },
    },
  } = usePublicConfig()
  const logoType = useLogoType()
  return (
    <>
      <MediaQuery minWidth={BreakpointMin.XL}>
        <Row justify={'space-between'} align={'middle'} wrap={false}>
          <Col push={1}>
            <Link to={'/'} style={{ overflow: 'hidden' }}>
              <Logo
                type={mode === 'horizontal' ? 'icon' : logoType?.logo?.expanded ?? 'icon+text'}
                dark={theme === 'dark'}
              />
            </Link>
          </Col>
          <Col>
            <Navigation />
          </Col>
        </Row>
      </MediaQuery>
      <MediaQuery maxWidth={Breakpoint.XL}>
        <PageHeader />
      </MediaQuery>
    </>
  )
}

type HeaderProps = { mode: 'horizontal' | 'burger' | 'vertical' }

const PageHeader: FC = () => {
  const appState = useSelector(appSelector)
  const dispatch = useAppDispatch()
  const { subscribe } = useNativeApp()
  const { plan } = usePublicConfig()
  const isDesktop = useMediaQuery({ minWidth: Breakpoint.MD })
  const { pathname } = useLocation()
  const [scrolled, setScrolled] = useState(false)
  const logoType = useLogoType()

  const closeMenu = () => dispatch(setMenuDrawerVisible(false))

  useEffect(() => {
    closeMenu()
  }, [pathname])

  function shrinkOnScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
    setScrolled(scrollTop > 0)
  }

  useEffect(() => {
    subscribe({
      event: 'OPEN_MENU',
      callback: () => {
        dispatch(setMenuDrawerVisible(true))
      },
    })
    subscribe({
      event: 'CLOSE_MENU',
      callback: () => {
        dispatch(setMenuDrawerVisible(false))
      },
    })

    window.addEventListener('scroll', shrinkOnScroll)

    return () => {
      window.removeEventListener('scroll', shrinkOnScroll)
    }
  }, [])

  return (
    <Layout.Header
      className={withPrefix('layout-header-wrapper', scrolled ? 'layout-header-wrapper-scrolled' : '')}
      data-cy={'header'}
    >
      <Flex
        direction={'horizontal'}
        align={'center'}
        justify={'space-between'}
        className={withPrefix('layout-header-nav')}
      >
        {plan === 'Plus' ? (
          <>
            <IvyIcon
              size={32}
              type={'custom/burger'}
              className={withPrefix('layout-header-nav-item')}
              onClick={() => dispatch(setMenuDrawerVisible(!appState.menuDrawerVisible))}
            />
            <Link to={'/'} className={withPrefix('layout-header-nav-item', 'layout-header-nav-item-logo')}>
              <Logo type={logoType?.logo?.expanded} height={'100%'} width={'100%'} />
            </Link>
          </>
        ) : (
          <Link to={'/'}>
            <Logo type={logoType?.logo?.expanded ?? 'icon'} width={32} />
          </Link>
        )}
        <HorizontalSpace style={{ width: 'auto' }} className={withPrefix('layout-header-nav-item')}>
          <Link data-cy={'notifications'} to={`/notifications`} style={{ display: 'flex', justifyContent: 'end' }}>
            <IvyIcon color={'default'} size={32} type={'application/bell'} />
          </Link>
          <Link data-cy={'notifications'} to={`/my-schedule`} style={{ display: 'flex' }}>
            <IvyIcon color={'default'} className='header-schedule' size={32} type={'custom/calendar'} />
          </Link>
        </HorizontalSpace>
      </Flex>
      <Drawer
        destroyOnClose={true}
        bodyStyle={{ padding: 0 }}
        placement={'left'}
        onClose={closeMenu}
        visible={appState.menuDrawerVisible}
        width={isDesktop ? '40%' : '80%'}
        className={withPrefix('drawer-mobile-sider')}
      >
        <Layout hasSider={true}>
          <Sider id={'mobile-menu'} width={'100%'} collapsed={false} collapsible={false} />
        </Layout>
      </Drawer>
    </Layout.Header>
  )
}

const Header: FC<HeaderProps> = ({ mode }) => {
  if (mode === 'vertical') {
    return (
      <MediaQuery maxWidth={BreakpointMax.SM}>
        <PageHeader />
      </MediaQuery>
    )
  }
  return mode === 'burger' ? <PageHeader /> : <HorizontalMenu />
}

export { Header }
