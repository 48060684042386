import { List, Space, Typography } from 'antd'
import React, { CSSProperties, FC, MouseEventHandler, ReactNode, useEffect } from 'react'
import { useSet } from 'react-use'
import { OptionsProps, Tag } from './Tag'

export type TagListItemProps = Data.Identified &
  Data.Named &
  Partial<OptionsProps> &
  Partial<{ style: CSSProperties }> &
  Partial<{ icon: FC; checked: boolean; onClick: MouseEventHandler }>

export const TagListItemMiddleShaped: FC<Partial<TagListItemProps>> = (props) => (
  <TagListItem shape={'asymmetric'} size={'middle'} checkable {...props} />
)
export const TagListItemMiddleShapedUncheckable: FC<Partial<TagListItemProps>> = (props) => (
  <TagListItem shape={'asymmetric'} type={'default'} size={'middle'} checkable={false} {...props} />
)
export const TagListItem: FC<Partial<TagListItemProps>> = ({
  checked = false,
  name = '',
  onClick = undefined,
  size,
  style,
  shape,
  type = 'default',
  checkable = true,
  children,
}) => (
  <List.Item style={{ marginBottom: 0 }} onClick={onClick}>
    <Tag style={style} isChecked={checked} checkable={checkable} shape={shape} type={type} size={size} title={name}>
      {!children ? (
        <Space direction={'horizontal'}>
          {name && (
            <Typography.Text strong type={'secondary'}>
              {name}
            </Typography.Text>
          )}
        </Space>
      ) : (
        children
      )}
    </Tag>
  </List.Item>
)

type TagListProps = Data.Named & {
  header: ReactNode
  dataSource: TagListItemProps[]
  renderItem: (item: TagListItemProps) => ReactNode
  tagProps: Partial<TagListItemProps>
  onChange: (id: React.Key, checked: boolean) => void
  checkedTags: Set<React.Key>
}

export const TagList: FC<Partial<TagListProps>> = ({
  header,
  dataSource = [],
  renderItem = undefined,
  tagProps,
  onChange = () => undefined,
  checkedTags,
}) => {
  const [, { add, has, remove }] = useSet(checkedTags)

  const handleTagClick = (id: React.Key) => {
    if (has(id)) {
      remove(id)
      onChange(id, false)
    } else {
      add(id)
      onChange(id, true)
    }
  }

  return (
    <List
      split={false}
      header={header}
      grid={{ gutter: 4 }}
      bordered={false}
      dataSource={dataSource}
      itemLayout={'horizontal'}
      renderItem={
        renderItem ??
        ((item) => (
          <TagListItem
            name={item.name}
            key={item.id}
            checked={checkedTags?.has(item.id)}
            onClick={() => handleTagClick(item.id)}
            style={{ marginBottom: 12 }}
            {...tagProps}
          >
            <Space direction={'horizontal'}>
              {item.icon}
              {item.name}
            </Space>
          </TagListItem>
        ))
      }
    />
  )
}
