import { createContext, PropsWithChildren, ReactElement, ReactNode, useContext } from 'react'
import { JSEncrypt } from 'jsencrypt'
import snakecaseKeys from 'snakecase-keys'
import { usePublicConfig } from './Config'

type TokenizeContext = {
  tokenize: (data: any, serialize?: boolean) => string | null
}

const defaultValues: TokenizeContext = {
  tokenize: (value) => value,
}

const Tokenize = createContext<TokenizeContext>(defaultValues)

function TokenizeProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const { key } = usePublicConfig()

  const publicKey = () => {
    var token = '-----BEGIN RSA PUBLIC KEY-----\n'
    token += key.replace(/(.{64})/g, '$1\n')
    token += '\n-----END RSA PUBLIC KEY-----'
    return token
  }

  const tokenize = (data: any, serialize: boolean = true) => {
    let value: string = ''
    if (typeof data === 'object') {
      if (serialize) {
        // Convert camelCase to snake_case
        value = JSON.stringify(snakecaseKeys(data, { deep: true }))
      } else {
        value = JSON.stringify(data)
      }
    } else {
      value = data
    }
    const encrypt = new JSEncrypt()
    encrypt.setPublicKey(publicKey())
    const encrypted = encrypt.encrypt(value)
    if (!encrypted) return null

    return `pv_${btoa(atob(encrypted).padStart(256, '\0'))}`
  }

  return (
    <Tokenize.Provider
      value={{
        tokenize,
      }}
    >
      {children}
    </Tokenize.Provider>
  )
}

const useTokenize: () => TokenizeContext = () => useContext(Tokenize)

export { TokenizeProvider, useTokenize }
