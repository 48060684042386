import { ApiErrorItem } from './error'

export class GeneralResponse {
  readonly id?: Data.ID
  readonly success: boolean = false
  readonly message: string = ''
}

export class DeleteResponse<T> {
  readonly id: Data.ID = 0
  readonly success: boolean = false
  readonly message: string = ''
  readonly code: string = ''
  readonly error?: ApiErrorItem
  readonly data?: T
}
