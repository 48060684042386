import { EndpointInstance } from "@rest-hooks/rest";
import { loadStripe } from "@stripe/stripe-js";
import { Endpoint, useController } from "rest-hooks";
import { IPaymentResponse, PaymentMethod, PaymentResponse } from 'src/sdk/datasource/payment';
import { ApiError } from "../api";
import { ApiResource } from "../datasource/entity";

type PaymentRequestParam = PaymentMethod | PaymentMethod[]

export interface IPaymentRequest {
    id?: Data.ID
    paymentMethod: PaymentRequestParam
}

interface IPaymentEndpoint {
    url: string | ((params: IPaymentRequest) => string),
    wrapper?: string,
    schema?: any
}

export class PaymentError {
    code?: string
    referenceId?: Data.ID
    name = 'PaymentError'

    constructor(errorCode?: string, id?: Data.ID) {
        this.code = errorCode
        this.referenceId = id
    }
}

export function usePaymentEndpoint<R extends IPaymentResponse = PaymentResponse>() {
    const { fetch } = useController()

    const handle3dSecure = async (publicStripeToken: string, paymentIntentToken: string) => {
        return loadStripe(publicStripeToken).then((stripe) => {
            return stripe?.handleCardAction(paymentIntentToken)
                .then(({ error, paymentIntent }) => {
                    if (error || !paymentIntent || !paymentIntent?.id) {
                        throw new ApiError(400, {
                            error: 'payment_authentication_failed',
                            title: 'Payment Error',
                            error_description: error
                                ? error.message
                                : 'There was a problem processing this payment',
                        })
                    }
                    return paymentIntent.id
                })
        })
    }

    function createPaymentEndpoint({ url, wrapper, schema }: IPaymentEndpoint) {
        return new Endpoint(
            (params: IPaymentRequest) => {
                return ApiResource.fetch(typeof url === 'string' ? url : url(params), {
                    method: 'POST',
                    body: JSON.stringify(params)
                }).then<R>()
            }, {
            wrapper: wrapper,
            schema: schema
        }
        )
    }

    async function submitPayment<
        E extends EndpointInstance<(params: IPaymentRequest) => Promise<R>, undefined, undefined> & {
            wrapper?: string | undefined;
        }
    >(endpoint: E, params: IPaymentRequest) {

        const handleReMapping = (paymentIntentId: string, methods: PaymentRequestParam) => {
            if (Array.isArray(methods)) {
                return methods.map((method) => {
                    return (method.type === 'creditcard' || method.type === 'newcreditcard' ? {
                        ...method,
                        id: paymentIntentId,
                        type: 'paymentIntent'
                    } : {
                        ...method
                    })
                }) as PaymentMethod[]
            } else {
                return {
                    ...methods,
                    id: paymentIntentId,
                    type: 'paymentIntent'
                } as PaymentMethod
            }
        }

        const pay = async (methods: PaymentRequestParam) => {
            const p: Parameters<E> = endpoint.wrapper ? [{ [endpoint.wrapper]: methods }] : [{ ...methods }] as any

            return new Promise<R>((resolve, reject) => {
                return fetch(endpoint, ...p)
                    .then<R>()
                    .then(response => {
                        if (!response.success) {
                            if (response.action?.code === '3ds_auth_required') {
                                const clientSecret = response.action?.clientSecret as string
                                const publicToken = response.action?.token as string
                                // const clientSecret = response.error.referenceId as string
                                return handle3dSecure(publicToken, clientSecret)
                                    .then((paymentIntentId) => {
                                        if (paymentIntentId) {
                                            const mapping = handleReMapping(paymentIntentId, methods)
                                            return pay(mapping).then(resolve)
                                        } else {
                                            reject(new ApiError(400, {
                                                code: 'payment_authentication_failed',
                                                title: 'Payment Error',
                                                error_description: 'There was a problem processing this payment'
                                            }))
                                        }
                                    }).catch(reject)
                            } else {
                                reject()
                            }

                        } else {
                            resolve(response)
                        }
                    }).catch(reject)
            })
        }

        return pay(params.paymentMethod)
    }

    return {
        createPaymentEndpoint,
        submitPayment
    }
}