import { List } from 'antd'
import { ListItemMetaProps } from 'antd/lib/list'
import { FC } from 'react'
import LocalFormattedDate from 'src/sdk/components/datetime/LocalFormattedDate'
import LocalFormattedTime from 'src/sdk/components/datetime/LocalFormattedTime'
import IvyIcon from 'src/sdk/components/icon'
import { AccountTicket } from 'src/sdk/datasource/account/account'

const OrderTicketDescription: FC<Data.Source<AccountTicket>> = ({ data }) => {
  const details: ListItemMetaProps[] = [
    data.title
      ? {
          description: data.title,
          avatar: <IvyIcon size={15} type={'custom/tickets'} />,
        }
      : {},
    {
      description: <LocalFormattedDate value={data.startDateLocal} dateStyle={'full'} type={'secondary'} />,
      avatar: <IvyIcon size={15} type={'custom/calendar'} />,
    },
    {
      description: <LocalFormattedTime value={data.startDateLocal} type={'secondary'} />,
      avatar: <IvyIcon size={15} type={'symbol/clock-circle'} />,
    },
  ]

  return (
    <List
      style={{ width: '100%' }}
      bordered={false}
      split={false}
      dataSource={details}
      renderItem={(item) => (
        <List.Item style={{ padding: '2px 0px', border: 'none' }}>
          <List.Item.Meta style={{ marginBottom: 0 }} {...item} />
        </List.Item>
      )}
    />
  )
}

export default OrderTicketDescription
