import { FC } from 'react'
import { TextLink } from './TextLink'

type WebsiteLinkProps = {
  title?: string
  link: string
  style?: React.CSSProperties
}

export const WebsiteLink: FC<WebsiteLinkProps> = ({ link, title = '', style }) =>
  link ? (
    <TextLink href={link} style={style}>
      {title || link}
    </TextLink>
  ) : null
