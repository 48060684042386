import { DrawerType } from '../DrawerDetailProvider'
import BookingDrawer from './BookingDrawer'
import OrderDrawer from './OrderDrawer'
import StatementDrawer from './StatementDrawer'
import TransactionDrawer from './TransactionDrawer'

const ResolveDrawer = (id: Data.ID, type: DrawerType) => {
  switch (type) {
    case 'order':
      return OrderDrawer
    case 'transaction':
      return TransactionDrawer
    case 'appointment':
      return BookingDrawer
    case 'reservation':
      return BookingDrawer
    case 'statement':
      return StatementDrawer
  }
}
export { ResolveDrawer, OrderDrawer, TransactionDrawer }
