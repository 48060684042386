import { DrawerProps } from 'antd/lib/drawer'
import { FC, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { SubTitle } from 'src/sdk/components/text'
import { withPrefix } from 'src/sdk/contexts/Config'
import './index.less'

export type DrawerCallbackProps = {
  onSuccess?: (data: {}) => void
  onError?: () => void
}

type RightSideDrawerProps = {
  size?: 'small' | 'medium' | 'large'
} & DrawerProps

const withRightSideDrawer =
  (Wrapped: FC<RightSideDrawerProps>): FC<RightSideDrawerProps> =>
  ({ title, size = 'small', afterVisibleChange, children, className, ...baseProps }) => {
    const windowSize = useWindowSize()
    useEffect(() => {
      if (!baseProps.visible) document.body.classList.remove(withPrefix('scrolling-effect'))
    }, [windowSize])

    return (
      <Wrapped
        title={title && <SubTitle level={2}>{title}</SubTitle>}
        contentWrapperStyle={{ scrollbarWidth: 'none' }}
        placement={'right'}
        mask={baseProps.mask ?? false}
        className={withPrefix(`drawer-right-${size}`, className ? className : '')}
        width={''}
        destroyOnClose
        afterVisibleChange={(visible) => {
          afterVisibleChange && afterVisibleChange(visible)
          if (visible) {
            document.body.classList.add(withPrefix('scrolling-effect'))
          } else {
            document.body.classList.remove(withPrefix('scrolling-effect'))
          }
        }}
        {...baseProps}
      >
        {children}
      </Wrapped>
    )
  }
const withLeftSideDrawer =
  (Wrapped: FC<DrawerProps>): FC<DrawerProps> =>
  ({ ...baseProps }) =>
    <Wrapped placement={'left'} width={''} mask={false} {...baseProps} />

const withFullScreenDrawer =
  (Wrapped: FC<DrawerProps>): FC<DrawerProps> =>
  ({ ...baseProps }) => {
    return <Wrapped placement={'top'} height={'100vh'} width={'100%'} {...baseProps} />
  }

export { withRightSideDrawer, withLeftSideDrawer, withFullScreenDrawer }
