import { FC, useEffect } from 'react'
import { Modal as AntModal, ModalProps as AntModalProps } from 'antd'
import { withPrefix } from 'src/sdk/contexts/Config'
import './Modal.less'

export type ModalProps = {
  blurMask?: boolean
} & AntModalProps

const Modal: FC<ModalProps> = ({ blurMask, afterClose, visible, maskStyle, zIndex, ...props }) => {
  useEffect(() => {
    if (visible === false) {
      document.body.classList.remove(withPrefix('scrolling-effect'))
      document.body.style.overflow = 'unset'
    }
  }, [visible])

  return (
    <AntModal
      zIndex={zIndex ?? 1001}
      visible={visible}
      maskStyle={{
        backdropFilter: blurMask ? 'blur(10px)' : undefined,
        zIndex: zIndex ?? 1001,
        ...maskStyle,
      }}
      {...props}
    />
  )
}
export { Modal as default, AntModal as ModalFunc }
