import { Divider, Layout } from 'antd'
import { CSSProperties, FC, ReactNode } from 'react'
import MediaQuery, { useMediaQuery } from 'react-responsive'
import { usePublicConfig, withPrefix } from '../../contexts/Config'
import { VerticalSpace } from '../layout'
import { Skeleton } from '../skeleton/Skeleton'
import { Header } from './Header'
import './Page.less'

export type PageProps = {
  className?: string
  style: CSSProperties
  extra: ReactNode
  suffixCls: string
  responsive: boolean
  width: string | number
  theme: 'light' | 'dark'
  Content: FC
  Sider: FC
  Header: FC
  Footer: FC
  siderWidth: number | string
  siderPosition: 'left' | 'right'
  breakpoint: number
  hasSiderMobile: boolean
  title: string | ReactNode
  titleBar?: ReactNode
  breadcrumbs: { [key: string]: string }
  loading?: boolean
}

export const Page: FC<Partial<PageProps & { id: string }>> = ({
  id,
  children,
  className = '',
  Content,
  Sider,
  Footer,
  responsive = false,
  extra,
  style,
  width = 1320,
  suffixCls = 'layout-content',
  theme = 'light',
  breakpoint = 1800,
  siderPosition = 'right',
  hasSiderMobile = true,
  siderWidth = 352,
  title,
  titleBar,
  breadcrumbs,
  loading = false,
}) => {
  const {
    plan,
    props: {
      navigation: { mode },
    },
  } = usePublicConfig()
  const contentPadding = `padding${siderPosition?.charAt(0).toUpperCase() + siderPosition?.slice(1)}`
  const isDesktop = useMediaQuery({ minWidth: breakpoint })
  const isMobile = useMediaQuery({ maxWidth: 576 })
  return (
    <Layout
      id={id}
      style={{
        width: '100%',
        maxWidth: responsive ? '100%' : width,
        // height: '100%',
        [contentPadding]: isDesktop && Sider ? siderWidth : 0,
        ...style,
      }}
      className={`${theme === 'dark' ? `inverse ${withPrefix('layout-dark')}` : ''} ${withPrefix(
        'page',
        'page-layout',
        `page-layout-${plan === 'Plus' ? mode : 'burger'}`,
        suffixCls && `page-layout-${suffixCls}`,
      )} ${className}`}
    >
      {loading ? (
        <Layout.Header className='page-header'>
          <Header loading title={<Skeleton width={225} height={55} />} parentBreadcrumb={breadcrumbs} extra={extra} />
        </Layout.Header>
      ) : (
        title && (
          <Layout.Header className={`page-header`}>
            <Header title={title} parentBreadcrumb={breadcrumbs} extra={extra} />
            {titleBar}
          </Layout.Header>
        )
      )}
      <Layout.Content className={withPrefix(suffixCls?.toLowerCase())}>
        {/* <VerticalSpace size={60}> */}
        {Content ? <Content>{children}</Content> : children}
        {hasSiderMobile && Sider ? (
          <MediaQuery maxWidth={breakpoint}>
            <Sider />
          </MediaQuery>
        ) : null}
        {/* </VerticalSpace> */}
      </Layout.Content>
      {Sider ? (
        <MediaQuery minWidth={breakpoint}>
          <Layout.Sider
            width={siderWidth}
            theme={'light'}
            style={{
              position: 'fixed',
              [siderPosition]: 0,
              zIndex: 2,
              overflow: 'auto',
              height: '100vh',
            }}
            className={withPrefix('page-sider').concat(' no-scrollbar')}
          >
            <Sider />
            <div style={{ height: 152 }} />
          </Layout.Sider>
        </MediaQuery>
      ) : null}
      {Footer && (
        <Layout.Footer style={{ padding: isMobile ? 24 : 40 }} className={withPrefix('page-footer')}>
          <Footer />
        </Layout.Footer>
      )}
    </Layout>
  )
}
