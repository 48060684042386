export const RemoveNullValues = (obj: Object) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null))
}

export const camelToSnakeCase = (obj: Object) =>
  Object.entries(obj).reduce((acc, [key, val]) => {
    const modifiedKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
    return {
      ...acc,
      ...{ [modifiedKey]: val },
    }
  }, {})

export const hasValidValue = (obj: Object) =>
  Object.values(obj).some((v) => {
    let valid = v !== undefined && v !== null

    if (valid) {
      if (v.constructor.name === 'Set') {
        valid = v.size > 0
      } else {
        if (Array.isArray(v)) {
          valid = v.length > 0
        }
      }
    }

    return valid
  })