import { FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Typography } from 'antd'
import { HorizontalSpace, VerticalSpace } from '../layout'
import IvyIcon from '../icon'

export type MarkerContentProps = {
  title?: string
  address?: string
  city?: string
  state?: string
  zipCode?: string
}

type MarkerProps = {
  id?: string | number
  lat: number
  lng: number
  text?: string
  content?: MarkerContentProps
  onClick?: () => void
}

const Marker: FC<MarkerProps> = ({ id, content, onClick }) => (
  <div key={id} className={withPrefix('google-maps-marker')}>
    {/* <IvyIcon size={32} color={'primary'} type={'custom/location'} /> */}
    <HorizontalSpace onClick={() => console.log('click')}>
      <IvyIcon size={32} color={'primary'} type={'custom/location'} />
      {content && (
        <VerticalSpace size={0} className={withPrefix('google-maps-marker-text')}>
          {content.title && (
            <Typography.Title style={{ marginBottom: 0 }} level={5}>
              {content.title}
            </Typography.Title>
          )}
          {/* {content.address && <Typography.Text strong>{content.address}</Typography.Text>}
          {content.city && <Typography.Text strong>{content.city}</Typography.Text>}
          {content.state && <Typography.Text strong>{content.state}</Typography.Text>}
          {content.zipCode && <Typography.Text strong>{content.zipCode}</Typography.Text>} */}
        </VerticalSpace>
      )}
    </HorizontalSpace>
  </div>
)

export default Marker
