import { useResource } from 'rest-hooks'
import { ColorKeys } from '../themes/default'
import { PropsConfig } from './config'
import { ApiResource } from './entity'

// public long Id { get; set; }
//         public PortalSettings Settings { get; set; } = new();
//         public PortalAnalytics Analytics { get; set; } = new();
//         public List<PortalPage> Pages { get; set; } = new();
//         public PortalProps Props { get; set; } = new();
//         public PortalIntl Intl { get; set; } = new();

export class DomainConfig {
  id: Data.ID = 0
  domainId: Data.ID = 0
  plan: 'Lounge' | 'Plus' = 'Plus'
  debug: boolean = false
  environment: string = 'US'
  token: string = ''
  analytics: any
  pages: any
  props: PropsConfig = new PropsConfig()
  theme?: { [key in ColorKeys]?: string | number } = {}
  intl: any
  settings: any
}

class DomainEntity extends ApiResource {
  static urlRoot = `/admin/api/domains`
  id: number = 0
  companyId: number = 0
  name: string = ''
  type: string = ''
  externalEmailId: string = ''
  externalDomainId: string = ''
  externalSslId: string = ''
  sslRegistered: boolean = false
  emailAuthenticated: boolean = false
  registered: boolean = false
  settings: any
  config?: DomainConfig
  portalConfig?: DomainConfig
  referenceType: string = ''
  referenceId: Data.ID = 0
  allowSsl: boolean = false
  fullUrl: string = ''
  default: boolean = false
  passwordProtected: boolean = false
  pageStyleId: Data.ID = 0
  paymentProcessorId: Data.ID = 0
  authenticatedPageStyleId: Data.ID = 0
  memberPageStyleId: Data.ID = 0
  environment: 'Production' | 'Stage' | 'QA' = 'Production'
  version: 3 | 4 = 4

  static getAll(params = {}): DomainEntity[] {
    return useResource(this.list(), params)
  }
}

export default DomainEntity
