import { ReactNode, useRef, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { ApiResource } from 'src/sdk/datasource/entity'
import { ButtonIcon } from '../form/Button'
import { HeaderActionBaseProps } from './DrawerDetailTypes'

export type PrintButtonProps<T> = {
  template: ReactNode
} & HeaderActionBaseProps

function PrintButton<T extends ApiResource>({ template, hidden }: PrintButtonProps<T>) {
  const [printing, setPrinting] = useState(false)

  const componentRef = useRef<HTMLDivElement>(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforePrint: () => setPrinting(true),
    onAfterPrint: () => {
      setPrinting(false)
    },
  })
  return (
    <>
      <ButtonIcon
        loading={printing}
        onClick={() => handlePrint()}
        icon={'application/printer'}
        children={
          <div className={'print-only'} ref={componentRef}>
            {template}
          </div>
        }
      />
    </>
  )
}

export { PrintButton }
