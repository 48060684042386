import { FC, lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useAccount } from 'src/sdk/contexts/Account'
import { useMenuConfig, usePublicConfig } from 'src/sdk/contexts/Config'
import { Result } from 'src/sdk/components/result/Result'
import { MappedRoutes, RouteMap } from '../RouteMap'

const LoungeRoutes: FC = () => {
  const { pages } = useMenuConfig()
  const { isActiveMember } = useAccount()

  return (
    <Switch>
      <Route exact path={['/login', '', '/']}>
        <Redirect to='/account' />
      </Route>
      <Route exact path={RouteMap.generalError} component={lazy(() => import('src/components/errors/GeneralError'))} />
      <Route
        exact
        path={RouteMap.passbookDownload}
        component={lazy(() => import('src/components/passbook/PassDownload'))}
      />
      <Route
        exact
        path={RouteMap.calendarRedirect}
        component={lazy(() => import('src/components/redirect/CalendarRedirect'))}
      />
      {MappedRoutes.filter((route) => !route.plusPlan).map((route) => {
        const customOverride = pages.find((p) => route.path.indexOf(p.uri) !== -1)
        const membersOnly = customOverride ? customOverride.membersOnly : route.membersOnly === true
        const key = Array.isArray(route.path) ? route.path[0] : route.path
        const uri = route.path
        return membersOnly ? (
          isActiveMember ? (
            <Route
              key={key}
              exact={route.exact}
              path={uri}
              component={lazy(() => import(`src/components/${route.component}`))}
            />
          ) : (
            <Route key={key} exact={route.exact} path={uri} component={Result.MembersOnly} />
          )
        ) : (
          <Route
            key={key}
            exact={route.exact}
            path={uri}
            component={lazy(() => import(`src/components/${route.component}`))}
          />
        )
      })}
      <Route key={'/'} path={'/'} component={lazy(() => import('src/components/account/Account'))} />
      <Route exact path={RouteMap.actions} component={lazy(() => import('src/components/actions/Actions'))} />
      <Route
        path={['/survey/:id', '/surveys/:id', '/form/:id']}
        component={lazy(() => import('src/components/surveys/SurveyView'))}
      />
      <Route path={'*'} component={Result.NotFound} />
    </Switch>
  )
}

const PlusRoutes: FC = () => {
  const { pages } = useMenuConfig()
  const { isActiveMember } = useAccount()

  return (
    <Switch>
      <Route exact path={'/login'}>
        <Redirect to='/' />
      </Route>
      <Route exact path={RouteMap.generalError} component={lazy(() => import('src/components/errors/GeneralError'))} />
      <Route
        exact
        path={RouteMap.passbookDownload}
        component={lazy(() => import('src/components/passbook/PassDownload'))}
      />
      <Route exact path={[RouteMap.guestPassShare, RouteMap.guestVoucher]}>
        <Redirect to={`${RouteMap.clubBenefits}/vouchers`} />
      </Route>
      <Route
        exact
        path={RouteMap.calendarRedirect}
        component={lazy(() => import('src/components/redirect/CalendarRedirect'))}
      />
      {MappedRoutes.map((route) => {
        let uri = route.path
        const split = route.path.split('/:')
        var basePath = split[0]
        const customOverride = pages.find((p) => p.uri === basePath)
        const membersOnly = customOverride ? customOverride.membersOnly : route.membersOnly === true
        const key = route.path
        if (customOverride) {
          if (!customOverride?.enabled) {
            return <Route key={key} exact={route.exact} path={uri} component={Result.NotFound} />
          }
          if (customOverride.path) {
            uri = customOverride.path
            if (split.length > 1) {
              uri += route.path.replace(basePath, '')
            }
          }
        }
        return membersOnly ? (
          isActiveMember ? (
            <Route
              key={key}
              exact={route.exact}
              path={uri}
              component={lazy(() => import(`src/components/${route.component}`))}
            />
          ) : (
            <Route key={key} exact={route.exact} path={uri} component={Result.MembersOnly} />
          )
        ) : (
          <Route
            key={key}
            exact={route.exact}
            path={uri}
            component={lazy(() => import(`src/components/${route.component}`))}
          />
        )
      })}
      <Route exact path={RouteMap.actions} component={lazy(() => import('src/components/actions/Actions'))} />
      <Route
        path={['/survey/:id', '/surveys/:id', '/form/:id']}
        component={lazy(() => import('src/components/surveys/SurveyView'))}
      />
      <Route component={Result.NotFound} />
    </Switch>
  )
}

const AuthenticatedRoutes: FC = () => {
  const { plan } = usePublicConfig()

  switch (plan) {
    case 'Plus':
      return <PlusRoutes />
    case 'Lounge':
      return <LoungeRoutes />
  }
}

export { AuthenticatedRoutes, LoungeRoutes, PlusRoutes }
