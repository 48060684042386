import { Space, Typography } from 'antd'
import moment from 'moment'
import { CSSProperties, FC } from 'react'
import { DateTz } from 'src/sdk/api'
import { HorizontalSpace } from '../layout'

interface LocalFormattedDateRange {
  from: DateTz
  to: DateTz
}

type LocalFormattedDateProps = {
  value: DateTz | LocalFormattedDateRange
  time?: boolean
  split?: string
  direction?: 'vertical' | 'horizontal'
  type?: 'secondary' | 'success' | 'warning' | 'danger'
  dateStyle?: 'full' | 'short'
  format?: string
  style?: CSSProperties
}
const LocalFormattedDate: FC<LocalFormattedDateProps> = ({
  value,
  direction = 'horizontal',
  split = '-',
  time = false,
  type,
  style,
  dateStyle,
  format,
}) => {
  const dateFormat = format
    ? format
    : dateStyle
    ? dateStyle === 'full'
      ? time
        ? 'dddd, MMMM Do YYYY \\a\\t h:mm A'
        : 'dddd, MMMM Do YYYY'
      : 'MMMM D, yyyy'
    : time
    ? 'MMMM D, yyyy \\a\\t h:mm A'
    : 'MMMM D, yyyy'

  const MultipleDates: FC<{ values: LocalFormattedDateRange }> = ({ values }) => {
    const from = DateTz.fromJS(values.from)
    const to = DateTz.fromJS(values.to)
    const sameDay = moment(from.date).isSame(to.date, 'day')

    return (
      <HorizontalSpace style={style}>
        {sameDay && !time && !format ? (
          <Typography.Text type={type}>{from.format(dateFormat)}</Typography.Text>
        ) : (
          <Space direction={direction} split={direction === 'horizontal' ? split : ''}>
            <Typography.Text type={type}>
              {from.format(dateFormat)}
              &nbsp;{direction === 'vertical' && split && !sameDay && split}
            </Typography.Text>
            {!sameDay && <Typography.Text type={type}>{to.format(dateFormat)}</Typography.Text>}
          </Space>
        )}
      </HorizontalSpace>
    )
  }

  const SingleDate: FC<{ value: DateTz }> = ({ value }) => {
    return <Typography.Text type={type}>{DateTz.fromJS(value).format(dateFormat)}</Typography.Text>
  }

  return 'to' in value ? <MultipleDates values={value} /> : <SingleDate value={value} />
}

export default LocalFormattedDate
