import { Drawer, Row, Spin, Typography } from 'antd'
import { DrawerProps } from 'antd/lib/drawer'
import { CSSProperties, FC, ReactNode } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { withFullScreenDrawer } from '../drawer'
import { Header } from '../page/Header'
import { Page } from '../page/Page'
import './Loading.less'

type LoaderProps = Partial<{
  loading: boolean
  justify?: 'start' | 'end' | 'center' | 'space-around' | 'space-between'
}>

const Spinner: FC<LoaderProps> = ({ loading = true }) => (
  <Row
    style={{ width: '100%', height: '100%', minHeight: '100vh', maxHeight: '100vh' }}
    align={'middle'}
    justify={'center'}
  >
    <Spin spinning={loading} size={'small'} />
  </Row>
)

const FullScreenDrawer: FC<DrawerProps> = withFullScreenDrawer(Drawer)

const MaskLoader: FC<LoaderProps> = ({ loading = true }) => (
  <FullScreenDrawer
    visible={loading}
    contentWrapperStyle={{ transition: 'none' }}
    maskStyle={{ backgroundColor: 'rgba(255,255,255,0.3)' }}
    closable={false}
    mask={true}
    maskClosable={false}
    aria-hidden={loading}
    className={'mask-loader'}
    style={{
      display: loading ? 'flex' : 'none',
    }}
  >
    <Spinner loading={loading} />
  </FullScreenDrawer>
)

const SectionLoader: FC<LoaderProps> = ({ loading = true, justify = 'center' }) => (
  <Row className={withPrefix('section-spinner')} align={'middle'} justify={justify}>
    <Spin spinning={loading} size={'small'} />
  </Row>
)

type OverlayLoaderProps = {
  fullScreen?: boolean
  style?: CSSProperties
} & LoaderProps
const OverlayLoader: FC<OverlayLoaderProps> = ({ fullScreen = false, loading = false, style, children }) => (
  <div
    className={withPrefix(
      'overlay-spinner',
      fullScreen ? 'overlay-spinner-full' : '',
      loading ? 'overlay-spinner-loading' : '',
    )}
    style={style}
  >
    <Spin spinning={loading} size={'small'} />
    {children}
  </div>
)

const SwiperLoader: FC<LoaderProps> = ({ loading = true }) => <Spinner loading={loading} />

const PageLoader: FC = () => (
  <Page responsive Header={() => <Header />} Content={() => null} Sider={() => null} Footer={() => null} />
)

interface IFullScreenLoader {
  loading: boolean
  text?: string | ReactNode
}
const FullScreenLoader: FC<IFullScreenLoader> = ({ loading, text }) => {
  return loading ? (
    <div className={withPrefix('ant-fullscreen-loader')}>
      <div className={withPrefix('ant-fullscreen-loader-container')}>
        <Spin spinning={loading} size={'large'} />
        {text &&
          (typeof text === 'string' ? (
            <Typography.Title level={4} type={'secondary'}>
              {text}
            </Typography.Title>
          ) : (
            { text }
          ))}
      </div>
    </div>
  ) : null
}

export { Spinner, MaskLoader, PageLoader, SectionLoader, SwiperLoader, OverlayLoader, FullScreenLoader }
