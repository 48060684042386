import { FormItemProps } from 'antd'
import React, { PropsWithChildren, ReactNode, FC, useState, useEffect, HTMLAttributes } from 'react'
import { useMediaQuery } from 'react-responsive'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Item } from '.'
import './CompactGroup.less'

const ChildWithProp = (
  child: ReactNode,
  props: Partial<any> & React.Attributes & HTMLAttributes<HTMLInputElement> = {},
) => {
  if (React.isValidElement(child)) {
    const children = child.props.children
    return React.cloneElement(child, { children: children, ...props })
  }
}

export type CompactItemProps = {
  width: 10 | 25 | 33 | 50 | 75 | 90 | 100
  collapseWidth?: number
  noWrapper?: boolean
} & FormItemProps

const CompactItem: FC<CompactItemProps> = ({ width, collapseWidth = 0, className, children, noWrapper, ...rest }) => {
  const [currentWidth, setCurrentWidth] = useState(width)
  const responsive = collapseWidth > 0
  const collapse = useMediaQuery({ maxWidth: collapseWidth })

  useEffect(() => {
    if (!responsive) return
    setCurrentWidth(collapse ? 100 : width)
  }, [collapse])

  return noWrapper ? (
    <>
      {ChildWithProp(children, {
        className: className,
        collapseWidth: collapseWidth,
        style: { width: `${currentWidth}%` },
      })}
    </>
  ) : (
    <Item className={className} style={{ width: `${currentWidth}%` }} {...rest}>
      {children}
    </Item>
  )
}

export type CompactGroupProps = {
  collapseWidth?: number
  children: PropsWithChildren<ReactNode>[]
} & Omit<FormItemProps, 'children'>

const CompactGroup: FC<CompactGroupProps> = ({ collapseWidth = 0, children }) => {
  var currentRow = 1
  var prevWidth = 0

  const childrenWithProps = children.map((child, index) => {
    var width = 0
    if (React.isValidElement(child)) {
      const props = child.props as CompactItemProps
      width = !isNaN(props.width) ? props.width : 0

      const className =
        index === 0 || prevWidth >= 100
          ? withPrefix('group-item-first')
          : prevWidth + width === 100
          ? withPrefix('group-item-last')
          : withPrefix('group-item-middle')

      const childWithProps = ChildWithProp(child, {
        key: `compact-group-item-${index}`,
        className: className,
        collapseWidth: collapseWidth,
        width: width === 33 ? 100 / 3 : width,
      })

      if (prevWidth >= 99) {
        currentRow += 1
        prevWidth = 0
      }
      prevWidth += width
      return childWithProps
    }
  })

  return <div className={withPrefix('compact-group')}>{childrenWithProps}</div>
}

export { CompactGroup, CompactItem }
