import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export const isNil = (value: any) => value === undefined || value === null

export const extractError = (error: any, fallback: string) => error?.response?.data?.errorDescription || fallback

export const isValidPassword = (password: string) => {
  // Contains  a lower case letter, an upper case letter, a number or a special character
  return /^(?=.*[A-Z])(?=.*[!@#$%^&*_0-9])(?=.*[a-z]).{8,}$/.test(password)
}

export const toCamelCase = <T>(data: any) => {
  return camelcaseKeys(data, { deep: true }) as T
}

export const toSnakeCase = <To>(data: any) => {
  return snakecaseKeys(data, { deep: true }) as To
}
