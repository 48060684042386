import { Entity, Resource } from '@rest-hooks/rest'
import { ApiErrorItem } from '../api/error'
import { BillingAddress } from './address'
import { TransactionEntity } from './transaction'

export type PaymentMethodType = '' | 'giftcard' | 'housecredit' | 'creditcard' | 'bankAccount' | 'newcreditcard' | 'houseAccount' | 'paymentIntent'

export interface IPaymentResponse {
  success: boolean
  error?: ApiErrorItem
  action?: any
}

class PaymentResponse extends Entity implements IPaymentResponse {
  amount: number = 0
  amountCharged: number = 0
  success: boolean = false
  error?: ApiErrorItem
  transaction: TransactionEntity = TransactionEntity.fromJS()

  pk() {
    return `${this.transaction.id}`
  }

  static schema = {
    transaction: TransactionEntity
  }
}

export interface IPaymentMethod { }
class PaymentMethod implements IPaymentMethod {
  constructor(obj: PaymentMethod) {
    this.id = obj.id
    this.type = obj.type
    this.amount = obj.amount
    this.save = obj.save
    this.processorId = obj.processorId
    this.billingDetails = obj.billingDetails
  }
  id: Data.ID = ''
  type: PaymentMethodType = ''
  processorId?: Data.ID
  amount?: number = 0
  save?: boolean = false
  billingDetails?: BillingAddress = {}
}



export { PaymentMethod, PaymentResponse }
