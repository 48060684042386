import { FC } from 'react'
import MyBookingDetails from 'src/components/mySchedule/bookings/MyBookingDetails'
import { CenteredContent } from 'src/sdk/components/layout'
import { IDrawerDetailView } from '../DrawerDetailProvider'

const BookingDrawer: FC<IDrawerDetailView> = ({ id, type }) => (
  <CenteredContent>
    <MyBookingDetails type={type as 'appointment' | 'reservation'} id={id} />
  </CenteredContent>
)

export default BookingDrawer
