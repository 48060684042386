import moment from 'moment'
import { AddToCalendarProps } from './AddToCalendar'

export function generateICS(props: AddToCalendarProps) {
  const { title, description, location, startDate, endDate } = props
  const formatIso = (date: moment.Moment) => {
    return date
      .toISOString()
      .replace(/\.\d{3}/g, '')
      .replace(/[^a-z\d]/gi, '')
  }
  const now = moment()
  const start = moment(startDate.date)
  const end = moment(endDate.date)
  const lines = ['BEGIN:VCALENDAR', 'VERSION:2.0', 'CALSCALE:GREGORIAN', 'BEGIN:VEVENT']

  lines.push(`UID:${formatIso(start)}-${title}`)
  lines.push(`DTSTAMP:${formatIso(start)}`)
  lines.push(`DTSTART:${formatIso(start)}`)
  lines.push(`DTEND:${formatIso(end)}`)
  lines.push(`SUMMARY:${title.replace(/\r\n/g, '\\n').replace(/.{65}/g, '$&\r\\n ')}`) // 75 characters per line
  lines.push(`DESCRIPTION:${description.replace(/\r\n/g, '\\n').replace(/.{65}/g, '$&\\n')}`)
  lines.push(
    `X-ALT-DESC;FMTTYPE=text/html:\r\n<!DOCTYPE HTML PUBLIC ""-//W3C//DTD HTML 3.2//EN"">\r\n <HTML><BODY>\r\n ${description
      .replace(/\n/g, '<br>')
      .replace(/.{60}/g, '$&\r\n ')}\r\n </BODY></HTML>`,
  )
  lines.push(`LOCATION:${location}`)
  lines.push(`STATUS:CONFIRMED`)
  lines.push(`LAST-MODIFIED:${formatIso(now)}`)
  lines.push(`SEQUENCE:0`)
  lines.push(`END:VEVENT`)
  lines.push(`END:VCALENDAR`)

  return `data:text/calendar;charset=utf-8,${encodeURIComponent(lines.join('\r\n'))}`
}
