import { Typography } from 'antd'
import moment from 'moment'
import { FC } from 'react'
import { FormattedDate, FormattedTime } from 'react-intl'
import Badge from 'src/sdk/components/badge/Badge'
import IvyIcon from 'src/sdk/components/icon'
import { HorizontalSpace, VerticalSpace } from 'src/sdk/components/layout'
import { ListItemMeta } from 'src/sdk/components/list/ListItem'
import { SectionLoader } from 'src/sdk/components/loader'
import { PaymentBrand } from 'src/sdk/components/payment-brand/PaymentBrand'
import { Money } from 'src/sdk/components/text'
import { PaymentMethodBrand } from 'src/sdk/datasource/config'
import { PaymentTypeLong, TransactionEntity, TransactionRefundItem } from 'src/sdk/datasource/transaction'
import { Capitalize } from 'src/sdk/helpers/strings'

type TransactionListProps = {
  loading?: boolean
  emptyMessage?: string
} & Data.SourceNullable<TransactionEntity[] | TransactionRefundItem[]>

const TransactionList: FC<TransactionListProps> = ({ data, emptyMessage, loading = false }) => {
  return loading ? (
    <SectionLoader />
  ) : data && data.length > 0 ? (
    <VerticalSpace>
      {data.map((transaction) => (
        <TransactionListItem key={`testing-${transaction.id}`} data={transaction} />
      ))}
    </VerticalSpace>
  ) : emptyMessage !== undefined ? (
    <Typography.Text>{emptyMessage}</Typography.Text>
  ) : null
}
const TransactionListItem: FC<Data.Source<TransactionEntity | TransactionRefundItem>> = ({ data }) => {
  const { id, amount, brand, lastFour, createdOn, paymentType, status, currency } = data

  const StatusTitle = () => {
    if (status === 'processed') {
      return (
        <VerticalSpace>
          <Typography.Text strong>{PaymentTypeLong[paymentType]} Payment</Typography.Text>
          <Badge status={'error'}>Cancelled</Badge>
        </VerticalSpace>
      )
    } else if (status === 'refunded') {
      return <Typography.Text strong>{PaymentTypeLong[paymentType]} Refund</Typography.Text>
    } else if (status === 'cancelled') {
      return <Typography.Text strong>{PaymentTypeLong[paymentType]} Cancelled</Typography.Text>
    }
    return <Typography.Text strong>{Capitalize(status)}</Typography.Text>
  }

  const Icon = () => {
    if (paymentType === 'creditcard' || paymentType === 'ach') {
      return <PaymentBrand brand={brand.toLowerCase() as PaymentMethodBrand} />
    }
    return <IvyIcon style={{ padding: 4 }} type={'custom/cash'} />
  }

  return (
    <ListItemMeta
      key={id}
      avatar={{
        icon: <Icon />,
        shape: 'square',
        background: 'none',
      }}
      description={
        <VerticalSpace size={0}>
          <HorizontalSpace align={'start'}>
            <StatusTitle />
            <Typography.Text type={'secondary'}>•••• {lastFour}</Typography.Text>
          </HorizontalSpace>
          <HorizontalSpace split={'-'}>
            <FormattedDate value={moment.utc(createdOn).local().toDate()} />
            <FormattedTime value={moment.utc(createdOn).local().toDate()} />
          </HorizontalSpace>
          {status === 'cancelled' && (
            <Typography.Text type={'secondary'}>This payment was cancelled. You will not be charged.</Typography.Text>
          )}
        </VerticalSpace>
      }
      extra={
        <Money
          currency={currency}
          type={status === 'refunded' ? 'danger' : undefined}
          prefix={status === 'refunded' ? '-' : ''}
        >
          {amount}
        </Money>
      }
    />
  )
}

export { TransactionList, TransactionListItem }
