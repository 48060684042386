import { useResource } from 'rest-hooks'
import { AbstractCartItem } from './cart'
import { CategoryEntity } from './category'
import { ApiResource } from './entity'
import { ImageEntity } from './media'

export type ProductGroup = 'dining' | 'product'

export type ProductType = 'bundle' | 'catalog' | 'digital' | 'external' | 'fandb' | 'physical' | 'product' | 'service'

class ProductAddOn {
  readonly productId: number = 0
  readonly quantity: number = 0
  readonly price: number = 0
  readonly name: string = ''
  readonly text: string = ''
}

class ProductAttribute {
  readonly id: number = 0
  readonly value: string = ''
}

class ValueEntity {
  readonly selected: boolean = true
  readonly value: string = ''
}

class FieldEntity {
  readonly editable: boolean = true
  readonly placeholder: string = ''
  readonly type: string = ''
  readonly value: string = ''
  readonly values: ValueEntity[] = []
}

class AttributeEntity {
  readonly id: number = 0
  readonly name: string = ''
  readonly field: FieldEntity = new FieldEntity()
}
type ProductFilters = {
  businessId?: Data.ID
  orderBy?: 'id' | 'name' | 'price' | 'newest'
  categoryId?: string[]
  group?: string
  notGroup?: string
  type?: ProductType
  flag?: 'featured' | 'frontpage' | 'discontinued' | 'special order'
}

class ProductResource
  extends ApiResource
  implements Data.Categorized<CategoryEntity>, Data.Grouped<ProductGroup>, Data.Imaginated {
  static readonly urlRoot = `/api/products`
  protected static readonly group: string = ''
  readonly id: Data.ID = ''
  readonly name: string = ''
  readonly description: string = ''
  readonly hasInventory: boolean = false
  readonly allowBackorders: boolean = false
  readonly categories: CategoryEntity[] = [new CategoryEntity()]
  readonly price: number = 0.0
  readonly photos?: ImageEntity[]
  readonly attributes: AttributeEntity[] = []
  readonly group: ProductGroup = 'product'
  readonly type: ProductType = 'physical'

  static schema = {
    photos: [ImageEntity],
  }

  get image(): string {
    return this.photos && this.photos.length > 0 ? this.photos[0].url : ''
  }

  get images(): string[] {
    return this.photos && this.photos?.length > 0 ? this.photos.map((value) => value.url) : []
  }

  static getAll({ group, ...params }: Partial<Data.Paginated & ProductFilters> = {}): ProductResource[] {
    return useResource(this.list(), {
      ...params,
    })
  }

  static getAllInDining(params: Partial<Data.Paginated & ProductFilters> = {}): ProductResource[] {
    return this.getAll({ type: 'fandb', ...params })
  }

  static getAllInShop(params: Data.Paginated = {}): ProductResource[] {
    return this.getAll({ type: 'product', ...params })
  }

  static getById(params: Partial<Data.Identified> = {}): ProductResource {
    return useResource(this.detail(), params)
  }
}

class ProductCartItem extends AbstractCartItem {
  readonly product: ProductResource = new ProductResource()

  static get key() {
    return '/api/cart/items/products'
  }

  get item(): ProductResource {
    return this.product
  }

  // updateQuantity = (quantity: number): UpdateRequest => ({ ...super.updateQuantity(quantity), productId: this.item.id })
}

export { ProductResource, ProductCartItem, AttributeEntity, ProductAddOn, ProductAttribute }
