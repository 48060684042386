import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'
import { Drawer as AntDrawer } from 'antd'
import { withFullScreenDrawer } from '../../components/drawer'
import { ResolveDrawer } from './views/Index'

export type DrawerType = 'order' | 'transaction' | 'directory' | 'appointment' | 'reservation' | 'statement'

export interface IDrawerDetailView {
  id: Data.ID
  type: DrawerType
}

interface IDrawerItem {
  key: Data.ID
  props: IDrawerDetailView
  visible: boolean
}

type DrawerDetailProps = {
  showDetails: (type: DrawerType, id: Data.ID) => void
  hideDrawer: () => void
}

const defaultValues: DrawerDetailProps = {
  showDetails: () => {},
  hideDrawer: () => {},
}

const DrawerDetailContext = createContext<DrawerDetailProps>(defaultValues)

const Drawer = withFullScreenDrawer(AntDrawer)

const DrawerDetailProvider = ({ children }) => {
  const [drawers, setDrawers] = useState<IDrawerItem[]>([])
  const [visible, setVisible] = useState(false)
  const [content, setContent] = useState<ReactNode>()

  const hideDrawer = () => setVisible(false)
  const showDetails = (type: DrawerType, id: Data.ID) => {
    const Component = ResolveDrawer(id, type)
    if (!Component) {
      console.log('No component found for drawer type', type)
      return
    }
    setContent(<Component id={id} type={type} />)
    setVisible(true)
  }

  return (
    <DrawerDetailContext.Provider
      value={{
        showDetails,
        hideDrawer,
      }}
    >
      {children}
      <Drawer visible={visible} onClose={() => setVisible(false)} destroyOnClose zIndex={1001}>
        {content}
      </Drawer>
    </DrawerDetailContext.Provider>
  )
}

const useDrawerDetail: () => DrawerDetailProps = () => useContext(DrawerDetailContext)

export { DrawerDetailProvider, useDrawerDetail }
