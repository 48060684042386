import { Entity, Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { LeftPadNumber } from 'src/sdk/helpers/numbers'
import { BillingAddress } from '../address'
import { ApiResource, SchemaPaginated } from '../entity'
import { BankAccount } from './bankaccount'
import { CreditCardEntity } from './creditcard'

export type WalletBillingUse = 'default' | 'billing' | 'pos' | ''
export type WalletPaymentType = 'creditcard' | 'ach' | 'houseAccount'
export type WalletItem = CreditCardEntity | BankAccount
export type WalletStatus = 'active' | 'pending'



export interface WalletInterface {
  readonly id: Data.ID
  readonly nickname?: string
  readonly billingUse?: WalletBillingUse
  default?: boolean
  readonly customerId?: number
  readonly status: string
  readonly lastFour: string
  readonly expMonth?: number
  readonly expYear?: number
  readonly billingDetails?: BillingAddress
  readonly paymentType: WalletPaymentType
  readonly verified?: boolean
  readonly brand: string
  readonly createdOn: Date
}

class HouseAccount extends Entity implements WalletInterface {
  readonly id: Data.ID = ''
  default: boolean = false
  readonly status: WalletStatus = 'active'
  readonly lastFour: string = '0000'
  readonly paymentType: WalletPaymentType = 'houseAccount'
  readonly brand: string = ''
  readonly createdOn: Date = new Date()
  pk(parent?: any, key?: string | undefined): string | undefined {
    return `${this.id}`
  }
}


class Wallet extends ApiResource implements WalletInterface {
  static readonly urlRoot = `/api/account/wallet`
  readonly id: Data.ID = ''
  readonly nickname: string = ''
  readonly billingUse: WalletBillingUse = 'billing'
  default: boolean = false
  readonly status: WalletStatus = 'active'
  readonly customerId?: number
  readonly lastFour: string = '0000'
  readonly expMonth?: number
  readonly expYear?: number
  readonly billingDetails: BillingAddress = {}
  readonly paymentType: WalletPaymentType = 'creditcard'
  readonly verified: boolean = false
  readonly brand: string = ''
  readonly modifiedOn: Date = new Date()
  readonly createdOn: Date = new Date()

  static getById(id: Data.Identified): Wallet {
    return useResource(this.detail(), id)
  }

  static getAll<T extends typeof Resource>(this: T) {
    const endpoint = this.detail()
    return endpoint.extend({
      schema: {
        banks: [BankAccount],
        cards: [CreditCardEntity]
      }
    })
  }

  get fullExpiration(): string {
    if (this.expMonth && this.expYear) {
      return `${LeftPadNumber(this.expMonth)} / ${this.expYear.toString().slice(-2)}`
    }
    return ''
  }
}

export { Wallet, HouseAccount }
