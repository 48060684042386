import { Col, Divider, FormInstance, Input, notification, Row, Typography } from 'antd'
import { Rule } from 'antd/lib/form'
import { FC, useState } from 'react'
import { useController } from 'rest-hooks'
import Checkable from 'src/sdk/components/checkable'
import Form, { Item } from 'src/sdk/components/form'
import { CompactGroup, CompactItem } from 'src/sdk/components/form/CompactGroup'
import { FormRuleProps } from 'src/sdk/components/form/Form'
import { FormItem } from 'src/sdk/components/form/FormItem'
import IvyIcon from 'src/sdk/components/icon'
import { VerticalSpace } from 'src/sdk/components/layout'
import { TextCentered } from 'src/sdk/components/text/TextCentered'
import { useAccount } from 'src/sdk/contexts/Account'
import { usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import { useNotification } from 'src/sdk/contexts/Notification'
import { useTokenize } from 'src/sdk/contexts/Tokenize'
import { BankAccount, BankAccountCreate } from 'src/sdk/datasource/wallet/bankaccount'
import { ValidateRouting } from 'src/sdk/datasource/wallet/routingnumbers'
import { PaymentMethodAddProps } from '../../../payment-methods/PaymentMethodAdd'
import { PlaidConnect } from '../../AchConnect'

type StripeAddBankAccountProps = {
  form: FormInstance<BankAccountCreate>
} & PaymentMethodAddProps

const StripeAddBankAccount: FC<StripeAddBankAccountProps> = ({ onSuccess, onLoading, form }) => {
  const { intl } = usePublicConfig()
  const { tokenize } = useTokenize()
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const { customer } = useAccount()
  const [bankName, setBankName] = useState<string>()
  const [bankType, setBankType] = useState<'individual' | 'company'>('individual')

  const validateRouting = (rule: Rule, routingNumber: string, source) => {
    return ValidateRouting(routingNumber)
  }

  const onFinish = async (data: BankAccountCreate) => {
    const tokenized = tokenize({
      ...data,
      currency: intl.currency,
    })

    if (!tokenized) return

    onLoading && onLoading(true)
    await fetch(
      BankAccount.add(),
      {},
      {
        token: tokenized,
      },
    )
      .then((account) => {
        onSuccess ? onSuccess(account) : notification.success({ message: 'Bank account was successfully added' })
      })
      .catch(notifyOnError)
      .finally(() => onLoading && onLoading(false))
  }

  const formRules: FormRuleProps<Partial<BankAccountCreate>> = {
    country: [
      {
        required: true,
        message: '',
      },
    ],
    nickName: [
      {
        required: true,
        message: '',
      },
    ],
    accountHolderName: [
      {
        required: true,
        message: '',
      },
    ],
    accountNumber: [
      {
        required: true,
        message: '',
      },
    ],
    routingNumber: [
      {
        required: true,
        validator: validateRouting,
      },
    ],
  }

  return (
    <VerticalSpace>
      <VerticalSpace size={32}>
        <PlaidConnect />
        <Divider>or</Divider>
        <TextCentered type={'secondary'}>
          Add your bank account information and verify manually.
          <br />
          Once added, we will credit two small deposits into your bank account that will have to be manually confirmed.
        </TextCentered>
        <Form<BankAccountCreate> validateTrigger={'submit'} form={form} onFinish={onFinish}>
          <Item>
            <Row gutter={[16, 16]}>
              <Col span={24} lg={12}>
                <Checkable
                  title={
                    <Typography.Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                      Personal
                    </Typography.Title>
                  }
                  size={'sm'}
                  icon={'custom/people'}
                  checked={bankType === 'individual'}
                  onClick={() => setBankType('individual')}
                />
              </Col>
              <Col span={24} lg={12}>
                <Checkable
                  title={
                    <Typography.Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                      Business
                    </Typography.Title>
                  }
                  size={'sm'}
                  icon={'custom/office'}
                  checked={bankType === 'company'}
                  onClick={() => setBankType('company')}
                />
              </Col>
            </Row>
          </Item>
          <Item name='accountHolderName' label={'Account Holder Name'} rules={formRules.accountHolderName}>
            <Input />
          </Item>
          <CompactGroup>
            <CompactItem
              name={'routingNumber'}
              required
              label={'Routing Number'}
              width={50}
              collapseWidth={100}
              rules={formRules.routingNumber}
            >
              <Input
                maxLength={9}
                className={withPrefix('input-routing-number')}
                suffix={
                  bankName ? (
                    <IvyIcon
                      className={withPrefix('input-logo')}
                      size={32}
                      theme={'filled'}
                      type={`payment-brand/${bankName as Design.PaymentBrandIcon}`}
                    />
                  ) : null
                }
              />
            </CompactItem>
            <CompactItem
              label={'Account Number'}
              name={'accountNumber'}
              width={50}
              collapseWidth={100}
              rules={formRules.accountNumber}
            >
              <Input />
            </CompactItem>
          </CompactGroup>
          <Item initialValue={customer?.country} name='country' label={'Account Country'} rules={formRules.country}>
            <FormItem.Countries />
          </Item>
        </Form>
      </VerticalSpace>
    </VerticalSpace>
  )
}

export default StripeAddBankAccount
