import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { ApiResource } from './entity'

class PlaidResource extends ApiResource {
  static readonly urlRoot = `/api/account/wallet/plaid/link`
  readonly linkToken: string = ''
  readonly requestId: string = ''
  readonly expiration: Date = new Date()

  pk() {
    return this.requestId
  }

  static getLink() {
    return super.fetch(this.urlRoot, {}).then<PlaidResource>()
  }
}

class StripeResource extends ApiResource {
  static readonly urlRoot = `/api/account/wallet/stripe/session`
  readonly id: string = ''
  readonly token: string = ''

  pk() {
    return this.id
  }

  static createSession() {
    return super.fetch(this.urlRoot, {}).then<StripeResource>()
  }
}

export type GoCardlessFlowRequest = {
  redirectUri: string
  exitUri: string
}

class GoCardlessFlow extends ApiResource {
  static readonly urlRoot = `/api/account/wallet/gocardless/link`
  readonly id: string = ''
  readonly billingRequest: string = ''
  readonly authorizationUrl: string = ''
  readonly expiresAt: Date = new Date()
  readonly createdAt: Date = new Date()
  readonly sandbox: boolean = false
  pk() {
    return this.id
  }

  static create<T extends typeof Resource>(this: T) {
    return super.create().extend({
      schema: GoCardlessFlow,
    })
  }

  static createLink(flow: GoCardlessFlowRequest) {
    return super.post(this.urlRoot, flow).then<GoCardlessFlow>()
  }
}

export { PlaidResource, StripeResource, GoCardlessFlow }
