import { List } from 'antd'
import { RenderEmptyHandler } from 'antd/lib/config-provider'
import Empty from '../list/Empty'

const renderEmpty: RenderEmptyHandler = (componentName?: string | undefined) => {
  switch (componentName) {
    case List.name:
      return <Empty />
    default:
      return <></>
  }
}
export { renderEmpty }
