import { Typography } from 'antd'
import { FC } from 'react'
import { Markup } from 'src/sdk/components/text/Markup'
import { OrderItem } from 'src/sdk/datasource/order'
import OrderBookingDescription from './OrderBookingDescription'
import OrderProductDescription from './OrderProductDescription'
import OrderTicketDescription from './OrderTicketDescription'

const OrderItemDescription: FC<Data.Source<OrderItem>> = ({ data }) => {
  const DefaultDescription = (
    <Typography.Text>
      <Markup html={data.description} sanitized={false} />
    </Typography.Text>
  )
  switch (data.type) {
    case 'appointment':
    case 'reservation':
      return data.booking ? <OrderBookingDescription data={data.booking} /> : DefaultDescription
    case 'event':
      return <OrderTicketDescription data={data.ticket} />
    case 'product':
      return <OrderProductDescription data={data.product} />
    default:
      return DefaultDescription
  }
}

export default OrderItemDescription
