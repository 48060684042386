import { Entity, EntityRecord } from '@rest-hooks/rest'
import { v4 as uuid } from 'uuid'
import moment from 'moment'

export class DateTz extends EntityRecord {
  readonly date: Date = new Date()
  readonly name: string = ''
  readonly timezone: string = ''
  readonly offset: string = ''
  pk(): string {
    return uuid()
  }

  format(as: string) {
    const momentDate = moment(this.date)
    return momentDate.format(as)
  }
}

export class DateRangeEntity extends Entity {
  start: Date = new Date(0)
  end: Date = new Date(0)

  get moment(): { to: moment.Moment; from: moment.Moment } {
    return {
      to: moment(this.end),
      from: moment(this.end),
    }
  }

  pk() {
    return `${this.start.toString()}-${this.end.toString()}`
  }
}
