import { Typography } from 'antd'
import { CSSProperties, FC } from 'react'
import { DateTz } from 'src/sdk/api'
import { toTimezoneAbbrev } from 'src/sdk/datasource/timezones'
import { HorizontalSpace } from '../layout'

type LocalFormattedTimedProps = {
  value: DateTz | DateTz[]
  includeTimezone?: boolean
  includeDay?: boolean
  type?: 'secondary' | 'success' | 'warning' | 'danger' | 'text'
  style?: CSSProperties
  className?: string
  format?: string
}
const LocalFormattedTime: FC<LocalFormattedTimedProps> = ({
  value,
  type,
  style,
  className,
  includeTimezone = true,
  includeDay = false,
  format,
}) => {
  const MultipleTimes: FC<{ values: DateTz[] }> = ({ values }) => {
    return Array.isArray(values) && values.length > 1 ? (
      <HorizontalSpace style={style} className={className}>
        <HorizontalSpace split={'-'}>
          {values.map((item, index) => {
            return type === 'text' ? (
              <div key={DateTz.fromJS(item).format('h:mm')}>
                {DateTz.fromJS(item).format(includeDay ? 'dddd, h:mm A' : format ? format : 'h:mm A')}
              </div>
            ) : (
              <Typography.Text key={DateTz.fromJS(item).format('h:mm')} type={type}>
                {DateTz.fromJS(item).format(includeDay ? 'dddd, h:mm A' : format ? format : 'h:mm A')}
              </Typography.Text>
            )
          })}
        </HorizontalSpace>
        {type === 'text' ? (
          <>{includeTimezone && toTimezoneAbbrev(value[0].name)}</>
        ) : (
          <Typography.Text key={'timezone'} type={type}>
            {includeTimezone && toTimezoneAbbrev(value[0].name)}
          </Typography.Text>
        )}
      </HorizontalSpace>
    ) : (
      <SingleTime value={values[0]} />
    )
  }

  const SingleTime: FC<{ value: DateTz }> = ({ value }) => {
    var date = DateTz.fromJS(value)
    const time = date.format(includeDay ? 'dddd, h:mm A' : 'h:mm A')
    return type === 'text' ? (
      <>
        {time} {includeTimezone && toTimezoneAbbrev(date.name)}
      </>
    ) : (
      <Typography.Text type={type} style={style}>
        {time} {includeTimezone && toTimezoneAbbrev(date.name)}
      </Typography.Text>
    )
  }

  return Array.isArray(value) ? <MultipleTimes values={value} /> : <SingleTime value={value} />
}

export default LocalFormattedTime
