import { Space, Typography } from 'antd'
import { TextProps } from 'antd/lib/typography/Text'
import { FC } from 'react'
import { FormattedNumber } from 'react-intl'
import { useIntlConfig, useIntlFormatter } from 'src/sdk/contexts/Config'

type MoneyProps = {
  prefix: string
  suffix: string
  strike?: boolean
  type?: 'secondary' | 'danger' | 'success' | 'warning'
  wrap?: boolean
  currency?: string
} & TextProps

const Money: FC<Partial<MoneyProps>> = ({
  prefix = '',
  suffix = '',
  type,
  strike = false,
  wrap = true,
  children,
  currency,
  ...props
}) => {
  const { currency: companyCurrency } = useIntlConfig()
  
  currency = !!currency?.trim() ? currency : companyCurrency

  return prefix || suffix ? (
    <Space size={3} direction={'horizontal'} split={''} style={{ height: '100%' }}>
      {prefix && (
        <Typography.Text type={type} {...props}>
          {prefix}
        </Typography.Text>
      )}
      <Typography.Text type={type} delete={strike} {...props}>
        {typeof children === 'number' ? (
          <FormattedNumber
            value={children}
            style={'currency' as never}
            currencyDisplay={'symbol'}
            currency={currency?.toLowerCase()}
          />
        ) : (
          children
        )}
      </Typography.Text>
      {suffix && (
        <Typography.Text type={type} {...props}>
          {suffix}
        </Typography.Text>
      )}
    </Space>
  ) : typeof children === 'number' ? (
    wrap ? (
      <Typography.Text type={type} delete={strike} {...props}>
        <FormattedNumber value={children} style={'currency' as never} currencyDisplay={'symbol'} currency={currency} />
      </Typography.Text>
    ) : (
      <FormattedNumber value={children} style={'currency' as never} currencyDisplay={'symbol'} currency={currency} />
    )
  ) : (
    <Typography.Text type={type} delete={strike} {...props}>
      {children}
    </Typography.Text>
  )
}

const MoneyString = (value: number) => {
  const { currency } = useIntlFormatter()
  return currency(value)
}

export { Money, MoneyString }
