import { FC, PropsWithChildren, useEffect } from 'react'
import { default as Wrapper } from 'react-loading-skeleton'
import { SkeletonProps as WrapperProps } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Route } from 'react-router'
import { CardMeta } from 'src/sdk/components/card'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Card } from '../card/Card'
import { Split } from '../layout/Grid'
import './Skeleton.less'

const Skeleton: FC<PropsWithChildren<WrapperProps>> = ({ style, ...props }) => {
  return (
    <Wrapper width={props.width ?? 100} height={props.height ?? 15} style={{ maxWidth: '100%', ...style }} {...props} />
  )
}

export type SkeletonWrapperProps = {
  loading?: boolean
} & WrapperProps

const SkeletonWrapper: FC<PropsWithChildren<SkeletonWrapperProps>> = ({ loading, children, ...props }) => {
  return loading ? <Wrapper width={props.width ?? 100} height={props.height ?? 15} {...props} /> : <>{children}</>
}

type SkeletonImageProps = {
  loading: boolean
  width?: number | string
  height?: number | string
}

const SkeletonImage: FC<PropsWithChildren<SkeletonImageProps>> = ({ loading, height, width, children }) => {
  useEffect(() => {}, [loading])

  return loading ? (
    <div className={withPrefix('skeleton-image-wrapper', 'image')}>
      <Wrapper width={width ?? '100%'} height={height ?? '100%'} />
    </div>
  ) : (
    <>{children}</>
  )
}

const SkeletonAvatar: FC<SkeletonWrapperProps> = ({ loading, children, width, height, ...props }) => {
  useEffect(() => {}, [loading])

  return loading ? (
    <div
      className={withPrefix('skeleton-avatar-wrapper', 'avatar', 'avatar-circle', 'avatar-image')}
      style={{ width: width ?? '100%', height: height ?? '100%' }}
    >
      <Wrapper {...props} />
    </div>
  ) : (
    <>{children}</>
  )
}

export type SkeletonCardProps = {} & WrapperProps

const SkeletonCard: FC<SkeletonCardProps> = () => (
  <Card
    equalHeight
    cover={<Wrapper className={withPrefix('skeleton-image')} height={190} />}
    title={<Wrapper />}
    subTitle={<Wrapper />}
    description={<Wrapper />}
    footer={<Wrapper />}
  />
)

const SkeletonListMeta: FC<SkeletonCardProps> = () => (
  <CardMeta
    className={withPrefix('skeleton-list-meta')}
    avatar={<Wrapper height={48} width={48} />}
    title={
      <Split>
        <Wrapper height={16} width={150} />
        <Wrapper height={16} width={150} />
      </Split>
    }
    description={<Wrapper height={16} width={150} />}
  >
    <Wrapper height={16} width={150} />
  </CardMeta>
)

export { Skeleton, SkeletonWrapper, SkeletonCard, SkeletonListMeta, SkeletonImage, SkeletonAvatar }
