import { ImageProps } from 'antd/es/image'
import { CSSProperties, FC } from 'react'
import { usePropsConfig, withPrefix } from 'src/sdk/contexts/Config'
import { LogoType } from 'src/sdk/datasource/config'
import { Flex } from '../layout/Grid'
import './Logo.less'

export type LogoProps = ImageProps & {
  type: LogoType
  dark?: boolean | undefined
  style?: CSSProperties
  strict?: boolean
}
export const LogoImage: FC<ImageProps> = ({ style, ...props }) => (
  <img
    src={props.src}
    style={{ objectFit: 'contain', objectPosition: '50% 50%', ...style }}
    onClick={props.onClick}
    className={props.className}
  />
)

export const Logo: FC<Partial<LogoProps>> = ({
  type = 'icon',
  onClick,
  width,
  height,
  dark = true,
  strict = false,
  style,
}) => {
  const {
    logo,
    layout: { theme },
  } = usePropsConfig()

  let logoVersion =
    dark !== undefined ? (dark === true ? 'standard' : 'inverted') : theme !== 'dark' ? 'standard' : 'inverted'

  const { full, text, icon, fullSize, textSize, iconSize } = logo[logoVersion] || {}

  // Determine if a fallback logo is allowed to be used
  if (type === 'icon' && !icon) {
    if (strict) return null
    type = 'text'
  } else if (type === 'text' && !text) {
    if (strict) return null
    type = 'icon'
  }

  return (() => {
    switch (type) {
      case 'text':
        return (
          <LogoImage
            onClick={onClick}
            src={text}
            className={withPrefix('logo-text')}
            style={{
              height: height ? height : '100%',
              width: width,
              maxHeight: `${textSize ?? 100}%`,
              maxWidth: `${textSize ?? 100}%`,
              ...style,
            }}
          />
        )
      case 'icon':
        return (
          <LogoImage
            onClick={onClick}
            src={icon}
            className={withPrefix('logo-icon')}
            style={{
              height: height,
              width: width ?? `${32 * (iconSize * 0.01)}px`,
              ...style,
            }}
          />
        )
      case 'full':
        return (
          <LogoImage
            onClick={onClick}
            src={full}
            className={withPrefix('logo-full')}
            style={{
              height: height,
              width: width ?? `${fullSize ?? 100}%`,
              ...style,
            }}
          />
        )
      case 'icon+text':
        return (
          <Flex size={16} className={withPrefix('logo')} align={'center'}>
            {icon && (
              <LogoImage
                className={withPrefix('logo-icon')}
                onClick={onClick}
                src={icon}
                style={{ height: height, width: width ?? `${32 * (iconSize * 0.01)}px` }}
              />
            )}
            {text && (
              <LogoImage
                className={withPrefix('logo-text')}
                onClick={onClick}
                src={text}
                style={{ height: height, maxWidth: `${textSize ?? 100}%` }}
              />
            )}
          </Flex>
        )
    }
  })()
}
