import { Alert as Wrapped } from 'antd'
import { AlertProps as AntAlertProps } from 'antd/es/alert'
import { createRef, CSSProperties, FC, ReactNode, useEffect, useState } from 'react'
import { useLayoutState } from 'src/layouts'
import { withPrefix } from 'src/sdk/contexts/Config'
import './Alert.less'

type AlertProps = {
  fixedBottom?: boolean
  message?: ReactNode
} & Omit<AntAlertProps, 'message'>

export const Alert: FC<AlertProps> = ({ fixedBottom, message, ...props }) => {
  const [paddingApplied, setPaddingApplied] = useState(false)
  const [styles, setStyles] = useState<CSSProperties>()
  const { siderState } = useLayoutState()

  const ref = createRef<HTMLDivElement>()
  const rootElem = document.getElementById('root')

  const handleClose = () => {
    if (rootElem && paddingApplied) {
      rootElem.style.paddingBottom = ''
    }
  }

  useEffect(() => {
    const box = ref.current?.getBoundingClientRect()

    if (rootElem) {
      if (fixedBottom) {
        if (box && rootElem) {
          rootElem.style.paddingBottom = `${box.height}px`
          setStyles({ left: siderState === 'collapsed' ? 112 : '' })
          setPaddingApplied(true)
        }
      } else {
        rootElem.style.paddingBottom = ''
        setPaddingApplied(false)
      }
    }
  }, [fixedBottom])

  return (
    <div ref={ref} className={withPrefix(fixedBottom ? 'alert-fixed-bottom' : '')} style={styles}>
      <Wrapped message={message} onClose={handleClose} className={withPrefix(`alert-${props.type}`)} {...props} />
    </div>
  )
}
