import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { DeleteResponse } from 'src/sdk/api/responses'
import Encrypt from 'src/sdk/helpers/encrypt'
import { PaymentProcessor } from 'src/sdk/typings/payment'
import { BillingAddress } from '../address'
import { ApiResource } from '../entity'
import { WalletBillingUse, WalletInterface, WalletPaymentType, WalletStatus } from './wallet'

export type BankAccountCreateToken = {
  token: string
  accountId?: string
  tokenType: 'gocardless' | 'plaid' | 'portal'
  billingDetails?: BillingAddress
}

export type BankAccountUpdate = {
  nickname: string
  billingUse: WalletBillingUse
}

export type BankAccountCreate = {
  country?: string
  currency?: string
  routingNumber?: string
  accountNumber?: string
  nickName?: string
  accountHolderName?: string
  default?: boolean
  token?: string
  billingDetails?: BillingAddress
}

export type BankAccountVerifyRequest = {
  amount_1: number
  amount_2: number
}

class BankAccount extends ApiResource implements WalletInterface {
  static readonly urlRoot = `/api/account/wallet/banks`
  readonly id: Data.ID = ''
  readonly canVerify: boolean = true
  readonly brand: string = ''
  readonly nickname: string = ''
  readonly billingUse: WalletBillingUse = 'billing'
  default: boolean = false
  readonly status: WalletStatus = 'active'
  readonly lastFour: string = '0000'
  readonly billingDetails: BillingAddress = {}
  readonly verified: boolean = false
  readonly paymentType: WalletPaymentType = 'ach'
  readonly modifiedOn: Date = new Date()
  readonly createdOn: Date = new Date()
  readonly type: PaymentProcessor = ''
  get bankName() {
    return this.brand.split(',')[0].replaceAll(' ', '-')
  }

  get title() {
    return this.brand
  }

  get verificationStatus() {
    return this.verified ? 'verified' : 'pending verification'
  }

  static add<T extends typeof Resource>(this: T) {
    const endpoint = BankAccount.list()
    return super.create().extend({
      schema: BankAccount,
      update: (newResourceId: string) => ({
        [endpoint.key({})]: (resourceIds: string[] = []) => [...resourceIds, newResourceId],
      }),
    })
  }

  static verifyAccount<T extends typeof Resource>(this: T) {
    const create = this.create()
    return super.create().extend({
      fetch: (id: Data.ID, data: BankAccountVerifyRequest) => {
        return create.fetch.call(
          create.extend({
            url: () => `${this.urlRoot}/${id}/verify`,
          }),
          null,
          data,
        )
      },
      schema: BankAccount,
    })
  }

  static remove<T extends typeof Resource>(this: T) {
    const endpoint = this.delete()
    const list = this.list()
    return endpoint.extend({
      schema: DeleteResponse,
      update: (deletedResults: Data.ID) => ({
        [list.key('')]: BankAccount.updateList.bind(ApiResource, deletedResults),
      }),
    })
  }

  static partialUpdate<T extends typeof Resource>(this: T) {
    return super.partialUpdate().extend({
      schema: BankAccount,
    })
  }
  static updateList(deletedResource: Data.ID, existing: string[]) {
    return [...existing.filter((item) => item !== deletedResource)]
  }

  static getById(id: Data.ID): BankAccount {
    return useResource(this.detail(), { id: id })
  }

  static getAll(params: Data.Paginated = {}): BankAccount[] {
    return useResource(this.list(), params)
  }

  static tokenize(data: BankAccountCreate): BankAccountCreateToken | null {
    const encrypted = Encrypt(data)
    return encrypted
      ? {
        token: encrypted,
        tokenType: 'portal',
      }
      : null
  }
}

export { BankAccount }
