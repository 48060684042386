import { useEffect, useMemo, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import { BreakpointMin } from '../components/screen/Breakpoint'
import { usePublicConfig } from '../contexts/Config'

export default function useLogoType() {
  const {
    props: {
      navigation: { logo },
    },
  } = usePublicConfig()
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.MD })

  let logoType = useMemo(() => {
    const logoVersion = isDesktop ? logo?.desktop : logo?.mobile
    let justify = logoVersion?.justify ?? 'left'
    return {
      logo: logoVersion,
      justify: justify === 'left' ? 'start' : justify === 'right' ? 'end' : 'center',
    }
  }, [isDesktop])

  return logoType
}
