import { Entity, Resource } from '@rest-hooks/rest'
import moment from 'moment'
import { useResource } from 'rest-hooks'
import { AccountEntity } from './account/account'
import { BaseAddress } from './address'
import { ApiResource, SchemaPaginated } from './entity'
import { MembershipCardEntity } from './membership'
import { OrderEntity } from './order'
import { IPaymentResponse, PaymentMethod } from './payment'
import { SubscriptionEntity } from './subscription'
import { Wallet } from './wallet/wallet'

type TransactionType =
  | ''
  | 'application'
  | 'appointment'
  | 'charge'
  | 'credit'
  | 'donation'
  | 'event'
  | 'event_ticket'
  | 'fundraiser'
  | 'giftcard'
  | 'invoice'
  | 'invoices'
  | 'invoicing'
  | 'membership'
  | 'order'
  | 'schedule'
  | 'schedule_book'
  | 'schedule_slot'
  | 'subscription'

export type TransactionStatus =
  | ''
  | 'cancelled'
  | 'declined'
  | 'voided'
  | 'payment'
  | 'error'
  | 'authorized'
  | 'refunded'
  | 'processed'
  | 'processing'
  | 'invoiced'
  | 'charged_back'

export type TransactionPaymentType = '' | 'creditcard' | 'ach' | 'giftcard' | 'invoice' | 'pms'

export const PaymentTypeLong: { [key in TransactionPaymentType]: string } = {
  'creditcard': 'Credit Card',
  'ach': 'Direct Debit',
  'giftcard': 'Gift Card',
  'invoice': 'Invoice',
  'pms': 'Self Service (PMS)',
  '': '',
}

export type TransactionData = {
  quantity: number
  fee: number
  amount: number
  referenceId?: string
  referenceNo?: number
  subscriptionId?: number
  referenceItem?: string
  referenceType?: string
}

export interface TransactionPaymentResponse extends IPaymentResponse {
  transaction: TransactionEntity
}

export type TransactionQuery = {
  status?: TransactionStatus
  type?: TransactionType
} & Data.Paginated

class TransactionEntity extends ApiResource implements Data.Identified {
  static urlRoot = `/api/account/transactions`
  readonly id: Data.ID = ''
  readonly orderId: number = 0
  readonly brand: string = ''
  readonly companyId: number = 0
  readonly lastFour?: number
  readonly billingAddress?: BaseAddress
  readonly paymentType: TransactionPaymentType = ''
  readonly status: TransactionStatus = ''
  readonly purchaseOrder: string = ''
  readonly linkedTransactionId: number = 0
  readonly referenceId: number = 0
  readonly type: TransactionType = ''
  readonly refundedAmount: number = 0
  readonly amount: number = 0
  readonly amountDue: number = 0
  readonly subTotal: number = 0
  readonly tax: number = 0
  readonly discount: number = 0
  readonly createdOn: Date = new Date()
  readonly paidOn: Date = new Date()
  readonly chargedOn: Date = new Date()
  readonly periodStart: Date = new Date()
  readonly periodEnd: Date = new Date()
  readonly currency: string = ''
  readonly data: TransactionData[] = []
  readonly description?: string
  readonly subscription?: SubscriptionEntity
  readonly membershipCard?: MembershipCardEntity
  readonly customer: AccountEntity = AccountEntity.fromJS()
  readonly wallet?: Wallet
  readonly linked: TransactionEntity[] = []
  readonly order?: OrderEntity

  static schema = {
    billingAddress: BaseAddress
  }

  formattedDate(): string {
    return moment(this.createdOn).format('MM-DD-YYYY')
  }

  static getById(params: Data.Identified | string) {
    return useResource(this.detail(), params)
  }

  static async sendByEmail(params: Data.Identified) {
    return await super.fetch(`${this.urlRoot}/${params.id}/email`, {})
  }

  static getPaginated(params: TransactionQuery = {}): SchemaPaginated<TransactionEntity> {
    return useResource(this.paginated(), params)
  }

  static payInvoice<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return this.partialUpdate().extend({
      fetch: (transaction_id: Data.ID, payment_method: PaymentMethod) => {
        return endpoint.fetch.call(
          endpoint.extend({
            method: 'POST',
            url: () => `${this.urlRoot}/${transaction_id}/pay`,
          }),
          null,
          payment_method,
        )
      },
      schema: TransactionEntity,
    })
  }
}

export type StatementQuery = {
  startDate?: Date | string
  endDate?: Date | string
  type?: 'membership' | 'subscription'
} & Data.Paginated

class StatementBase extends ApiResource {
  readonly id: string = ''
  readonly startDate: Date = new Date(0)
  readonly endDate: Date = new Date(0)
  readonly count: number = 0
  readonly charged: number = 0
  readonly total: number = 0
  readonly refunded: number = 0
  readonly periodDue: number = 0
  readonly totalDue: number = 0
}

class StatementEntity extends StatementBase {
  static urlRoot = `/api/account/transactions/statements`

  startMonth(): string {
    const month = moment(this.startDate).format('MMMM')
    return month
  }

  startYear(): string {
    const year = moment(this.startDate).format('YYYY')
    return year
  }

  static getPaginated(params: StatementQuery = {}): SchemaPaginated<StatementEntity> {
    return useResource(this.paginated(), params)
  }
  static getAll(params: StatementQuery = {}) {
    return useResource(this.list(), params)
  }

  static transactions<T extends typeof ApiResource>(this: T) {
    const endpoint = this.list()
    return endpoint.extend({
      fetch: (id: Data.ID) => {
        return endpoint.fetch.call(endpoint.extend({
          url: () => `${this.urlRoot}/${id}`
        }), {})
      },
      schema: [TransactionEntity]
    })
  }
}

class StatementWithTransactions extends StatementEntity {
  readonly transactions: TransactionEntity[] = []

  static get key() {
    return '/api/account/transactions/statements/detailed'
  }
}

class TransactionRefundItem extends Entity {
  readonly id: Data.ID = 0
  readonly amount: number = 0
  readonly lastFour: string = ''
  readonly brand: string = ''
  readonly paymentType: TransactionPaymentType = ''
  readonly status: TransactionStatus = ''
  readonly currency: string = ''
  readonly createdOn: Date = new Date()

  pk() {
    return `${this.id}`
  }
}

class TransactionRefund extends Entity {
  readonly id: Data.ID = 0
  readonly orderId?: Data.ID
  readonly order?: OrderEntity
  readonly refunds?: TransactionRefundItem[]
  readonly amount: number = 0
  readonly message: string = ''
  readonly success: boolean = false
  pk() {
    return `${this.id}`
  }

  get totalByCurrency() {
    return this.refunds?.filter(r => r.paymentType !== 'invoice').reduce((acc, refund) => {
      if (!acc[refund.currency]) {
        acc[refund.currency] = 0
      }
      acc[refund.currency] += refund.amount
      return acc
    }, {} as { [key: string]: number })
  }
}

export { StatementEntity, StatementWithTransactions, TransactionEntity, TransactionRefund, TransactionRefundItem }

