import { Badge as Wrapper, BadgeProps as WrapperProps } from 'antd'
import { FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'

import './Badge.less'

type BadgeProps = {} & WrapperProps

const Badge: FC<BadgeProps> = ({ count, children, ...props }) => {
  return <Wrapper count={count} children={children} {...props} />
}

const BadgeCount: FC<BadgeProps> = ({ count, children, ...props }) => {
  return <Wrapper className={withPrefix('badge-counter')} count={count} children={children} {...props} />
}

export { Badge as default, BadgeCount }
