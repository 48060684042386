import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { GeneralResponse } from '../api/responses'
import { ApiResource } from './entity'

export class BaseAddress extends Resource {
  static readonly urlRoot = ''
  readonly address: string = ''
  readonly address2: string = ''
  readonly address3: string = ''
  readonly city: string = ''
  readonly state: string = ''
  readonly zipCode: string = ''
  readonly country: string = ''

  pk(): string {
    return `${this.address}:${this.zipCode}`
  }

  get fullAddress(): string {
    return `${this.address}${this.address2 && ` | ${this.address2}`}`
  }

  get cityStateZip(): string {
    return `${this.city}${this.state && `, ${this.state}`} ${this.zipCode}`
  }
}

export type BillingAddress = {
  name?: string
  address?: string
  address2?: string
  city?: string
  state?: string
  country?: string
  zipCode?: string
}

export type AddressType = 'shipping' | 'billing' | 'default'

export class AccountAddress extends ApiResource implements Data.Address, Data.Identified {
  static readonly urlRoot = `/api/account/addresses`
  readonly id: string | number = 0
  readonly address: string = ''
  readonly address2: string = ''
  readonly address3: string = ''
  readonly city: string = ''
  readonly companyName: string = ''
  readonly country: string = 'US'
  readonly firstName: string = ''
  readonly lastName: string = ''
  readonly notes: string = ''
  readonly phoneNumber: string = ''
  readonly state: string = ''
  readonly type: AddressType = 'shipping'
  readonly zipCode: string = ''
  readonly verified: boolean = false
  readonly default: boolean = false

  static getAll(): AccountAddress[] | [] {
    return useResource(this.list(), {})
  }

  static async add(address: AccountAddress): Promise<AccountAddress> {
    return await super
      .fetch(this.urlRoot, {
        method: 'POST',
        body: JSON.stringify(address),
      })
      .then((result) => {
        return Object.assign(new AccountAddress(), result)
      })
  }

  static async patch(id: Data.ID, address: AccountAddress): Promise<AccountAddress> {
    return await super
      .fetch(`${this.urlRoot}/${id}`, {
        method: 'PATCH',
        body: JSON.stringify(address),
      })
      .then((result) => {
        return Object.assign(new AccountAddress(), result)
      })
  }

  static async remove(id: Data.ID): Promise<GeneralResponse> {
    return await super
      .fetch(`${this.urlRoot}/${id}`, {
        method: 'DELETE',
      })
      .then((result) => {
        return Object.assign(new GeneralResponse(), result)
      })
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }

  get fullAddress(): string {
    return [this.address, this.address2].join(' | ')
  }

  get street(): string {
    return `${this.address}${this.address2 && `| ${this.address2}`}`
  }
}
