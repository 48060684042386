import { FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import './CardBadge.less'

export type BadgeProps = {
  type: 'primary' | 'info' | 'warning' | 'danger'
  responsive: boolean
}

/**
 *
 * @param type Badge type
 * @param children
 * @constructor
 */
export const CardBadge: FC<Partial<BadgeProps>> = ({ type, responsive, children }) => {
  return (
    <div
      className={withPrefix(
        'card-badge',
        responsive ? 'card-badge-response' : '',
        `${type !== undefined}` ? `card-badge-${type}` : '',
      )}
    >
      {children}
    </div>
  )
}
