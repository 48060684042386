import { StyleSheet, Text, View } from '@react-pdf/renderer'
import { CSSProperties, FC, ReactNode, ReactNodeArray } from 'react'

const Styles = StyleSheet.create({
  body: {
    padding: '32px 20px 50px',
  },
  flex: {
    display: 'flex',
    width: '100%',
  },
  title: {
    fontFamily: 'Jost',
    fontSize: '18px',
    fontWeight: 800,
    textTransform: 'uppercase',
  },
  subTitle: {
    fontFamily: 'Roboto-Heavy',
    fontSize: '14px',
    marginBottom: '10px',
  },
  borderedTitle: {
    fontFamily: 'Roboto-Heavy',
    lineHeight: '1',
    fontSize: '14px',
    marginBottom: '0',
    border: '2px solid #000000',
    padding: '12px ',
  },
  lineTitle: {
    fontFamily: 'Roboto-Heavy',
    lineHeight: '1',
    fontSize: '14px',
    marginBottom: '0',
    padding: '12px',
    paddingLeft: '0',
  },
  table: {
    table: 'auto',
    width: '100%',
    tableLayout: 'auto',
    textAlign: 'left',
    borderRadius: '4px 4px 0 0',
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  tr: {
    flexDirection: 'row',
    margin: 'auto',
  },
  th: {
    padding: '8px 8px',
    fontSize: '8px',
    textAlign: 'left',
    color: '#212224',
    fontWeight: 'bold',
    backgroundColor: 'transparent',
    border: 'none',
    overflowWrap: 'break-word',
  },
  td: {
    textAlign: 'left',
    color: '#212224',
    fontSize: '8px',
    margin: 'auto',
    overflowWrap: 'break-word',
  },
  label: {
    color: '#212224',
    fontSize: '8px',
    fontWeight: 'bold',
  },
  text: {
    color: '#212224',
    fontSize: '8px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  image: {
    width: 500,
    height: 500,
  },
})

interface IFlex {
  justify?: 'flex-start' | 'flex-end' | 'center' | 'space-around' | 'space-between' | 'space-evenly'
  align?: 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
  wrap?: boolean
  gap?: number
  width?: string | number
  children: ReactNode | ReactNodeArray
  style?: CSSProperties
}
const TextItem: FC<{ label?: boolean; size?: string; align?: 'left' | 'right' | 'center' }> = ({
  label = false,
  size = '8px',
  align = 'left',
  children,
}) => (
  <Text
    style={{
      color: '#212224',
      fontSize: size,
      fontWeight: label ? 'bold' : 'normal',
      fontFamily: label ? 'Roboto-Heavy' : 'Roboto',
      textAlign: align,
    }}
  >
    {children}
  </Text>
)

const Table: FC = ({ children }) => <View style={Styles.table}>{children}</View>
const TableRow: FC<{ border?: boolean }> = ({ border = true, children }) => (
  <View style={{ borderBottom: border ? '1px solid #f0f0f0' : '', ...Styles.tr }}>{children}</View>
)
const TableHeading: FC<{ width?: string | number }> = ({ width, children }) => (
  <View style={{ ...Styles.th, width: width }}>{children}</View>
)
const TableData: FC<{ width?: string | number; padding?: string | number }> = ({ width, padding, children }) => (
  <View style={{ width: width, padding: padding ? padding : '8px 8px', ...Styles.td }}>{children}</View>
)

const Vertical: FC<IFlex> = ({ width, style, justify = 'flex-start', align, wrap = true, children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: align,
      justifyContent: justify,
      flexWrap: wrap ? 'wrap' : undefined,
      width: width,
      gap: 4,
      ...style,
    }}
  >
    {children}
  </div>
)

const Horizontal: FC<IFlex> = ({ width, style, justify = 'flex-start', align, wrap = true, children }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: align,
      justifyContent: justify,
      flexWrap: wrap ? 'wrap' : undefined,
      width: width,
      gap: 4,
      ...style,
    }}
  >
    {children}
  </div>
)

const HorizontalLine: FC<{ width?: string | number }> = ({ width }) => (
  <div
    style={{
      width: width,
      height: 2,
      backgroundColor: '#cccccc',
      margin: '5px auto',
    }}
  />
)

const PDFelement = {
  TextItem,
  Table,
  TableRow,
  TableHeading,
  TableData,
  Vertical,
  Horizontal,
  HorizontalLine,
  Styles,
} as const

export { PDFelement }
