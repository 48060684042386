import {
  createContext,
  Dispatch,
  FC,
  lazy,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { HubProvider } from 'src/sdk/contexts/Hub'
import { useAuth } from '../sdk/contexts/Auth'
import './index.less'

const Authorized: FC = lazy(() => import('./Authorized'))
const Unauthorized: FC = lazy(() => import('./Unauthorized'))

type SiderState = '' | 'hidden' | 'collapsed' | 'expanded'

type LayoutStateProps = {
  siderState: SiderState
  setSiderState: Dispatch<SetStateAction<SiderState>>
  footerVisible: Boolean
  setFooterVisible: Dispatch<SetStateAction<boolean>>
}

const LayoutState = createContext<LayoutStateProps>({
  siderState: '',
  setSiderState: (value) => value,
  footerVisible: true,
  setFooterVisible: (value) => value,
})

function LayoutProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const { authenticated } = useAuth()
  const [siderState, setSiderState] = useState<SiderState>('')
  const [footerVisible, setFooterVisible] = useState(true)

  return (
    <LayoutState.Provider
      value={{
        siderState,
        setSiderState,
        footerVisible,
        setFooterVisible,
      }}
    >
      {authenticated ? (
        <HubProvider>
          <Authorized>{children}</Authorized>
        </HubProvider>
      ) : (
        <Unauthorized>{children}</Unauthorized>
      )}
    </LayoutState.Provider>
  )
}

function useLayoutState(): LayoutStateProps {
  return useContext(LayoutState)
}

export { LayoutProvider, useLayoutState }
