import { Typography } from 'antd'
import { FC } from 'react'
import { VerticalSpace } from 'src/sdk/components/layout'
import {} from 'src/sdk/contexts/Notification'
import { BankAccount } from 'src/sdk/datasource/wallet/bankaccount'

const GoCardlessVerifyBankAccount: FC<Data.Source<BankAccount>> = ({ data }) => {
  return (
    <VerticalSpace>
      <Typography.Title level={1}>Pending Verification</Typography.Title>
      <Typography.Paragraph>
        Your account must be verified before use and may take up to 4 business days to complete. You will receive an
        email to notify when this account is verified and ready for use.
      </Typography.Paragraph>
    </VerticalSpace>
  )
}

export default GoCardlessVerifyBankAccount
