import { Entity, Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { CartItemProps, Monetary, UpdateRequest } from '../contexts/cart/Cart'
import { AccountEntity } from './account/account'
import { AccountAddress } from './address'
import { AppointmentCartItem } from './appointment'
import { ApiResource, ApiSingletonResource } from './entity'
import { EventCartItem } from './event'
import { ProductAddOn, ProductAttribute, ProductCartItem } from './product'
import { ReservationCartItem } from './reservations'
import { ShippingOption, ShippingOptionType } from './shipping'

export type CartItemType = 'appointment' | 'event' | 'product' | 'reservation'
export type CartItemsType = 'appointments' | 'tickets' | 'products' | 'reservations'

// export type CartItemsGroupProps = {
//   readonly [key in CartItemsType]: CartItemProps[]
// }

export type ShippingType = 'delivery' | 'pickup'
export type ShippingCourier =
  | ''
  | 'fedex'
  | 'ups'
  | 'usps'
  | 'flat_rate'
  | 'pickup'
  | 'dine_in'
  | 'take_out'
  | 'delivery'

class CartItemsGroup extends Entity {
  readonly reservations?: ReservationCartItem[]
  readonly appointments?: AppointmentCartItem[]
  readonly tickets?: EventCartItem[]
  readonly products?: ProductCartItem[]

  pk() {
    return 'items'
  }

  static get key() {
    return '/api/cart'
  }
}

export type CartItem = ReservationCartItem | AppointmentCartItem | EventCartItem | ProductCartItem

type DiscountItemType = '' | 'Dollar' | 'Percent' | 'Member'
class DiscountItem extends Entity {
  readonly type: DiscountItemType = ''
  readonly discount: number = 0
  readonly value: number = 0
  readonly message: string = ''
  pk() {
    return `${this.type}-${this.discount}-${this.value}`
  }
}

export class Shipping extends Entity {
  readonly price: number = 0
  readonly courier?: ShippingCourier
  readonly method: string = ''

  pk() {
    return `${this.courier}-${this.price}`
  }
}

class ShippingParams extends Entity {
  id?: string = ''
  method?: string | undefined
  courier?: ShippingCourier | undefined
  courierCode?: string | undefined
  price?: number | undefined
  type?: ShippingOptionType | undefined
  customerAddressId?: number | string = 0
  locationId?: number | string = ''
  address?: AccountAddress

  pk() {
    return this.id
  }

  constructor(option: ShippingOption, address?: AccountAddress) {
    super()
    this.id = option.id as string
    this.method = option.method
    this.courier = option.courier
    this.courierCode = option.courierCode
    this.price = option.price
    this.type = option.type
    if (option.type === 'pickup') {
      this.locationId = option.id
    } else {
      if (address) {
        this.address = address
        this.customerAddressId = address.id
      }
    }
  }
}
export type BillingState = {
  cardNumber?: string
  expDate?: string
  cvc?: string
  name?: string
  zipCode?: string
  country?: string
  saveCard?: boolean
  cardId?: number | string
}

export type AbstractCartItems = ProductCartItem | EventCartItem | ReservationCartItem | AppointmentCartItem

export abstract class AbstractCartItem extends ApiResource implements CartItemProps {
  readonly id: number = 0
  readonly quantity: number = 1
  readonly price: number = 0
  readonly priceEach: number = 0
  readonly title: string = ''
  readonly description: string = ''
  readonly photo: string = ''
  readonly minQty: number = 0
  readonly maxQty: number = 0
  readonly discount?: number
  readonly reserved: boolean = false
  readonly reservedUntil: Date = new Date()
  readonly type: CartItemType = 'event'
  readonly guestId?: number
  readonly guest?: AccountEntity

  abstract get item(): Data.Identified

  get placeholder(): Design.PlaceholderIcon {
    switch (this.type) {
      case 'reservation':
        return 'rooms'
      case 'appointment':
        return 'experience'
      case 'event':
        return 'event-items'
      case 'product':
        return 'items'
    }
  }

  static get key() {
    return '/api/cart/items'
  }

  get itemId(): Data.ID {
    return this.id
  }
}

export class AddToCart {
  updateQuantity?: boolean = false
  quantity?: number = 0
}

export type AddCartProduct = {
  productId: Data.ID
  quantity: number
  addOns?: ProductAddOn[]
  attributes?: ProductAttribute[]
  updateQuantity?: boolean
}

export type AddCartTicket = {
  id: Data.ID
  quantity: number
  updateQuantity?: boolean
}

export type AddCartReservation = {
  id: Data.ID
  quantity: number
  notes?: string
}

export type AddCartRoom = {
  ids: Data.ID[]
  quantity: number
  notes?: string
}

export type AddCartAppointment = {
  id: string
  quantity: number
  notes?: string
  guestId?: Data.ID
}

export type UpdateCartAppointment = {
  item: AppointmentCartItem
  quantity: number
  notes?: string
}

class CartResource extends ApiSingletonResource implements Monetary {
  static readonly urlRoot = `/api/cart`
  readonly currency: string = 'USD'
  readonly discountTotal: number = 0
  readonly total: number = 0
  readonly totalDue: number = 0
  readonly totalItems: number = 0
  readonly subTotal: number = 0
  readonly taxTotal: number = 0
  readonly shipping?: ShippingParams
  readonly handlingTotal: number = 0
  readonly postpayTotal: number = 0
  readonly discountItems: DiscountItem[] = []
  readonly items: CartItemsGroup = new CartItemsGroup()

  get hasPhysical(): boolean {
    return this.items.products ? this.items?.products?.length > 0 : false
  }

  get summary(): Array<Data.Named & { value: number }> {
    return [
      { name: 'Subtotal', value: this.subTotal },
      { name: 'Shipping', value: this.handlingTotal },
      {
        name: 'Tax',
        value: this.taxTotal,
      },
      { name: 'Discount', value: this.discountTotal },
      { name: 'Total', value: this.total },
    ]
  }

  static get(): CartResource {
    return useResource(this.detail(), {})
  }

  static forceFetch(): Promise<CartResource> {
    return super
      .fetch(this.urlRoot, {
        method: 'GET',
      })
      .then<CartResource>()
  }

  static addCoupon<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate().extend({
      url: () => `${this.urlRoot}/coupon`,
    })
    return endpoint.extend({
      fetch: (code: string) => {
        return endpoint.fetch.call(endpoint, null, {
          code: code,
        })
      },
      schema: DiscountItem
    })
  }

  static removeCoupon<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate().extend({
      url: () => `${this.urlRoot}/coupon`,
      method: 'DELETE'
    })
    return endpoint.extend({
      fetch: () => {
        return endpoint.fetch.call(endpoint, null, null)
      }
    })
  }

  static update<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (type: CartItemType, params: UpdateRequest) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${type}s`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }

  static products<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (params: AddCartProduct) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/products`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }

  static ticket<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return endpoint.extend({
      fetch: async (params: AddCartTicket) => {
        return await endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/tickets`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }

  // Add multiple tickets
  static tickets<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return endpoint.extend({
      fetch: (params: AddCartTicket[]) => {
        return Promise.all(
          params.map((ticket) => {
            return endpoint.fetch.call(
              endpoint.extend({
                url: () => `${this.urlRoot}/tickets`,
              }),
              {},
              ticket,
            )
          }),
        )
      },
      schema: [this],
    })
  }

  static rooms<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (params: AddCartRoom) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/reservations`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }

  static reservations<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (params: AddCartReservation) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/reservations`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }

  static appointments<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (params: AddCartAppointment) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/appointments`,
          }),
          {},
          params,
        )
      },
      schema: this,
    })
  }

  static add<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (type: CartItemType, item: any) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${type}s`,
          }),
          {},
          item,
        )
      },
      schema: this,
    })
  }
}
export { CartResource, ShippingParams, CartItemsGroup, DiscountItem }
