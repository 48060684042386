import { Drawer as AntDrawer, DrawerProps } from 'antd'
import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  Suspense,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import { withFullScreenDrawer } from '../components/drawer'
import RightSideDrawer from '../components/drawer/RightSideDrawer'
import { Button } from '../components/form'
import { SectionLoader } from '../components/loader'
import Modal, { ModalProps } from '../components/modal/Modal'

const Drawer = withFullScreenDrawer(AntDrawer)

type DrawerLinkProps = {
  type?: 'link' | 'button'
  component: ReactNode | FC
  className?: string
  goTo: string
}

const DrawerLink: FC<DrawerLinkProps> = ({ type, component, goTo, className, children }) => {
  const { setDrawer } = useDynamicDrawer()

  const onClick = (evt) => {
    evt.preventDefault()
    setDrawer({
      path: goTo,
      content: component,
    })
  }

  return type === 'link' ? (
    <Link className={className} type={'link'} to={''} onClickCapture={onClick}>
      {children}
    </Link>
  ) : (
    <Button className={className} theme={'text'} type={'link'} onClickCapture={onClick}>
      {children}
    </Button>
  )
}

export type DynamicDrawerProps = {
  path?: string
  className?: string
  onClose?: () => void
  pathOnClose?: string
  content: FC | ReactNode
  type?: 'full' | 'right'
  size?: 'small' | 'medium' | 'large'
  destroyOnClose?: boolean
  zIndex?: number
} & Partial<DrawerProps>

type DynamicModalProps = {
  content: FC | ReactNode
} & Partial<ModalProps>

type DrawerContextProps = {
  drawerCloseable: boolean
  drawerVisible: boolean
  drawerCloseCallback: (() => void) | undefined
  setDrawerCloseCallback: Dispatch<SetStateAction<(() => void) | undefined>>
  setDrawerCloseable: Dispatch<SetStateAction<boolean>>
  setDrawer: (props: DynamicDrawerProps) => void
  setDrawerVisible: Dispatch<SetStateAction<boolean>>
  setModal: (props: DynamicModalProps) => void
  setModalVisible: Dispatch<SetStateAction<boolean>>
  closeDrawer: () => void
}

const defaultProps: DrawerContextProps = {
  drawerCloseable: true,
  drawerVisible: false,
  drawerCloseCallback: undefined,
  setDrawerCloseCallback: () => {},
  setDrawer: (value) => value,
  setDrawerCloseable: (value) => value,
  setDrawerVisible: (value) => value,
  setModal: (value) => value,
  setModalVisible: (value) => value,
  closeDrawer: () => {},
}

const DynamicDrawer = createContext(defaultProps)

function DynamicDrawerProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const location = useLocation()
  const [drawerCloseable, setDrawerCloseable] = useState(true)
  const [drawerCloseCallback, setDrawerCloseCallback] = useState<() => void>()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [drawerProps, setDrawerProps] = useState<DynamicDrawerProps>()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalProps, setModalProps] = useState<DynamicModalProps>()

  const [prevLocation, setPrevLocation] = useState('')

  // Close the drawer/modal if location changes
  useEffect(() => {
    handleDrawerClose()
    handleModalClose()
  }, [location])

  useLayoutEffect(() => {
    if (!drawerVisible) {
      handleDrawerClose()
    }
  }, [drawerVisible])

  useLayoutEffect(() => {
    if (!modalVisible) {
      handleModalClose()
    }
  }, [modalVisible])

  const handleVisibility = (visible: boolean) => {
    drawerProps?.afterVisibleChange && drawerProps.afterVisibleChange(visible)
    if (visible && drawerProps?.path) {
      window.history.replaceState(null, '', drawerProps.path)
    }
    if (!visible) {
      setDrawerProps(undefined)
    }
  }

  const handleDrawerClose = () => {
    if (!!drawerCloseCallback) {
      drawerCloseCallback()
      setDrawerCloseCallback(undefined)
      return
    }

    if (drawerProps?.pathOnClose && drawerProps?.path) {
      window.history.replaceState(null, '', drawerProps?.pathOnClose)
    } else if (prevLocation && drawerProps?.path) {
      window.history.replaceState(null, '', prevLocation)
    }
    drawerProps?.onClose && drawerProps.onClose()
    setDrawerVisible(false)
    setDrawerCloseable(true)
  }

  const handleModalClose = () => {
    setModalVisible(false)
  }

  function setDrawer(props: DynamicDrawerProps) {
    if (props.path) {
      setPrevLocation(window.location.pathname)
    }
    setDrawerProps(props)
    setDrawerVisible(true)
  }

  function closeDrawer() {
    if (drawerProps) {
      if (drawerProps?.pathOnClose && drawerProps?.path) {
        window.history.replaceState(null, '', drawerProps?.pathOnClose)
      } else if (prevLocation && drawerProps?.path) {
        window.history.replaceState(null, '', prevLocation)
      }
      drawerProps?.onClose && drawerProps.onClose()
    }
    setDrawerVisible(false)
  }

  function setModal(props: DynamicModalProps) {
    setModalProps(props)
    setModalVisible(true)
  }

  let FullDrawer = useMemo(
    () => (
      <Drawer
        destroyOnClose={drawerProps?.destroyOnClose ?? true}
        visible={drawerProps?.type === 'full' || (drawerProps?.type === undefined && drawerVisible)}
        onClose={handleDrawerClose}
        afterVisibleChange={handleVisibility}
        className={drawerProps?.className}
        closable={drawerCloseable}
        maskClosable={drawerCloseable}
        zIndex={drawerProps?.zIndex}
      >
        <Suspense fallback={<SectionLoader />}>{drawerProps?.content}</Suspense>
      </Drawer>
    ),
    [drawerProps, drawerVisible, drawerCloseable],
  )

  return (
    <DynamicDrawer.Provider
      value={{
        drawerCloseable,
        drawerVisible,
        setDrawerCloseable,
        setDrawer,
        drawerCloseCallback,
        setDrawerCloseCallback,
        setDrawerVisible,
        setModal,
        setModalVisible,
        closeDrawer,
      }}
    >
      {children}
      {FullDrawer}

      <RightSideDrawer
        size={drawerProps?.size}
        destroyOnClose={drawerProps?.destroyOnClose ?? true}
        visible={drawerProps?.type === 'right' && drawerVisible}
        onClose={handleDrawerClose}
        afterVisibleChange={handleVisibility}
        className={drawerProps?.className}
        footer={drawerProps?.footer}
      >
        {drawerProps?.content}
      </RightSideDrawer>

      <Modal
        destroyOnClose
        onCancel={(e) => {
          if (!modalProps?.closable) {
            e.preventDefault()
            return
          }
          handleModalClose()
          modalProps && modalProps?.onCancel && modalProps.onCancel(e)
        }}
        visible={modalVisible}
        {...modalProps}
      >
        <Suspense fallback={<SectionLoader />}>{modalProps?.content}</Suspense>
      </Modal>
    </DynamicDrawer.Provider>
  )
}

const useDynamicDrawer: () => DrawerContextProps = () => useContext(DynamicDrawer)

export { DynamicDrawerProvider, useDynamicDrawer, DrawerLink }
