import { Carousel as Wrapped, CarouselProps } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import React, { Children, FC, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useDragDetection } from 'src/sdk/hooks/useDragDetection'
import { Button } from '../form'
import IvyIcon from '../icon'
import { Image } from '../image'
import { Breakpoint } from '../screen/Breakpoint'
import './Carousel.less'

const Carousel: FC<CarouselProps> = (props) => (
  <Wrapped
    className={withPrefix(props.className ? props.className : '', props.dots ? 'carousel-with-dots' : '')}
    dots={props.dots ?? false}
    infinite={false}
    draggable
    speed={500}
    slidesToShow={props.slidesToShow ?? 3}
    slidesToScroll={props.slidesToScroll ?? 3}
    responsive={
      props.responsive ?? [
        {
          breakpoint: Breakpoint.LG,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: Breakpoint.SM,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ]
    }
    {...props}
  />
)

export type CarouselBannerProps = {
  data?: string[]
  height?: number
  navigation?: boolean
  dots?: boolean
  placeholder: Design.PlaceholderIcon
} & CarouselProps

const CarouselBanner: FC<CarouselBannerProps> = ({
  data,
  height,
  navigation = true,
  dots = true,
  children,
  placeholder,
  ...props
}) => {
  const slider = useRef<CarouselRef>(null)
  const [images, setImages] = useState<ReactNode[]>([])
  const [prevEnabled, setPrevEnabled] = useState(true)
  const [nextEnabled, setNextEnabled] = useState(true)

  const beforeChange = (from: number, to: number) => {
    setPrevEnabled(to > 0)
    setNextEnabled(to < images.length - 1)
  }

  useEffect(() => {
    setImages(
      data?.length
        ? data.map((item, index) => <Image height={height} type={placeholder} alt={item} src={item} key={index} />)
        : [<Image height={height} type={placeholder} />],
    )
  }, [data])

  return (
    <div
      className={withPrefix(
        'carousel-banner',
        (navigation || dots) && images.length > 1 ? 'carousel-banner-with-nav' : '',
      )}
      style={props.style}
    >
      <Wrapped
        ref={slider}
        arrows={false}
        dots={dots}
        infinite={false}
        autoplay
        draggable
        speed={500}
        beforeChange={beforeChange}
        {...props}
      >
        {images ?? children}
      </Wrapped>
      {navigation && images.length > 1 && (
        <div className={withPrefix('carousel-banner-nav')}>
          <Button
            disabled={!prevEnabled}
            className={withPrefix('carousel-banner-nav-arrows-prev')}
            type={'ghost'}
            icon={<IvyIcon size={20} type={'directional/arrow-left'} />}
            onClick={() => slider.current?.prev()}
          />
          <Button
            disabled={!nextEnabled}
            className={withPrefix('carousel-banner-nav-arrows-next')}
            type={'ghost'}
            icon={<IvyIcon size={20} type={'directional/arrow-right'} />}
            onClick={() => slider.current?.next()}
          />
        </div>
      )}
    </div>
  )
}

export type CardCarouselProps = {
  navigation?: boolean
  dots?: boolean
  onChange?: (from: number, to: number) => void
} & CarouselProps

const CardCarousel: FC<CardCarouselProps> = ({ navigation = false, dots = true, onChange, children, ...props }) => {
  const slider = useRef<CarouselRef>(null)
  const count = Children.count(children)
  const [prevEnabled, setPrevEnabled] = useState(false)
  const [nextEnabled, setNextEnabled] = useState(true)
  const { handleMouseDown, handleChildClick } = useDragDetection()

  const beforeChange = (from: number, to: number) => {
    onChange && onChange(from, to)
    setPrevEnabled(to > 0)
    setNextEnabled(to < count - 1)
  }

  return (
    <div className={withPrefix('carousel-card', navigation || dots ? 'carousel-card-with-nav' : '')}>
      <Wrapped ref={slider} arrows={false} dots={dots} draggable speed={500} beforeChange={beforeChange} {...props}>
        {children &&
          React.Children.map(children, (child) => (
            <div onMouseDownCapture={handleMouseDown} onClickCapture={handleChildClick}>
              {child}
            </div>
          ))}
      </Wrapped>
      {navigation && (
        <div className={withPrefix('carousel-banner-nav')}>
          <Button
            disabled={!prevEnabled}
            className={withPrefix('carousel-banner-nav-arrows-prev')}
            type={'ghost'}
            icon={<IvyIcon size={20} type={'directional/arrow-left'} />}
            onClick={() => slider.current?.prev()}
          />
          <Button
            disabled={!nextEnabled}
            className={withPrefix('carousel-banner-nav-arrows-next')}
            type={'ghost'}
            icon={<IvyIcon size={20} type={'directional/arrow-right'} />}
            onClick={() => slider.current?.next()}
          />
        </div>
      )}
    </div>
  )
}

type CarouselItemsProps = {
  height?: number
  navigation?: boolean
  dots?: boolean
  children: ReactElement<any>[] | ReactElement<any>
} & CarouselProps

const CarouselItems: FC<CarouselItemsProps> = ({ height, navigation = true, dots = true, children, ...props }) => {
  const slider = useRef<CarouselRef>(null)
  const [images, setImages] = useState<ReactNode[]>([])
  const [prevEnabled, setPrevEnabled] = useState(true)
  const [nextEnabled, setNextEnabled] = useState(true)

  const beforeChange = (from: number, to: number) => {
    setPrevEnabled(to > 0)
    setNextEnabled(to < images.length - 1)
  }

  return (
    <div
      className={withPrefix(
        'carousel-items',
        (navigation || dots) && images.length > 1 ? 'carousel-items-with-nav' : '',
      )}
      style={props.style}
    >
      <Wrapped
        ref={slider}
        arrows={false}
        dots={dots}
        infinite={false}
        autoplay
        draggable
        speed={500}
        beforeChange={beforeChange}
        {...props}
      >
        {children}
      </Wrapped>
      {navigation && images.length > 1 && (
        <div className={withPrefix('carousel-items-nav')}>
          <Button
            disabled={!prevEnabled}
            className={withPrefix('carousel-items-nav-arrows-prev')}
            type={'ghost'}
            icon={<IvyIcon size={20} type={'directional/arrow-left'} />}
            onClick={() => slider.current?.prev()}
          />
          <Button
            disabled={!nextEnabled}
            className={withPrefix('carousel-items-nav-arrows-next')}
            type={'ghost'}
            icon={<IvyIcon size={20} type={'directional/arrow-right'} />}
            onClick={() => slider.current?.next()}
          />
        </div>
      )}
    </div>
  )
}
export { Carousel, CarouselBanner, CardCarousel, CarouselItems }
