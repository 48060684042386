import { Country } from "../datasource/intl"

export function useCountries() {
    const countries = Country.getAll()
    const postalValidator = (country: string) => {
        const countryObj = countries.find(c => c.abbreviation === country)
        if (!countryObj || !countryObj.postalValidator) return
        return new RegExp(countryObj.postalValidator, 'gi')
    }
    return { countries, postalValidator }
}