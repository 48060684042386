import { FC } from 'react'
import Statement from 'src/components/account/tabs/statement/Statement'
import { CenteredContent } from 'src/sdk/components/layout'
import { IDrawerDetailView } from '../DrawerDetailProvider'

const StatementDrawer: FC<IDrawerDetailView> = ({ id }) => {
  return (
    <CenteredContent width={1200}>
      <Statement id={id} />
    </CenteredContent>
  )
}

export default StatementDrawer
