import GoogleMapReact from 'google-map-react'
import { FC, useEffect, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import Marker, { MarkerContentProps } from './Marker'
import './GoogleMap.less'
import { SectionLoader } from '../loader'

export type Location = {
  lat: string | number
  lng: string | number
}

interface GoogleMapProps {
  height: string | number
  width?: string | number
  zoom?: number
  location: Location
  content: MarkerContentProps
  delay?: number
}

const GoogleMap: FC<GoogleMapProps> = ({ height, width, location, content, delay, zoom = 16 }) => {
  const [visible, setVisible] = useState(delay ? false : true)
  useEffect(() => {
    delay && setTimeout(() => setVisible(true), delay)
  }, [])

  return (
    <div className={withPrefix('google-maps-map')} style={{ height: height, width: width ? width : '100%' }}>
      {!visible ? (
        <SectionLoader />
      ) : (
        <GoogleMapReact
          yesIWantToUseGoogleMapApiInternals
          bootstrapURLKeys={{ key: 'AIzaSyCrMwpg_hX7MNgvcoPifg0xT2JsiEsYo_Y' }}
          defaultCenter={{
            lat: Number(location.lat),
            lng: Number(location.lng),
          }}
          options={{
            mapTypeId: 'roadmap',
          }}
          zoom={zoom}
        >
          <Marker
            id={'marker'}
            content={content}
            onClick={() => {}}
            lat={Number(location.lat)}
            lng={Number(location.lng)}
          />
        </GoogleMapReact>
      )}
    </div>
  )
}

export default GoogleMap
