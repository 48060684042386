import { Col, Row } from 'antd'
import { FC } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Flex } from './Grid'

export type CenteredContentProps = {
  width?: string | number
  verticalAlign?: boolean
}

export const CenteredContent: FC<CenteredContentProps> = ({ width = 930, verticalAlign = false, children }) => {
  return (
    <Flex align={verticalAlign ? 'center' : 'start'} justify={'center'} style={{ width: '100%' }}>
      <div style={{ width: '100%', maxWidth: width }}>{children}</div>
    </Flex>
  )
}
