import { CompanyVersion } from "./company";
import { ApiResource } from "./entity";

class GlobalNetworkCompany extends ApiResource {
    static readonly urlRoot = `/api/account/global-networks`
    readonly id: number = 0
    readonly name: string = ''
    readonly logoUrl: string = ''
    readonly iconUrl: string = ''
    readonly address: string = ''
    readonly address2: string = ''
    readonly city: string = ''
    readonly state: string = ''
    readonly zipCode: string = ''
    readonly country: string = ''
    readonly version: CompanyVersion = 'nextgen'
    readonly portal: {
        url: string
        version: 'Version3' | 'Version4'
    } = {
            url: '',
            version: 'Version4'
        }
}

export { GlobalNetworkCompany }