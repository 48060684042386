import { JSEncrypt } from 'jsencrypt'
import snakecaseKeys from 'snakecase-keys'
const public_key = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs7/RuBKApOSd5qUt+AtC
h+QWiKiV2xZMnrNxBl0mqojP+HtOD6bOmxCauL3Br7WYac2lV705AtFN8TAR5v5g
aykP2+CjzDEeR7MrsN8iGvj+/KR6FulDgBg2UiuuIXyMHR3bLCskJruifFatA95u
3ASXEUXN8ZKwsEuJMNpba2mHaPAa8ObOz7Q/Gm2C/bmlDurjPn+Cw1cvPNMrydXF
kf8X1AmCIjcuRhAdjeOxxDn5W7BUV0oFCvbYB66Rfy8KUxXs83ihUTExyodk6PKs
cSouBTS+AZ+KCR5XyWqZNKxZPVqukEcjY49l97he7Hh195nfRocopJnWoKKOuvZl
YwIDAQAB
-----END PUBLIC KEY-----`

const Encrypt = (data: object | string, serialize: boolean = true) => {
  let value: string = ''
  if (typeof data === 'object') {
    if (serialize) {
      // Convert camelCase to snake_case
      value = JSON.stringify(snakecaseKeys(data, { deep: true }))
    } else {
      value = JSON.stringify(data)
    }
  } else {
    value = data
  }
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(public_key)
  const encrypted = encrypt.encrypt(value)
  return encrypted ? btoa(atob(encrypted).padStart(256, '\0')) : null
}

export default Encrypt
