import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'

const checkoutSlice = createSlice({
  name: 'app',
  initialState: {
    menuDrawerVisible: false,
  },
  reducers: {
    setMenuDrawerVisible(state, action: { payload: boolean }) {
      state.menuDrawerVisible = action.payload
    },
  },
})

export const { setMenuDrawerVisible } = checkoutSlice.actions
export const appSelector = (state: RootState) => state.app
export default checkoutSlice.reducer
