import { Form as AbstractForm, FormItemProps as AntFormItemProps } from 'antd'
import { FC } from 'react'
import { Button } from 'src/sdk/components/form/Button'
import { DatePicker } from 'src/sdk/components/form/DatePicker'
import { Form } from 'src/sdk/components/form/Form'
import { InputNumber } from 'src/sdk/components/form/InputNumber'
import { RadioSelect } from 'src/sdk/components/form/RadioSelect'
import { TimePicker } from 'src/sdk/components/form/TimePicker'
import { Switch } from 'src/sdk/components/form/Switch'
import { FieldType } from 'src/sdk/datasource/survey'
import { withPrefix } from 'src/sdk/contexts/Config'

import './index.less'

const { Item: Wrapper, List } = AbstractForm

export type FormItemProps = {
  type?: FieldType
  label?: React.ReactNode
} & AntFormItemProps

const Item: FC<FormItemProps> = ({ type, label, ...props }) => {
  return (
    <Wrapper
      className={
        type
          ? withPrefix(`form-item-${type} `) +
            (label && (label as string).length && (label as string).length < 2 ? ' condensed' : '')
          : ''
      }
      label={label}
      {...props}
    />
  )
}

export { Form as default, Item, List, Button, DatePicker, InputNumber, TimePicker, RadioSelect, Switch }
