import { createSlice } from '@reduxjs/toolkit'
import { RootState } from './rootReducer'

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        groupVisible: false,
        activeGroup: ''
    },
    reducers: {
        setGroupVisible(state, action: { payload: boolean }) {
            state.groupVisible = action.payload
        },
        setActiveGroup(state, action: { payload: string }) {
            state.activeGroup = action.payload
        }
    },
})

export const { setGroupVisible, setActiveGroup } = chatSlice.actions
export const groupVisibleSelector = (state: RootState) => state.chat.groupVisible
export const activeGroupSelector = (state: RootState) => state.chat.activeGroup
export default chatSlice.reducer
