import { Col, notification, Row, Typography } from 'antd'
import { FC, useState } from 'react'
import { useController } from 'rest-hooks'
import Form, { Button, Item } from 'src/sdk/components/form'
import { FormItem } from 'src/sdk/components/form/FormItem'
import IvyIcon from 'src/sdk/components/icon'
import { VerticalSpace } from 'src/sdk/components/layout'
import { useNotification } from 'src/sdk/contexts/Notification'
import { AccountEntity, AccountUpdateProps } from 'src/sdk/datasource/account/account'

interface CreatePasswordProps {
  onSuccess: (data: AccountEntity) => void
  action: 'create' | 'change'
}

const CreatePassword: FC<CreatePasswordProps> = ({ action, onSuccess }) => {
  const { fetch } = useController()
  const { notifyOnError } = useNotification()
  const [enabled, setEnabled] = useState(false)
  const [loading, setLoading] = useState(false)

  const onFinish = (value: Partial<AccountUpdateProps>) => {
    setLoading(true)
    fetch(AccountEntity.updateProfile(), value)
      .then((response) => {
        notification.success({
          message: 'Password Updated',
          description: 'Your new password has been updated',
        })
        onSuccess(response)
      })
      .catch(notifyOnError)
      .finally(() => setLoading(false))
  }
  return (
    <Row align={'middle'}>
      <Col span={24}>
        <VerticalSpace size={'large'}>
          <VerticalSpace size={0}>
            <Typography.Title level={1} style={{ marginBottom: 0 }}>
              Create a Password
            </Typography.Title>
            <Typography.Text type={'secondary'}>
              Your account does not have a password created yet. <br />
              Let's secure your account with a new password.
            </Typography.Text>
          </VerticalSpace>

          <Form<Partial<AccountUpdateProps>> name={'createPassword'} onFinish={onFinish} onValidate={setEnabled}>
            <FormItem.Password
              name={['password', 'newPassword']}
              disabled={loading}
              onValidate={setEnabled}
              showLevel
            />
            <Item>
              <Button loading={loading} disabled={!enabled} block htmlType={'submit'}>
                Continue
                <IvyIcon type={'directional/arrow-right'} />
              </Button>
            </Item>
          </Form>
        </VerticalSpace>
      </Col>
    </Row>
  )
}

export { CreatePassword }
