import { Col, Form, Row, Typography } from 'antd'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useController } from 'rest-hooks'
import Card from 'src/sdk/components/card'
import Checkable from 'src/sdk/components/checkable'
import { Button } from 'src/sdk/components/form'
import { VerticalSpace } from 'src/sdk/components/layout'
import { OverlayLoader } from 'src/sdk/components/loader'
import SmallText from 'src/sdk/components/text/SmallText'
import { TextCentered } from 'src/sdk/components/text/TextCentered'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import { SubscriptionEntity } from 'src/sdk/datasource/subscription'
import { BankAccount } from 'src/sdk/datasource/wallet/bankaccount'
import { CreditCardEntity } from 'src/sdk/datasource/wallet/creditcard'
import { WalletBillingUse, WalletItem } from 'src/sdk/datasource/wallet/wallet'
import useWallet from 'src/sdk/hooks/useWallet'
import BankAccountAddForm from '../bank-account/BankAccountAddForm'
import BankAccountView from '../bank-account/BankAccountView'
import BillingUse from '../BillingUse'
import CreditCardAddForm from '../credit-card/CreditCardAddForm'
import CreditCardView from '../credit-card/CreditCardView'

export type PaymentMethodAddProps = {
  before?: () => void
  onCancel?: () => void
  onSuccess?: (paymentItem: WalletItem) => void
  onError?: () => void
  onLoading?: (loading: boolean) => void
  children?: ReactNode
  billingUse?: WalletBillingUse
  billingUseEnabled?: boolean
}

const PaymentMethodAdd: FC<PaymentMethodAddProps> = ({
  onCancel,
  onSuccess,
  billingUse = '',
  billingUseEnabled = true,
  ...props
}) => {
  const { fetch } = useController()
  const { achProcessor, stripeProcessor } = usePublicConfig()
  const [loading, setLoading] = useState(false)
  const { onUpdate } = useWallet()
  const [form] = Form.useForm()
  const [paymentType, setPaymentType] = useState<'card' | 'bank'>()
  const [currentBillingUse, setCurrentBillingUse] = useState<WalletBillingUse>(billingUse)
  const [selectedSubscriptions, setSubscriptions] = useState<Data.ID[]>([])
  const [hasSubscriptions, setHasSubscriptions] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [paymentMethod, setPaymentMethod] = useState<WalletItem>()

  const handleUpdate = (value?: WalletBillingUse, subscriptions?: Data.ID[]) => {
    value && setCurrentBillingUse(value)
    subscriptions && setSubscriptions(subscriptions)
  }

  const updateSubscriptionPaymentMethod = async (walletId: Data.ID, subscriptions: Data.ID[]) => {
    setLoading(true)
    return await Promise.all([
      ...subscriptions.map(
        async (subscriptionId) =>
          await fetch(
            SubscriptionEntity.partialUpdate(),
            { id: subscriptionId },
            {
              walletId: walletId,
            },
          ),
      ),
    ]).finally(() => setLoading(false))
  }

  useEffect(() => {
    setPaymentType(stripeProcessor ? 'card' : 'bank')
  }, [])

  useEffect(() => {
    setDisabled(
      hasSubscriptions &&
        currentBillingUse === 'billing' &&
        (!selectedSubscriptions || selectedSubscriptions?.length === 0),
    )
  }, [selectedSubscriptions, currentBillingUse, hasSubscriptions])

  const handleSuccess = async (paymentItem: WalletItem) => {
    setPaymentMethod(paymentItem)
    if (selectedSubscriptions && selectedSubscriptions.length > 0) {
      await updateSubscriptionPaymentMethod(paymentItem.id, selectedSubscriptions)
    }
    onUpdate(paymentItem)
    onSuccess && onSuccess(paymentItem)
  }

  return paymentMethod ? (
    paymentType === 'card' ? (
      <CreditCardView data={paymentMethod as CreditCardEntity} onDelete={onCancel} />
    ) : (
      <BankAccountView data={paymentMethod as BankAccount} onDelete={onCancel} />
    )
  ) : (
    <OverlayLoader fullScreen loading={loading}>
      <Row
        gutter={[
          {
            xs: 0,
            sm: 0,
            md: 55,
            lg: 55,
          },
          { xs: 24, sm: 24, md: 24, lg: 24 },
        ]}
      >
        <Col span={24}>
          <Typography.Title level={1}>Add Payment Method</Typography.Title>
        </Col>
        <Col span={24}>
          <Typography.Title level={3} type={'secondary'}>
            Select Payment Type
          </Typography.Title>
        </Col>
        <Col span={24} lg={14}>
          <VerticalSpace size={40}>
            <VerticalSpace>
              {stripeProcessor?.cardsAccepted && stripeProcessor.cardsAccepted.length > 0 && (
                <Checkable
                  title={
                    <Typography.Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                      Credit Card
                    </Typography.Title>
                  }
                  size={'lg'}
                  icon={'application/credit-card'}
                  checked={paymentType === 'card'}
                  onClick={() => setPaymentType('card')}
                />
              )}
              {achProcessor && (
                <Checkable
                  title={
                    <Typography.Title type={'secondary'} level={5} style={{ marginBottom: 0 }}>
                      Direct Debit
                    </Typography.Title>
                  }
                  size={'lg'}
                  icon={'application/bank'}
                  checked={paymentType === 'bank'}
                  onClick={() => setPaymentType('bank')}
                />
              )}
            </VerticalSpace>
            {paymentType === 'card' ? (
              <CreditCardAddForm
                form={form}
                onSuccess={handleSuccess}
                onLoading={setLoading}
                billingUse={currentBillingUse}
                {...props}
              >
                {billingUseEnabled && <BillingUse onChange={handleUpdate} onFetch={setHasSubscriptions} />}
              </CreditCardAddForm>
            ) : (
              <BankAccountAddForm form={form} onSuccess={handleSuccess} onLoading={setLoading} {...props} />
            )}
          </VerticalSpace>
        </Col>
        <Col span={24} lg={10}>
          <Card
            bordered
            style={{
              position: 'sticky',
              top: 0,
            }}
          >
            <VerticalSpace size={32}>
              <TextCentered>
                The protection of your data is one of the most important factors in our business. We are aware of your
                concerns and the data security regulations.
              </TextCentered>
              <VerticalSpace size={12}>
                <Button onClick={onCancel} disabled={loading} type={'ghost'} title={'Cancel'} block />
                {paymentType === 'card' && (
                  <VerticalSpace>
                    <Button
                      loading={loading}
                      disabled={loading || disabled}
                      type={'primary'}
                      onClick={() => form.submit()}
                      title={'Add Payment Method'}
                      block
                    />
                    {disabled && (
                      <SmallText align={'center'}>Choose a membership to add this payment method to.</SmallText>
                    )}
                  </VerticalSpace>
                )}
              </VerticalSpace>
            </VerticalSpace>
          </Card>
        </Col>
      </Row>
    </OverlayLoader>
  )
}

export default PaymentMethodAdd
