import { Tooltip, Typography } from 'antd'
import { FC } from 'react'
import IvyIcon from '../icon'

export const LabelTooltip: FC<{ label: string; helpText: string }> = ({ label, helpText }) => {
  return (
    <>
      {label}
      <Tooltip title={helpText}>
        <Typography.Link tabIndex={-1} href='#' style={{ marginLeft: '5px' }}>
          <IvyIcon type={'symbol/info-circle'} />
        </Typography.Link>
      </Tooltip>
    </>
  )
}
