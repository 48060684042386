import { RcFile } from 'antd/lib/upload/interface'

export const toBase64 = (file?: RcFile) =>
  new Promise<string>((resolve, reject) => {
    if (!file) return reject()
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })
