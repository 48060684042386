import { Avatar, Card, Typography } from 'antd'
import * as React from 'react'
import { FC, ReactNode } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { HorizontalSpace, VerticalSpace } from '../layout'
import { OptionsProps, Tag } from '../tag/Tag'
import './CardMeta.less'

type CardMetaProps = {
  avatar: ReactNode
  style?: React.CSSProperties
  title: ReactNode
  description: ReactNode
  bordered?: boolean
  className?: string
  avatarShape?: 'circle' | 'square'
  tag?: Partial<OptionsProps> & { title: string; visible?: boolean; align?: 'baseline' | 'center' | 'end' | 'start' }
}

export const CardMeta: FC<CardMetaProps> = (props) => {
  var className = props.className ?? ''
  if (props.tag) {
    className += ` ${withPrefix('card-meta-with-tag')}`
  }

  return (
    <Card.Meta
      avatar={
        <Avatar
          style={{
            color: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          shape={props.avatarShape ? props.avatarShape : 'square'}
          size={48}
          icon={props.avatar}
        />
      }
      style={{
        padding: `${props.bordered ? '10px 0' : '10px 0 0'}`,
        borderWidth: `${props.bordered ? '0 0 1px 0' : ''}`,
        borderStyle: `${props.bordered ? 'solid' : ''}`,
        display: 'flex',
        alignItems: 'center',
        ...props.style,
      }}
      title={props.tag === undefined ? props.title : ''}
      description={
        props.tag !== undefined ? (
          <HorizontalSpace style={{ justifyContent: 'space-between' }} align={props.tag.align ?? 'center'}>
            <VerticalSpace size={0}>
              {props.title}
              {props.description}
            </VerticalSpace>
            {props.tag.visible && (
              <Tag style={{ margin: 0 }} {...props.tag}>
                {props.tag.title}
              </Tag>
            )}
          </HorizontalSpace>
        ) : (
          props.description
        )
      }
      className={className}
    />
  )
}
