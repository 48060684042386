import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { LeftPadNumber } from 'src/sdk/helpers/numbers'
import { PaymentProcessor } from 'src/sdk/typings/payment'
import { BillingAddress } from '../address'
import { ApiResource } from '../entity'
import { WalletBillingUse, WalletInterface, WalletPaymentType, WalletStatus } from './wallet'

export type CreditCardCreateForm = {
  name: string
  zipCode: string
  country: string
  default?: boolean
  nickname?: string
  cardNumber: string
  cardExpiry: string
  cardCvc: string
  billingUse?: WalletBillingUse
  saveCard?: boolean
}

export type CreditCardCreate = {
  token: string
  nickname: string
  billingDetails: BillingAddress
  billingUse: WalletBillingUse
}

export type CreditCardUpdate = {
  default: boolean
  nickname: string
  expMonth: string
  expYear: string
  billingUse: WalletBillingUse
  billingDetails: BillingAddress
}

class CreditCardEntity extends ApiResource implements WalletInterface {
  static readonly urlRoot = `/api/account/wallet/cards`
  readonly id: Data.ID = ''
  readonly brand: string = ''
  readonly nickname: string = ''
  readonly billingUse: WalletBillingUse = 'billing'
  default: boolean = false
  readonly status: WalletStatus = 'active'
  readonly expMonth: number = 0
  readonly expYear: number = 0
  readonly lastFour: string = '0000'
  readonly billingDetails: BillingAddress = {}
  readonly verified: boolean = false
  readonly paymentType: WalletPaymentType = 'creditcard'
  readonly type: PaymentProcessor = ''
  readonly createdOn: Date = new Date()

  get fullExpiration(): string {
    if (this.expMonth && this.expYear) {
      return `${LeftPadNumber(this.expMonth)} / ${this.expYear.toString().slice(-2)}`
    }
    return ''
  }

  static add<T extends typeof Resource>(this: T) {
    const list = CreditCardEntity.list()
    return this.create().extend({
      schema: CreditCardEntity,
      update: (newResourceId: string) => ({
        [list.key({})]: (resourceIds: string[] = []) => [...resourceIds, newResourceId],
      }),
    })
  }

  static generateIntentSecret(processorId: Data.ID) {
    return this.query(`/api/account/wallet/stripe/${processorId}/intent`)
      .then((response) => response as string)
  }

  static getById(id: Data.ID): CreditCardEntity {
    return useResource(this.detail(), { id: id })
  }

  static getAll(): CreditCardEntity[] {
    return useResource(this.list(), {})
  }
}

export { CreditCardEntity }
