import { Buffer } from 'buffer'

export default function useBase64() {
  const encode = (data: any) => {
    let dataString = typeof data !== 'string' ? JSON.stringify(data) : data
    return Buffer.from(dataString).toString('base64')
  }

  const decode = (data: string) => {
    return Buffer.from(data, 'base64').toString('ascii')
  }

  function decodeTo<T>(data: string) {
    try {
      const decoded = Buffer.from(data, 'base64').toString('ascii')
      const obj = JSON.parse(decoded)
      return obj as T
    } catch (ex) {}
  }
  return {
    encode,
    decode,
    decodeTo,
  }
}
