import { Entity, Resource } from '@rest-hooks/rest'

export type MediaType = 'image' | 'video' | 'embed' | 'url' | 'file'
export class ImageEntity extends Entity {
  readonly title: string = ''
  readonly description: string = ''
  readonly url: string = ''
  readonly main: boolean = true
  readonly type: MediaType = 'image'

  pk(): string {
    return this.url
  }
}
