import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { AccountReservation } from './account/account'
import { AbstractCartItem } from './cart'
import { SchemaPaginated } from './entity'
import { ScheduleBlockType, SchedulerEntity, TimeSlotEntity } from './scheduler'

export type RoomType = 'meeting_rooms' | 'hotel_rooms'
export type ReservationBlockType = 'daily' | 'multi'

export type ReservationQuery = {
  group?: string
  search?: string
  blockType?: ReservationBlockType
} & Data.Paginated

export type ReservationExpressBook = {
  id?: string
  ids?: Data.ID[]
  partySize: number
}

class ReservationEntity extends SchedulerEntity {
  static urlRoot = `/api/reservations`
  readonly blockType: ReservationBlockType = 'daily'

  get image(): string {
    return this.graphic
  }

  static getAll(params: ReservationQuery): ReservationEntity[] {
    return useResource(this.list(), params)
  }

  static getDaily(params: Data.Paginated): ReservationEntity[] {
    return ReservationEntity.getByBlockType({ blockType: 'daily', ...params })
  }

  static getHourly(params: Data.Paginated): ReservationEntity[] {
    return ReservationEntity.getByBlockType({ blockType: 'multi', ...params })
  }

  static meetingRooms(params: ReservationQuery): Promise<SchemaPaginated<ReservationEntity>> {
    return this.paging({ ...params, group: 'meeting_rooms' })
  }

  static getByBlockType(
    params: Data.Paginated & { blockType: ReservationBlockType; group?: string },
  ): ReservationEntity[] {
    const queryParams = new URLSearchParams(params as any)
    queryParams.sort()
    return useResource(
      this.list().extend({
        url: ({ blockType, ...params }) => `${this.urlRoot}?${queryParams.toString()}`,
      }),
      params,
    )
  }

  static getById(params: Data.Identified): ReservationEntity {
    return useResource(this.detail(), params)
  }

  static expressBook<T extends typeof Resource>(this: T) {
    const list = AccountReservation.list()
    const endpoint = ReservationEntity.create().extend({
      url: () => `${ReservationEntity.urlRoot}/book`,
    })
    return this.create().extend({
      fetch: (params: ReservationExpressBook) => {
        return endpoint.fetch.call(endpoint, {}, params)
      },
      update: (newResource: string) => ({
        [list.key({})]: (resourcePks: string[] = []) => [...resourcePks, newResource],
      }),
      schema: AccountReservation,
    })
  }
}

class ReservationCartItem extends AbstractCartItem {
  readonly slot: TimeSlotEntity = new TimeSlotEntity()
  readonly blockType?: ScheduleBlockType
  readonly lines?: string[]

  static get key() {
    return '/api/cart/items/reservations'
  }

  get item(): TimeSlotEntity {
    return this.slot
  }

  get itemId(): Data.ID {
    return this.slot.slotId
  }

  // updateQuantity(quantity: number): UpdateRequest {
  //   return {
  //     ...super.updateQuantity(quantity),
  //     slotId: this.reservation.slotId,
  //   }
  // }
}

export { ReservationCartItem, ReservationEntity }

