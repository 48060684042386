type ErrorDescription = {
  [key in string]: {
    title: string
    message?: string
  }
}

export const ErrorTitles: ErrorDescription = {
  invalid_request: {
    title: 'Invalid Request',
  },
  ticket_max_reached: {
    title: 'Maximum Tickets Purchased',
  },
  not_deleted: {
    title: 'There was an error',
  },
  invalid_coupon: {
    title: 'Invalid Coupon',
  },
  wallet_device_not_supported: {
    title: 'Device Not Supported',
    message: 'This device cannot be used to download a wallet pass.  Supported devices are Android and iOS'
  }
}
