import { useResource } from 'rest-hooks'
import { ApiResource } from './entity'

class AppConfig {
  readonly appId: number = 0
  readonly slug: string = ''
  readonly iconBackgroundColor: string = ''
  readonly icon: string = ''
  readonly splashBackgroundColor: string = ''
  readonly screenshot1IphoneBig: string = ''
  readonly screenshot1IphoneSmall: string = ''
  readonly screenshot1Ipad: string = ''
  readonly screenshot2IphoneBig: string = ''
  readonly screenshot2IphoneSmall: string = ''
  readonly screenshot2Ipad: string = ''
}

type AppScreenshot = {
  path: string
  index: number
}

class AppEntity extends ApiResource {
  static urlRoot = `/admin/api/app`
  readonly id: number = 0
  readonly company_id: number = 0
  readonly name: string = ''
  readonly status: string = ''
  readonly package: string = ''
  readonly version_code: number = 0
  readonly domain_id: number = 0
  readonly credentials_id: number = 0
  readonly config: AppConfig = new AppConfig()

  static get(): AppEntity {
    return useResource(this.detail(), {})
  }

  static captureScreenshot<T extends typeof ApiResource>(this: T) {
    const endpoint = this.create()
    return this.create().extend({
      fetch: (data: AppScreenshot) =>
        endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/screenshot`,
          }),
          {},
          data,
        ),
      schema: AppEntity,
    })
  }
}

export default AppEntity
