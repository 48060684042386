import { Space, SpaceProps as Props } from 'antd'
import { CSSProperties, FC, Fragment, ReactNode, RefObject } from 'react'

type SpaceProps = Props & {
  justify?: '' | 'left' | 'center' | 'right' | 'space-between'
}

const VerticalSpace: FC<SpaceProps> = ({ justify = '', style, children, ...baseProps }) => (
  <Space direction={'vertical'} style={{ width: '100%', justifyContent: justify, ...style }} {...baseProps}>
    {children}
  </Space>
)

const HorizontalSpace: FC<SpaceProps> = ({ justify = '', style, children, ...baseProps }) => {
  return (
    <Space
      direction={'horizontal'}
      wrap={false}
      style={{ width: '100%', justifyContent: justify, ...style }}
      {...baseProps}
    >
      {children}
    </Space>
  )
}

const Split: FC<Props> = ({ style, children, direction = 'horizontal', ...baseProps }) => (
  <Space
    direction={direction}
    wrap={false}
    style={{ width: '100%', justifyContent: 'space-between', ...style }}
    {...baseProps}
  >
    {children}
  </Space>
)

interface FlexProps extends React.HTMLAttributes<HTMLDivElement> {
  direction?: 'vertical' | 'vertical-reverse' | 'horizontal' | 'horizontal-reverse'
  justify?: 'left' | 'center' | 'right' | 'space-between' | 'space-evenly'
  align?: 'center' | 'end' | 'start' | 'baseline'
  wrap?: boolean
  size?: number
  style?: CSSProperties
  className?: string
  split?: string
  children: ReactNode | ReactNode[]
  ref?: RefObject<HTMLDivElement>
}

const Flex: FC<FlexProps> = ({
  direction,
  justify = '',
  align = '',
  size,
  wrap = false,
  className,
  style,
  children,
  split,
  ref,
  ...props
}) => {
  const resolveDirection = (direction?: 'vertical' | 'vertical-reverse' | 'horizontal' | 'horizontal-reverse') => {
    switch (direction) {
      case 'vertical':
        return 'column'
      case 'vertical-reverse':
        return 'column-reverse'
      case 'horizontal':
        return 'row'
      case 'horizontal-reverse':
        return 'row-reverse'
      default:
        return undefined
    }
  }
  return (
    <div
      ref={ref}
      className={className}
      style={{
        display: 'flex',
        flexWrap: wrap ? 'wrap' : 'nowrap',
        justifyContent: justify,
        alignItems: align,
        flexDirection: resolveDirection(direction),
        gap: size,
        ...style,
      }}
      {...props}
    >
      {Array.isArray(children) && split
        ? children.map((child, index) => (
            <Fragment key={index}>
              {child}
              {index < children.length - 1 && <div>{split}</div>}
            </Fragment>
          ))
        : children}
    </div>
  )
}

export { HorizontalSpace, VerticalSpace, Split, Flex }
