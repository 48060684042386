import { useController, useDLE } from 'rest-hooks'
import { useAccount } from '../contexts/Account'
import { useCompanyConfig } from '../contexts/Config'
import { BankAccount } from '../datasource/wallet/bankaccount'
import { CreditCardEntity } from '../datasource/wallet/creditcard'
import { HouseAccount, WalletItem } from '../datasource/wallet/wallet'

export default function useWallet() {
  const { invalidate } = useController()
  const { houseAccountEnabled } = useAccount()
  const company = useCompanyConfig()
  const { data: cards, loading: cardsLoading } = useDLE(CreditCardEntity.list(), {})
  const { data: banks, loading: banksLoading } = useDLE(BankAccount.list(), {})
  const wallet = cardsLoading || banksLoading ? undefined : [...(cards ?? []), ...(banks ?? [])]

  const onUpdate = (updated: WalletItem) => {
    const billing_use = updated.billingUse
    if (billing_use !== '') {
      const prevPrimary = wallet?.find(item => item.billingUse === billing_use && item.id !== updated.id)
      if (prevPrimary) {
        if (prevPrimary.paymentType === 'creditcard') {
          invalidate(CreditCardEntity.detail(), prevPrimary.id)
        } else if (prevPrimary.paymentType === 'ach') {
          invalidate(BankAccount.detail(), prevPrimary.id)
        }
      }
    }
  }

  return {
    loading: cardsLoading || banksLoading,
    wallet: wallet,
    cards: cards && cards.length > 0 ? cards : undefined,
    banks: banks,
    onUpdate,
    primary: wallet?.some(w => w.default),
    houseAccount: houseAccountEnabled ? HouseAccount.fromJS({
      id: 'house-account',
      status: 'active',
      paymentType: 'houseAccount',
      brand: company.name,
      createdOn: new Date()
    }) : undefined,
    defaultWallet: wallet ? (wallet.length > 1 ? wallet.find((w) => w.default) : wallet[0]) : undefined,
  }
}