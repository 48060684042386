import { Col, Row } from 'antd'
import React, { FC, useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'
import { Flex } from 'src/sdk/components/layout/Grid'
import { LogoImage } from 'src/sdk/components/logo/Logo'
import { BreakpointMax, BreakpointMin } from 'src/sdk/components/screen/Breakpoint'
import { usePropsConfig, usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import { IsLightColor } from 'src/sdk/helpers/colors'
import useLoginLogo from 'src/sdk/hooks/useLoginLogo'
import useLogoType from 'src/sdk/hooks/useLogoType'
import Logo from '../../sdk/components/logo'
import { Page } from '../../sdk/components/page/Page'
import './index.less'
import SloganCarousel from './SloganCarousel'

export interface IPublicView extends FC {}

const LogoView: FC = () => {
  const { slogans } = usePropsConfig()
  const { theme } = usePublicConfig()
  const hasImages = slogans?.some(slogan => slogan.image)
  const backgroundColor = theme['@layoutSiderBackground'] as string
  const logoType = useLogoType()
  const loginLogo = useLoginLogo()
  return loginLogo ? (
    <Flex style={{ width: '100%'}} justify={loginLogo.justify}>
      <Link
        className={withPrefix('portal-navigation-header-link')}
        to={'/'}
        style={{ overflow: 'hidden', display: 'flex', justifyContent: loginLogo.justify ?? ''}}
      >
        {loginLogo.src ? (
          <LogoImage src={loginLogo.src} style={{ maxWidth: loginLogo.width}} />
        ) : (
          <Logo dark={hasImages ? false : IsLightColor(backgroundColor)} type={logoType?.logo?.expanded ?? 'icon+text'} />
        )}
        
      </Link>
    </Flex>
  ) : (
    <Link
        className={withPrefix('portal-navigation-header-link')}
        to={'/'}
        style={{ overflow: 'hidden', display: 'flex', justifyContent: logoType.justify}}
      >
        <Logo dark={hasImages ? false : IsLightColor(backgroundColor)} type={logoType?.logo?.expanded ?? 'icon+text'} />
      </Link>
  )
}

const LeftView: FC = () => {
  const { slogans } = usePropsConfig()
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.MD })
  const hasImages = slogans?.some(slogan => slogan.image)
  const Carousel = useMemo(() => SloganCarousel, [slogans])
  return (
      <Col span={24} md={12} className={withPrefix('public-left-view-container')}>
        <Page className={withPrefix('split-view', hasImages ? 'with-carousel' : '')} theme={hasImages && isDesktop ? 'dark' : 'light'}>
            <Row wrap className={'public-left-view-row'} justify={'center'} align='top'>
              {isDesktop && <Carousel/>}
              <Col span={24}>
                <LogoView />
              </Col>
            </Row>
        </Page>
      </Col>
   )
  }

const LeftMemo = React.memo(LeftView)

const PublicView: FC = ({ children }) => {
  const { slogans } = usePropsConfig()
  const isMobile = useMediaQuery({ maxWidth: BreakpointMax.SM })
  
  const backgroundImage = useMemo(() => {
    const mobileSlogan = slogans?.filter(s => s.mobile)
    const slogan = mobileSlogan && mobileSlogan?.length > 0 ? mobileSlogan[Math.floor(Math.random() * mobileSlogan.length)] : slogans[Math.floor(Math.random() * slogans?.length)]
      return isMobile && slogan && slogan.image ? `url('${slogan.image}')` : undefined
    },[isMobile])
  return (
      <Row className={withPrefix('public-view-container')} style={{ flexDirection: (isMobile ? 'column' : 'row'), backgroundImage: backgroundImage }}>
        <LeftMemo />
        <Col span={24} md={12} className={withPrefix('public-right-view-container')}>
          <div className={withPrefix('public-right-view-content')}>
            {children}
          </div>
        </Col>
      </Row>
  )
}

export default PublicView