import { Entity, Resource } from '@rest-hooks/rest'
import { Col, List, Row, Space, Table, Typography } from 'antd'
import { Gutter } from 'antd/lib/grid/row'
import { ListProps } from 'antd/lib/list'
import Pagination, { PaginationConfig } from 'antd/lib/pagination'
import { ColumnsType } from 'antd/lib/table'
import { BaseType } from 'antd/lib/typography/Base'
import classNames from 'classnames'
import React, { FC, MouseEventHandler, ReactElement, ReactNode, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMediaQuery } from 'react-responsive'
import { FilterActionButton } from 'src/sdk/components/filter/FilterForm'
import { withPrefix } from 'src/sdk/contexts/Config'
import { useFilter } from 'src/sdk/contexts/Filter'
import { SchemaPaginated } from 'src/sdk/datasource/entity'
import { VerticalSpace } from '../layout'
import { Breakpoint } from '../screen/Breakpoint'
import { ScrollToTop } from '../scroll/ScrollToTopController'
import Search from '../search'
import { SearchProps } from '../search/Search'
import { SkeletonCard, SkeletonListMeta } from '../skeleton/Skeleton'
import './index.less'

type RenderItemProps<T> = { item: T; index: number }

function withDataSource<T = Data.Identified>(Wrapped: FC<Data.Source<T[]>>, source: T[]): FC {
  return (props) => <Wrapped {...props} data={source} />
}

type ListActionsProps = Data.Source<Data.Searchable[]> & {
  onFilterClick: MouseEventHandler
  filterCount?: number
  searchBaseUrl: string
}

function ListActions<T extends Resource>({
  onFilterClick,
  filterCount,
  ...props
}: Partial<ListActionsProps> & SearchProps<T>) {
  const style = useMediaQuery({ maxWidth: Breakpoint.LG }) ? { width: '100%' } : {}
  return (
    <Space direction={'horizontal'} size={'middle'} style={style}>
      <Search {...props} />
      {onFilterClick && <FilterActionButton filterCount={filterCount} onClick={onFilterClick} />}
    </Space>
  )
}

function withList<T>(Wrapped: FC<ListProps<T>>): FC<ListProps<T>> {
  return ({ ...baseProps }) => (
    <Wrapped
      grid={{ gutter: baseProps.grid?.gutter ?? 4 }}
      bordered={false}
      split={false}
      itemLayout={'horizontal'}
      {...baseProps}
    />
  )
}

function withVerticalList<T>(Wrapped: FC<ListProps<T>>): FC<ListProps<T>> {
  return ({ ...baseProps }) => <Wrapped bordered={false} split={false} itemLayout={'vertical'} {...baseProps} />
}

export type PaginatedListProps<T> = {
  defaultPage?: number
  pageSize: number
  renderItem: (item: T, index: number) => React.ReactNode
} & ListProps<T>

function VerticalPaginatedList<T>({
  defaultPage,
  pageSize,
  dataSource,
  renderItem,
  ...baseProps
}: PaginatedListProps<T>) {
  return (
    <List
      bordered={false}
      split={false}
      renderItem={(item, index) => {
        return renderItem(item, index)
      }}
      dataSource={dataSource}
      {...baseProps}
    />
  )
}

type PaginatorSkeletonProps = {
  skeleton?: ReactNode
  count?: number
}

export type PaginatorProps<T extends Resource | Entity> = {
  type?: 'grid' | 'list' | 'table'
  className?: string
  scrollToTop?: boolean
  dataSource?: T[]
  columns?: ColumnsType<T>
  onRowClick?: (item: T) => void
  renderItem?: (item: T, index: number) => React.ReactNode
  prepend?: () => React.ReactNode
  children?: ReactNode
  header?: ReactNode
  onEmpty?: ReactNode | (() => void)
  infinite?: boolean
  grid?: {
    gutter?: Gutter | [Gutter, Gutter]
    column?: number
    xs?: number
    sm?: number
    md?: number
    lg?: number
    xl?: number
    xxl?: number
  }
  pagination?: {
    current?: number
    default?: number
    pageSize?: number
    total?: number
    hideOnSinglePage?: boolean
    onChange?: (page: number) => void
    hide?: boolean
  }
  fetcher?: {
    resource: (params: Partial<Data.Paginated> & any) => Promise<SchemaPaginated<T>>
    onFetch?: (data: SchemaPaginated<T>) => void
    onError?: (error: any) => void
    filters?: Record<string, any>
  }
  skeleton?: PaginatorSkeletonProps
}

type PageTracker<T> = {
  pageNumber: number
  filters?: Record<string, any>
  results: T[]
}

function Paginator<T extends Resource>({
  type = 'grid',
  scrollToTop = false,
  columns,
  onRowClick,
  dataSource,
  onEmpty,
  header,
  pagination = {
    current: 1,
    default: 1,
    hide: false,
  },
  infinite = true,
  renderItem,
  prepend,
  className = '',
  fetcher,
  skeleton,
  children,
  ...props
}: PaginatorProps<T>) {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const isMobile = useMediaQuery({ maxWidth: Breakpoint.MD })
  const [loading, setLoading] = useState(true)
  const [isEmpty, setIsEmpty] = useState(false)
  const [dataList, setDataList] = useState<T[]>()
  const [pageTracker, setPageTracker] = useState<PageTracker<T>[]>([])
  const [paginationProps, setPaginationProps] = useState({
    current: pagination?.current ?? 1,
    default: pagination?.default ?? 1,
    pageSize: pagination?.pageSize ?? 6,
    total: pagination?.total ?? 0,
    hideOnSinglePage: pagination?.hideOnSinglePage ?? true,
  })
  const { didSearch } = useFilter()

  const grid = {
    ...{
      gutter: [12, 12] as [Gutter, Gutter],
      xs: 1,
      sm: 1,
      md: 2,
      lg: 3,
      xl: 3,
      xxl: 3,
    },
    ...props.grid,
  }

  const resetView = () => {
    if (!scrollToTop || paginationProps.total <= paginationProps.pageSize) return
    setTimeout(() => {
      if (wrapperRef.current) {
        const elemTop = wrapperRef.current.getBoundingClientRect().top + window.scrollY
        window.scrollTo({ top: elemTop, behavior: 'smooth' })
      } else {
        ScrollToTop()
      }
    })
  }

  // On Init
  useEffect(() => {
    if (dataSource) {
      handlePageChange(pagination.current ?? 1)
    } else if (fetcher && !didSearch) {
      fetchResource(paginationProps.current)
    }
  }, [])

  const fetchResource = (page: number) => {
    setLoading(true)
    // Search for any previously fetched paginated items
    const trackerMatch = pageTracker.find((t) => t.pageNumber == page && t.filters == fetcher?.filters)

    if (trackerMatch) {
      setDataList(trackerMatch.results)
      setPaginationProps((prevState) => ({
        ...prevState,
        current: page,
      }))
      setLoading(false)
    } else {
      fetcher
        ?.resource({
          pageNumber: page,
          pageSize: paginationProps.pageSize,
          ...fetcher.filters,
        })
        .then((result) => {
          setIsEmpty(result.results.length === 0)
          setPageTracker((prevState) => [
            ...prevState,
            {
              pageNumber: result.pagination.pageNumber,
              results: result.results,
              filters: fetcher.filters,
            },
          ])

          fetcher.onFetch && fetcher.onFetch(result)
          setPaginationProps((prevState) => ({
            ...prevState,
            total: result.pagination.totalCount,
            pageSize: result.pagination.pageSize,
            current: result.pagination.pageNumber,
          }))

          if (infinite) {
            if (page === 1) {
              setDataList(result.results)
            } else {
              setDataList([...(dataList || []), ...result.results])
            }
          } else {
            setDataList(result.results)
          }
        })
        .catch((error) => {
          fetcher.onError && fetcher.onError(error)
        })
    }
  }

  const handlePageChange = (page: number) => {
    if (dataSource) {
      setIsEmpty(dataSource.length === 0)
      const start = (page - 1) * paginationProps.pageSize
      const end = start + paginationProps.pageSize
      const newData = dataSource.slice(start, end)
      setDataList(infinite ? [...(dataList || []), ...newData] : newData)
      setPaginationProps((prevState) => ({
        ...prevState,
        current: page,
        total: pagination.total ?? dataSource.length,
      }))
    } else if (fetcher) {
      fetchResource(page)
    }
  }

  useEffect(() => {
    if (!dataSource) return
    if (!dataList || dataList.length === 0) {
      handlePageChange(1)
    }
    setLoading(false)
  }, [dataSource])

  useEffect(() => {
    setLoading(true)
    if (fetcher?.filters) {
      const filterValues = Object.values(fetcher?.filters)
      if (!filterValues.some((v) => v !== undefined || v !== null || v.length > 0)) return
      setDataList(undefined)
      handlePageChange(1)
    }
  }, [fetcher?.filters])

  useEffect(() => {
    pagination.current && handlePageChange(pagination.current)
  }, [pagination.current])

  useEffect(() => {
    pagination && pagination.onChange && pagination.onChange(paginationProps.current)
  }, [paginationProps])

  useEffect(() => {
    if (!loading) {
      resetView()
    }
  }, [loading])

  const Loader: FC = () => {
    const skeletonCount = skeleton && skeleton.count ? skeleton.count : paginationProps.pageSize

    return type === 'grid' ? (
      <Row className={withPrefix('grid-paginated', className)} gutter={grid.gutter} style={{ marginTop: 15 }}>
        {new Array(isMobile ? 1 : skeletonCount).fill(0).map((_, i) => (
          <Col
            xs={24 / grid.xs}
            sm={24 / grid.sm}
            md={24 / grid.md}
            lg={24 / grid.lg}
            xl={24 / grid.xl}
            xxl={24 / grid.xxl}
            key={i}
          >
            {skeleton && skeleton.skeleton ? skeleton.skeleton : <SkeletonCard />}
          </Col>
        ))}
      </Row>
    ) : (
      <List itemLayout={'vertical'}>
        {new Array(isMobile ? 1 : skeletonCount).fill(0).map((_, i) => (
          <List.Item key={i}>{skeleton && skeleton.skeleton ? skeleton.skeleton : <SkeletonListMeta />}</List.Item>
        ))}
      </List>
    )
  }

  const PaginatedGrid: FC<Data.Source<T[]>> = ({ data }) => (
    <Row className={withPrefix('grid-paginated', className)} gutter={grid.gutter}>
      {header && header}
      {data.map((item, index) => (
        <Col
          xs={24 / grid.xs}
          sm={24 / grid.sm}
          md={24 / grid.md}
          lg={24 / grid.lg}
          xl={24 / grid.xl}
          xxl={24 / grid.xxl}
          key={index}
        >
          {renderItem && renderItem(item, (paginationProps.current - 1) * paginationProps.pageSize + index)}
        </Col>
      ))}
    </Row>
  )

  const PaginatedTable: FC<Data.Source<T[]>> = ({ data }) => (
    <Table
      className={onRowClick ? withPrefix('table-clickable') : undefined}
      scroll={{
        x: true,
      }}
      onRow={(record, index) => {
        return {
          onClick: (event) => onRowClick && onRowClick(record),
        }
      }}
      rowKey={'id'}
      columns={columns!}
      dataSource={data}
      pagination={false}
    />
  )

  const PaginatedList: FC<Data.Source<T[]>> = ({ data }) => (
    <>
      {header && header}
      <List
        bordered={false}
        split={false}
        itemLayout={'vertical'}
        dataSource={data}
        renderItem={(item, index) =>
          renderItem!(item, (paginationProps.current - 1) * paginationProps.pageSize + index)
        }
      />
    </>
  )

  return (
    <div ref={wrapperRef}>
      {isEmpty ? (
        <VerticalSpace>
          {header}
          {onEmpty}
        </VerticalSpace>
      ) : dataList ? (
        <VerticalSpace>
          {infinite ? (
            <InfiniteScroll
              scrollThreshold={0.25}
              key={'infinite-scroll'}
              dataLength={dataList.length}
              next={() => {
                handlePageChange(paginationProps.current + 1)
              }}
              hasMore={paginationProps.current * paginationProps.pageSize < paginationProps.total}
              loader={<Loader />}
            >
              {(() => {
                switch (type) {
                  case 'grid':
                    return <PaginatedGrid data={dataList} />
                  case 'list':
                    return <PaginatedList data={dataList} />
                  case 'table':
                    return <PaginatedTable data={dataList} />
                }
              })()}
            </InfiniteScroll>
          ) : (
            <>
              {(() => {
                switch (type) {
                  case 'grid':
                    return <PaginatedGrid data={dataList} />
                  case 'list':
                    return <PaginatedList data={dataList} />
                  case 'table':
                    return <PaginatedTable data={dataList} />
                }
              })()}
              {!pagination?.hide && (
                <Pagination
                  {...paginationProps}
                  style={{ marginTop: 15 }}
                  onChange={handlePageChange}
                  defaultCurrent={paginationProps.default}
                />
              )}
            </>
          )}
        </VerticalSpace>
      ) : (
        <Loader />
      )}
      {children}
    </div>
  )
}
function withListItem<T extends Data.Identified>(
  Wrapped: FC<Data.Source<T>>,
): (item: T) => ReactElement<RenderItemProps<T>> {
  return (item) => (
    <List.Item style={{ paddingLeft: 0 }} key={item.id}>
      <Wrapped data={item as T} />
    </List.Item>
  )
}

/* function getMaxItemNumberByBreakpoint(items: Record<string, number>): number {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const screens = Grid.useBreakpoint()
  const screenBreakpoint = Object.entries(screens).reduce(
    (previous, next) => (previous[1] ? previous : next),
    ['xs', true],
  )[0]
  return items[screenBreakpoint] ? (items[screenBreakpoint] % 2 === 0 ? 3 : 4) : 1
} */

function withSingleRowGrid<T extends Data.Identified>(Wrapped: FC<ListProps<T>>): FC<ListProps<T>> {
  return ({ className, children, grid, ...baseProps }: ListProps<T>) => {
    const itemsPerRow = {
      xs: 1,
      sm: 1,
      md: 1,
      lg: 1,
      xl: 1,
      xxl: 1,
    }

    return (
      <Wrapped
        className={`${className} `.trim()}
        grid={{
          gutter: 16,
          ...itemsPerRow,
          ...grid,
        }}
        size={'large'}
        itemLayout={'horizontal'}
        dataSource={baseProps.dataSource}
        pagination={{
          defaultPageSize: 9,
          defaultCurrent: 1,
          responsive: false,
          size: 'default',
          showSizeChanger: false,
          ...baseProps.pagination,
        }}
      >
        {children}
      </Wrapped>
    )
  }
}

function withGrid<T extends Data.Identified>(Wrapped: FC<ListProps<T>>): FC<ListProps<T>> {
  return ({ className, children, ...baseProps }: ListProps<T>) => {
    return (
      <Wrapped
        bordered={false}
        split={false}
        className={classNames(className, 'card-grid')}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 2,
          xl: 3,
          xxl: 3,
        }}
        itemLayout={'horizontal'}
        pagination={{
          defaultPageSize: 9,
          defaultCurrent: (baseProps.pagination as PaginationConfig)?.defaultCurrent
            ? (baseProps.pagination as PaginationConfig).defaultCurrent
            : 1,
          responsive: false,
          size: 'default',
          showSizeChanger: false,
          style: { display: `${baseProps.dataSource && (baseProps.dataSource.length > 9 ? 'block' : 'none')}` },
        }}
        {...baseProps}
      >
        {children}
      </Wrapped>
    )
  }
}

export type GridListProps<T> = {
  data?: T[]
  className?: string
  keyProp?: string
  grid: {
    xs: number
    sm: number
    md: number
    lg: number
    xl: number
    xxl: number
    gutter: number | [number, number]
  }
  renderItem: (data: T) => JSX.Element
}

function GridList<T>({ data, className, keyProp, grid, renderItem }: GridListProps<T>): JSX.Element {
  const children: JSX.Element[] = []

  data?.forEach((element, index) => {
    children.push(
      <Col
        key={keyProp ? element[keyProp] : index}
        xs={24 / grid.xs}
        sm={24 / grid.sm}
        md={24 / grid.md}
        lg={24 / grid.lg}
        xl={24 / grid.xl}
        xxl={24 / grid.xxl}
        style={{ margin: '0 0 12px' }}
      >
        {renderItem(element)}
      </Col>,
    )
  })

  return (
    <Row gutter={grid.gutter} className={className}>
      {children}
    </Row>
  )
}
type ListTitleValueProps = {
  title: string
  value: string | number
  titleStyle?: React.CSSProperties
  valueStyle?: React.CSSProperties
  titleType?: BaseType
  valueType?: BaseType
}

const ListTitleValue: FC<Partial<ListTitleValueProps>> = (data) => {
  return (
    <List.Item>
      <Typography.Paragraph style={{ marginBottom: 0 }}>
        <Typography.Text strong style={data.titleStyle}>{`${data?.title}: `}</Typography.Text>
        <Typography.Text type={data.valueType} style={data.valueStyle}>
          {data?.value}
        </Typography.Text>
      </Typography.Paragraph>
    </List.Item>
  )
}

export {
  withGrid,
  GridList,
  withList,
  withListItem,
  withSingleRowGrid,
  withDataSource,
  withVerticalList,
  VerticalPaginatedList,
  Paginator,
  ListActions,
  ListTitleValue,
}
