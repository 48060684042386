import React from 'react'
import { ApiError } from '../api'
import { Result } from '../components/result/Result'

function isApiError(error: ApiError | unknown): error is ApiError {
  return Object.prototype.hasOwnProperty.call(error, 'name')
}

interface Props {
  children: React.ReactNode
  pathname: any
}

interface State<E extends ApiError> {
  error?: E
  pathname: any
}

export class ApiErrorBoundary<E extends ApiError> extends React.Component<Props, State<E>> {
  state: State<E> = {
    pathname: '',
  }

  static getDerivedStateFromError(error: ApiError | any) {
    if (isApiError(error)) {
      return { error }
    } else {
      throw error
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pathname !== this.props.pathname) {
      this.setState({ error: undefined })
    }
  }

  render(): JSX.Element {
    if (!this.state.error) {
      return this.props.children as any
    }
    switch (this.state.error.code) {
      case 403:
        return <Result.Forbidden />
      case 404:
      case 406:
        return <Result.NotFound />
      default:
        return <Result.Error />
    }
  }
}
