import { createContext, PropsWithChildren, ReactElement, ReactNode, useContext, useEffect, useState } from 'react'
import { useGlobalNetwork } from 'src/components/global-network/GlobalNetwork'
import PayWall from 'src/components/paywall/PayWall'
import { CreatePassword } from 'src/components/user/password/create/CreatePassword'
import { AccountEntity, AccountFlag } from '../datasource/account/account'
import { GlobalNetworkCompany } from '../datasource/globalNetwork'
import { useDynamicDrawer } from './DynamicDrawer'
import { usePrivateConfig } from './Config'
import { useNativeApp } from './NativeApp'

type AccountContext = {
  customer: AccountEntity
  isActiveMember: boolean
  houseAccountEnabled: boolean
  globalNetworks?: GlobalNetworkCompany[]
}

const defaultValue: AccountContext = {
  customer: new AccountEntity(),
  isActiveMember: false,
  houseAccountEnabled: false,
  globalNetworks: [],
}

const Account = createContext<AccountContext>(defaultValue)

function AccountProvider(props: PropsWithChildren<ReactNode>): ReactElement {
  const { company } = usePrivateConfig()
  const { isNativeApp } = useNativeApp()
  const { setGlobalNetworks, globalNetworks } = useGlobalNetwork()
  const { setModal, setModalVisible } = useDynamicDrawer()
  const customer = AccountEntity.get()
  const [flag, setFlag] = useState<AccountFlag>()
  const { globalNetworkEnabled, currentNetwork, changeNetwork } = useGlobalNetwork()

  useEffect(() => {
    if (!customer) return

    switch (customer.flag) {
      case 'changepw':
      case 'createpw':
        setModal({
          centered: true,
          closable: false,
          maskClosable: false,
          footer: null,
          blurMask: true,
          content: (
            <CreatePassword
              action={customer.flag === 'createpw' ? 'create' : 'change'}
              onSuccess={(response) => {
                setModalVisible(false)
              }}
            />
          ),
        })
        break
    }
    setGlobalNetworks(customer.globalNetworks)
    setFlag(customer.flag)
  }, [customer])

  useEffect(() => {
    if (isNativeApp || !globalNetworkEnabled || !customer || !company) return

    if (globalNetworks && !globalNetworks.find((network) => network.id === company.id)) {
      changeNetwork(customer.companyId)
    }
  }, [company, customer, globalNetworks])

  return (
    <Account.Provider
      value={{
        customer,
        isActiveMember: customer?.isMember ?? false,
        houseAccountEnabled: customer?.houseAccountEnabled ?? false,
        globalNetworks: customer?.globalNetworks,
      }}
    >
      {customer.flag && customer.flag === 'membership_failed_payment' ? <PayWall /> : props.children}
    </Account.Provider>
  )
}

const useAccount: () => AccountContext = () => useContext(Account)

export { AccountProvider, useAccount }
