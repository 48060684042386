import { createSlice } from '@reduxjs/toolkit'
import rootReducer, { RootState } from './rootReducer'

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    skipForm: false,
    step: 0,
    paying: false
  },
  reducers: {
    setSkipForm(state, action: { payload: boolean }) {
      state.skipForm = action.payload
    },
    setStep(state, action: { payload: number }) {
      state.step = action.payload
    },
    setPaying(state, action: { payload: boolean }) {
      state.paying = action.payload
    }
  },
})

export const { setSkipForm, setStep, setPaying } = checkoutSlice.actions
export const skipFormSelector = (state: RootState) => state.checkout.skipForm
export const stepSelector = (state: RootState) => state.checkout.step
export const payingSelector = (state: RootState) => state.checkout.paying
export default checkoutSlice.reducer
