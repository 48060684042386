import { FC } from 'react'
import { atcb_action } from 'add-to-calendar-button'
import { Button } from '../form'
import { ButtonIcon, ButtonProps } from '../form/Button'
import { withPrefix } from 'src/sdk/contexts/Config'
import { DateTz } from 'src/sdk/api'
import { isIOS, isSafari, isMobileSafari } from 'react-device-detect'
import { generateICS } from './GenerateICS'
import IvyIcon from '../icon'

import './AddToCalendar.less'

export type AddToCalendarProps = {
  buttonType?: 'icon' | 'button'
  buttonText?: string
  title: string
  description: string
  location?: string
  startDate: DateTz
  endDate: DateTz
  fileName?: string
  hideIcon?: boolean
} & ButtonProps

const AddToCalendar: FC<AddToCalendarProps> = ({
  buttonType = 'button',
  buttonText,
  title,
  description,
  location,
  startDate,
  endDate,
  fileName,
  hideIcon,
  ...props
}) => {
  const start = DateTz.fromJS(startDate)
  const end = DateTz.fromJS(endDate)
  const file_name = fileName ? fileName : title.replaceAll(' ', '-')

  const handleClick = () => {
    // Check if running in webview
    if (isIOS && !isSafari && !isMobileSafari) {
      const ics = generateICS({
        title,
        description,
        location,
        startDate,
        endDate,
      })
      try {
        const link = document.createElement('a')
        link.href = ics
        link.target = '_system'
        link.download = file_name
        const evt = new MouseEvent('click', {
          view: window,
          button: 0,
          bubbles: true,
          cancelable: false,
        })
        link.dispatchEvent(evt)
      } catch (e) {
        console.error(e)
      }
    } else {
      atcb_action({
        name: title,
        description: description,
        location: location,
        startDate: start.format('yyyy-MM-DD'),
        endDate: end.format('yyyy-MM-DD'),
        startTime: start.format('HH:mm'),
        endTime: end.format('HH:mm'),
        timeZone: start.timezone,
        // timeZoneOffset: start.offset,
        options: [
          'Apple|APPLE' as 'Apple',
          'Google|GMAIL' as 'Google',
          'Outlook.com|OUTLOOK' as 'Outlook.com',
          'Yahoo|YAHOO' as 'Yahoo',
          // 'iCal|ICAL FILE' as 'iCal',
        ],
        iCalFileName: file_name,
        listStyle: 'dropdown',
      })
    }
  }
  return buttonType === 'button' ? (
    <Button
      className={withPrefix('add-to-calendar-button')}
      onClick={handleClick}
      icon={!hideIcon && <IvyIcon type={'custom/calendar'} />}
      {...props}
    >
      {buttonText ? buttonText : 'Add To Calendar'}
    </Button>
  ) : (
    <ButtonIcon size={props.size} icon={'custom/calendar'} onClick={handleClick} />
  )
}

export default AddToCalendar
