import { LinkProps } from 'antd/lib/typography/Link'
import classNames from 'classnames'
import { FC, MouseEventHandler } from 'react'
import { Link } from 'react-router-dom'
import './TextLink.less'

type TextLinkProps = {
  // onClick?: (evt: MouseEventHandler<HTMLAnchorElement> | undefined) => void
  style?: React.CSSProperties
} & Pick<LinkProps, 'target' | 'href' | 'underline' | 'strong' | 'className' | 'onClick'>

const TextLink: FC<TextLinkProps> = ({ href = '', className = '', underline, strong, children, onClick, style }) => {
  className = classNames({
    'ant-link-underline': underline,
    'ant-link-strong': strong,
    'ant-typography-link': true,
  }).concat(className)
  return href?.startsWith('http') || href?.startsWith('mailto') ? (
    <a className={className} target={'_blank'} href={href} rel={'noreferrer'} style={style}>
      {children}
    </a>
  ) : (
    <Link className={className} onClick={onClick} to={href} style={style}>
      {children}
    </Link>
  )
}

export { TextLink }
