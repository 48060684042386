import moment from 'moment'
import { DateRangeEntity, DateTz } from 'src/sdk/api/intl'
import { BusinessLocation } from './business'
import { CategoryEntity } from './category'
import { ApiResource } from './entity'
import { DayOfWeek } from './intl'

export type ScheduleType = 'reservation' | 'appointment'

export type DailySlots = {
  date: moment.Moment
  items: TimeSlotEntity[]
}

export type TimeSlotParams = {
  startDate?: string | Date
  endDate?: string | Date
  partySize?: number
}

export type TimeSlotCharges = {
  reservation: number
  service?: number
}

class TimeSlotEntity extends ApiResource implements Data.TemporalLocal {
  static urlRoot = '/api/'
  readonly title: string = ''
  readonly description: string = ''
  readonly scheduleId: number = 0
  readonly appointmentId: number = 0
  readonly fee: number = 0
  readonly feeType?: 'Reservation' | 'Person'
  readonly charges?: TimeSlotCharges
  readonly reservationId: number = 0
  readonly slotId: string = ''
  readonly subject: string = ''
  readonly startDate: Date = new Date()
  readonly endDate: Date = new Date()
  readonly startDateLocal: DateTz = DateTz.fromJS({})
  readonly endDateLocal: DateTz = DateTz.fromJS({})
  readonly capacity: number = 0
  readonly quantity: number = 0
  readonly duration: number = 0
  readonly location?: BusinessLocation
  readonly id: string = ''
  readonly metadata?: any

  static schema = {
    startDateLocal: DateTz,
    endDateLocal: DateTz,
    location: BusinessLocation,
  }

  get range(): DateRangeEntity {
    return DateRangeEntity.fromJS({ end: this.endDateLocal.date, start: this.startDateLocal.date })
  }
}

class SchedulerRules extends ApiResource implements Data.Identified {
  readonly id: Data.ID = 0
  readonly day?: DayOfWeek
  readonly start: string = ''
  readonly end: string = ''
}

export type ScheduleBlockType = 'one' | 'multi' | 'daily' | 'dining' | 'spa' | ''

type SchedulerSettings = {
  defaultStartTime?: string
  minimumCapacity?: number
}

class SchedulerEntity extends ApiResource implements Data.Named, Data.Imaginated, Data.SingleCategory {
  readonly name: string = ''
  readonly description: string = ''
  readonly graphic: string = ''
  readonly allowCancel: boolean = false
  readonly location: string = ''
  readonly blockDuration: number = 0
  readonly blockType: ScheduleBlockType = ''
  readonly businessId: number = 0
  readonly businessLocationId: number = 0
  readonly cancelBy: number = 0
  readonly categoryBlock: boolean = false
  readonly categoryId: number = 0
  readonly maxBlocks: number = 0
  readonly maxDaysOut: number = 0
  readonly capacity: number = 0
  readonly type: ScheduleType = 'appointment'
  readonly surveyId: number = 0
  readonly startingPrice: number = 0
  readonly category: CategoryEntity = CategoryEntity.fromJS()
  readonly rules: SchedulerRules[] = []
  readonly settings?: SchedulerSettings

  get image(): string {
    return this.graphic
  }

  get images(): string[] {
    return [this.image]
  }

  get categories(): CategoryEntity[] {
    return [this.category]
  }

  static findSlots(id?: Data.ID, params: TimeSlotParams = {}): Promise<TimeSlotEntity[]> {
    return this.query(`${this.urlRoot}/${id}/slots`, params).then<TimeSlotEntity[]>()
  }
}

export { SchedulerEntity, TimeSlotEntity, DateRangeEntity }
