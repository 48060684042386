import { Row, Col, Typography, Image, Card, List } from 'antd'
import { FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { VerticalSpace } from '../layout'
import { Skeleton } from '../skeleton/Skeleton'

const DrawerDetailLoader: FC = () => {
  return (
    <Row gutter={[32, 32]}>
      <Col span={24}>
        <Skeleton width={200} height={34} />
        <Skeleton width={200} height={22} />
      </Col>
      <Col span={24} md={16} className={withPrefix('tickets-container')} style={{ marginBottom: 20 }}>
        <VerticalSpace size={80} align={'center'} justify={'center'} className={withPrefix('ticket-details')}>
          <VerticalSpace align={'start'} size={32} className={withPrefix('ticket-details-header')}>
            <div className={withPrefix('ticket-details-qr')}>
              <Skeleton height={225} width={225} />
            </div>
            <VerticalSpace align={'center'} size={24}>
              <VerticalSpace size={0}>
                <Skeleton width={91} height={22} />
                <Skeleton width={91} height={22} />
              </VerticalSpace>
              <Skeleton width={73} height={24} />
            </VerticalSpace>
          </VerticalSpace>
          <Card className={withPrefix('ticket-details-card')}>
            <VerticalSpace>
              <List
                bordered={false}
                split={false}
                itemLayout={'vertical'}
                className={withPrefix('ticket-detail-list')}
                dataSource={[
                  {
                    title: <Skeleton width={200} height={22} />,
                    description: '',
                  },
                  {
                    title: <Skeleton width={200} height={22} />,
                    description: '',
                  },
                  {
                    title: <Skeleton width={200} height={22} />,
                    description: '',
                  },
                  {
                    title: (
                      <VerticalSpace size={0}>
                        <Skeleton width={100} height={22} />
                        <Skeleton width={200} height={22} />
                      </VerticalSpace>
                    ),
                    description: '',
                  },
                  {
                    title: <Skeleton width={200} height={22} />,
                    description: '',
                  },
                  {
                    title: <Skeleton width={200} height={22} />,
                    description: '',
                  },
                ]}
                renderItem={(props, index) => <List.Item.Meta key={`skeleton-list-item-${index}`} {...props} />}
              />
            </VerticalSpace>
          </Card>
        </VerticalSpace>
      </Col>
      <Col span={0} md={8}>
        <Card>
          <List
            bordered={false}
            split={false}
            itemLayout={'vertical'}
            className={withPrefix('ticket-detail-list')}
            dataSource={[
              {
                title: <Skeleton width={200} height={22} />,
                description: '',
              },
              {
                title: <Skeleton width={200} height={22} />,
                description: '',
              },
              {
                title: <Skeleton width={200} height={22} />,
                description: '',
              },
            ]}
            renderItem={(props) => <List.Item.Meta {...props} />}
          />
        </Card>
      </Col>
    </Row>
  )
}

export default DrawerDetailLoader
