import { Button, Input, List, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useController, useDLE } from 'rest-hooks'

import { usePublicConfig, withPrefix } from 'src/sdk/contexts/Config'
import { AdminEntity } from 'src/sdk/datasource/account/account'
import { CompanyEntity } from 'src/sdk/datasource/company'
import DomainEntity from 'src/sdk/datasource/domain'
import { useDebug } from './DebugProvider'

import { VerticalSpace } from '../layout'
import { Section } from '../layout/Section'
import { Tag } from '../tag'
import { useDebugAuth } from './DebugAuth'

const DebugCompany: FC = () => {
  const config = usePublicConfig()
  const { companyId, setCompanyId } = useDebug()
  const { authType, switchCompany } = useDebugAuth()
  const { fetch, invalidate } = useController()
  const [filtered, setFiltered] = useState<string>()
  const [companySearch, setCompanySearch] = useState<string>()
  const { data: domains, loading: domainsLoading } = useDLE(DomainEntity.list(), companyId > 0 ? {} : null)
  const { data: companies, loading: companiesLoading } = useDLE(
    CompanyEntity.list(),
    companySearch ? { search: companySearch } : {},
  )
  const [loadingCompanyId, setLoadingCompanyId] = useState<number>()
  const [loadingDomainId, setLoadingDomainId] = useState<number>()

  useEffect(() => {
    if (!domains) return
    if (domains[0]?.companyId && domains[0].companyId !== companyId) {
      setCompanyId(domains[0]!.companyId)
    }
  }, [domains])

  return (
    <VerticalSpace>
      <Section title={'Portals'} titleSize={5}></Section>
      {domains && (
        <List
          itemLayout='vertical'
          dataSource={domains.filter((d) => !!d.config || !!d.portalConfig)}
          renderItem={(d: DomainEntity) => (
            <List.Item>
              <Button
                type='ghost'
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  width: '100%',
                  textTransform: 'none',
                }}
                loading={loadingDomainId === d.id}
                onClick={() => {
                  if (d.name !== config.domainName) {
                    setLoadingDomainId(d.id)
                    fetch(AdminEntity.switchDomain(), { domainName: d.name }).finally(() => {
                      setLoadingDomainId(undefined)
                      window.location.reload()
                    })
                  }
                }}
              >
                {d.name}
                <Tag size='small' type='info' style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                  {d.environment}
                </Tag>
                {d.name === config.domainName && (
                  <Tag size='small' type='primary' style={{ display: 'flex', alignItems: 'center', marginLeft: 0 }}>
                    Active
                  </Tag>
                )}
              </Button>
            </List.Item>
          )}
          pagination={{
            pageSize: 6,
            hideOnSinglePage: true,
          }}
        />
      )}

      <Section title={'Change Company'} titleSize={5} />
      <Input.Search
        // onChange={(e) => setCompanySearch(e.target.value)}
        onSearch={setCompanySearch}
        enterButton={'Search'}
        className={withPrefix('debug-color-actions-search')}
        allowClear
        size={'small'}
        style={{ marginBottom: 15 }}
      />
      <List
        loading={companiesLoading}
        dataSource={companies}
        renderItem={(c: CompanyEntity) => (
          <List.Item key={c.id} style={{ flexDirection: 'column' }}>
            <Button
              type='ghost'
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                textTransform: 'none',
              }}
              loading={loadingCompanyId === c.id}
              onClick={() => {
                if (c.id !== companyId) {
                  setLoadingCompanyId(c.id)
                  switchCompany(c.id)
                    .then(() => setCompanyId(c.id))
                    .finally(() => {
                      setLoadingCompanyId(undefined)
                      invalidate(DomainEntity.list(), {})
                    })
                }
              }}
            >
              <div className='debug-company-icon'>{c.name.slice(0, 2)}</div>
              <Typography.Text>{c.name}</Typography.Text>
              {c.id === companyId && (
                <Tag size='small' type='primary' style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                  Active
                </Tag>
              )}
            </Button>
          </List.Item>
        )}
      />
      {/* 
      <List>
        {companiesLoading ? (
          <SectionLoader />
        ) : companies ? (
          companies
            .filter((c) => (filtered && filtered !== '' ? c.name.toLowerCase().includes(filtered.toLowerCase()) : true))
            .sort((a) => (a.id === companyId ? -1 : 0))
            .map((c) => (
              <List.Item key={c.id} style={{ flexDirection: 'column' }}>
                <Button
                  type='ghost'
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    width: '100%',
                    textTransform: 'none',
                  }}
                  loading={loadingCompanyId === c.id}
                  onClick={() => {
                    if (c.id !== companyId) {
                      setLoadingCompanyId(c.id)
                      switchCompany(c.id)
                        .then(() => setCompanyId(c.id))
                        .finally(() => {
                          setLoadingCompanyId(undefined)
                          invalidate(DomainEntity.list(), {})
                        })
                    }
                  }}
                >
                  <div className='debug-company-icon'>{c.name.slice(0, 2)}</div>
                  <Typography.Text>{c.name}</Typography.Text>
                  {c.id === companyId && (
                    <Tag size='small' type='primary' style={{ display: 'flex', alignItems: 'center', marginLeft: 15 }}>
                      Active
                    </Tag>
                  )}
                </Button>
              </List.Item>
            ))
        ) : null}
      </List> */}
    </VerticalSpace>
  )
}

export default DebugCompany
