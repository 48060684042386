import { BaseType } from 'antd/lib/typography/Base'
import { CSSProperties, FC } from 'react'
import sanitizeHtml from 'sanitize-html'
import { withPrefix } from 'src/sdk/contexts/Config'
import parse from 'html-react-parser'

type MarkupProps = {
  className?: string
  style?: CSSProperties
  html: string
  sanitized: boolean
  type?: BaseType
}

const Markup: FC<Partial<MarkupProps>> = ({ sanitized = true, html = '', className = '', style, type }) => {
  const cn =
    type !== undefined
      ? ''.concat(withPrefix(`typography`), withPrefix(`typography-${type}`)) + ' ' + className
      : className
  return !sanitized ? (
    <span className={cn} style={style} dangerouslySetInnerHTML={{ __html: html }} />
  ) : (
    <span className={cn} style={style}>
      {sanitizeHtml(html, { parser: { decodeEntities: false }, allowedTags: [] })}
    </span>
  )
}

type ParseHtmlProps = {
  html: string
  type?: BaseType
}
const ParseHtml: FC<ParseHtmlProps> = ({ html, type }) => {
  const className = type !== undefined ? ''.concat(withPrefix(`typography`), withPrefix(`typography-${type}`)) : ''
  return <span className={className}>{parse(html)}</span>
}
export { Markup, ParseHtml }
