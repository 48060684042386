import { ToWords } from 'to-words'

export const LeftPadNumber = (value: number | string) => {
  return value > 9 ? value : `0${value}`
}

export const ToNumberWord = (value: number) => {
  const toWords = new ToWords()
  return toWords.convert(value).toLocaleLowerCase()
}

export const StringToDecimal = (value: string, decPlaces: number = 2) => {
  var numString = value
  const lastChar = value[value.length - 1]
  if (lastChar === '.') {
    numString += '00'
  }
  const number = Number(numString)
  return number.toFixed(decPlaces)
}

export const NumberToMoney = (value: number, decPlaces: number = 2) => {
  var money = String(value)
  if (money.indexOf('.') !== -1) {
    money = money.replace('.', '')
  } else {
    money += '.00'
  }
  return StringToDecimal(money, decPlaces)
}

export const FormatCurrency = (value: number) => {
  var money = String(value)
  if (money.indexOf('.') !== -1) {
    const splitMoney = money.split('.')
    if (splitMoney.length === 1 && splitMoney[1].length === 1) {
      money += '0'
    }
  } else {
    money += '.00'
  }
  return StringToDecimal(money, 2)
}
