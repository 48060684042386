import { Drawer, DrawerProps } from 'antd'
import { FC, useEffect } from 'react'
import { useWindowSize } from 'react-use'
import { withPrefix } from 'src/sdk/contexts/Config'
import { SubTitle } from '../text'
import './index.less'

type RightSideDrawerProps = {
  size?: 'small' | 'medium' | 'large'
} & Omit<DrawerProps, 'size'>

const RightSideDrawer: FC<RightSideDrawerProps> = ({
  title,
  size = 'small',
  afterVisibleChange,
  children,
  className,
  destroyOnClose = true,
  maskClosable = false,
  ...props
}) => {
  const windowSize = useWindowSize()
  useEffect(() => {
    if (!props.visible) document.body.classList.remove(withPrefix('scrolling-effect'))
  }, [windowSize])

  return (
    <Drawer
      title={title && <SubTitle level={2}>{title}</SubTitle>}
      contentWrapperStyle={{ scrollbarWidth: 'none' }}
      placement={'right'}
      width={''}
      mask={props.mask ?? false}
      className={withPrefix(`drawer-right-${size}`, className ? className : '')}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
      afterVisibleChange={(visible) => {
        afterVisibleChange && afterVisibleChange(visible)
        if (visible) {
          document.body.classList.add(withPrefix('scrolling-effect'))
        } else {
          document.body.classList.remove(withPrefix('scrolling-effect'))
        }
      }}
      {...props}
    >
      {children}
    </Drawer>
  )
}

export default RightSideDrawer
