export const messages = {
  en: {
    voucher_use: 'You have {number} vouchers ready to use',
    log_in: 'Log In',
    log_out: 'Log Out',
    my_club: 'My Club',
    my_profile: 'My Profile',
    appointments: 'Appointments',
    companies: 'Companies',
    contacts: 'Contacts',
    dining: 'Dining',
    events: 'Events',
    experiences: 'Experiences',
    members: 'Members',
    rooms: 'Rooms',
    submit: 'Submit',
    view: 'View',
  },
  es: {
    voucher_use: 'Tienes {number} vales listos para usar',
    log_in: 'Ingresa',
    log_out: 'Cerrar la sesión',
    my_club: 'Mi Club',
    my_profile: 'Mi Perfil',
    appointments: 'Citas',
    companies: 'Empresas',
    contacts: 'Contactos',
    dining: 'Cena',
    events: 'Eventos',
    experiences: 'Experiencias',
    members: 'Miembros',
    rooms: 'Habitaciones',
    submit: 'Envíe',
    view: 'Vea',
  },
}
