import { Typography } from 'antd'
import { CSSProperties, FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import './ClickableText.less'

interface ClickableTextProps {
  text: string
  onClick: () => void
  style?: CSSProperties
}

const ClickableText: FC<ClickableTextProps> = ({ text, onClick, style }) => {
  return (
    <Typography.Text className={withPrefix('typography-clickable')} onClick={onClick} style={style}>
      {text}
    </Typography.Text>
  )
}

export { ClickableText }
