import { useResource } from 'rest-hooks'
import { ApiResource } from './entity'

export type CompanyVersion = 'enterprise' | 'nextgen' | 'legacy'

export class CompanyEntity extends ApiResource {
  static readonly urlRoot = `/admin/api/company`
  readonly id: number = 0
  readonly name: string = ''
  readonly logoUrl: string = ''
  readonly iconUrl: string = ''
  readonly biography: string = ''
  readonly website: string = ''
  readonly email: string = ''
  readonly phone: string = ''
  readonly address: string = ''
  readonly address2: string = ''
  readonly city: string = ''
  readonly state: string = ''
  readonly zipCode: string = ''
  readonly country: string = ''
  readonly timezone: string = ''
  readonly timezoneOffset: number = 0
  readonly backgroundColor: string = ''
  readonly fullAddress: string = ''
  static get(params: { id: Data.ID }): CompanyEntity {
    return useResource(this.detail(), params)
  }

  static getAll(params = {}): CompanyEntity[] {
    return useResource(this.list(), params)
  }

  get cityStateZip() {
    const items = [this.city, this.state, this.zipCode, this.country]
    return items.filter(Boolean).join(', ')
  }
}
