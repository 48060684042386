import { FC } from 'react'
import { TransactionDetails } from 'src/components/transactions/TransactionDetails'
import { CenteredContent } from 'src/sdk/components/layout'
import { IDrawerDetailView } from '../DrawerDetailProvider'

const TransactionDrawer: FC<IDrawerDetailView> = ({ id }) => (
  <CenteredContent>
    <TransactionDetails id={id} />
  </CenteredContent>
)

export default TransactionDrawer
