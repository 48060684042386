import { FormInstance, Input, Select, Typography } from 'antd'
import { FC, useEffect, useState } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { isMobilePhone } from 'validator'
import { Item } from '..'
import { FormProps } from '../FormItem'
import './PhoneNumber.less'

type ValidateStatus = 'success' | 'warning' | 'error' | 'validating'

type PhoneNumberProps = {
  form?: FormInstance
  onValid?: (clean: string, formatted: string) => void
  onChange?: (formatted: string, clean?: string) => void
} & FormProps

const PhoneNumber: FC<PhoneNumberProps> = ({
  disabled = false,
  placeholder = '(xxx) xxx-xxxx',
  errorMessage,
  enableTab = true,
  label = 'Phone Number',
  name = 'phoneNumber',
  className,
  rules,
  onValid,
  onChange,
  form,
  ...props
}) => {
  const itemKey = `phone-input-item-${typeof name === 'string' || typeof name === 'number' ? name : name.join('-')}`

  return (
    <div className={withPrefix('phone-form-container, form-item')}>
      <Item
        label={label}
        name={name}
        key={itemKey}
        required={props.required}
        rules={[
          {
            validator: (_, value: string) => {
              if (!props.required && (!value || value.length === 0)) return Promise.resolve()
              value = value.replace(/[^\d|\+]/g, '')
              return value && value.length >= 10 && isMobilePhone(value, false, { strictMode: false })
                ? Promise.resolve()
                : Promise.reject()
            },
            required: props.required,
            message: errorMessage ?? 'Please enter a valid phone number',
          },
        ]}
        className={withPrefix('phone-form-input-wrapper', className ? className : '')}
        {...props}
      >
        <Input disabled={disabled} className={withPrefix('phone-form-input')} />
      </Item>
    </div>
  )
}

export default PhoneNumber
