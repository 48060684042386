import moment from 'moment'
import ics from 'ics'

export const isNumeric = (str: string) => {
  if (typeof str != 'string') return false // we only process strings!
  return !isNaN(Number(str)) && !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const Capitalize = (str?: string, all: boolean = false) => {
  if (!str) return ''
  if (all) {
    const list = str.split(' ')
    return list.map((item) => Capitalize(item)).join(' ')
  } else {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}

export const TitleCase = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1')
  return Capitalize(result)
}
export const CamelCase = (str: string) => {
  return str.toLowerCase().replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
}

export const SnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export const DashCase = (str: string) => {
  return str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`)
}

export const Pluralize = (count: number, noun: string | string[], suffix: string = 's') => {
  if (Array.isArray(noun)) {
    return `${count === 1 ? noun[0] : noun[1]}`
  } else {
    return `${noun}${count !== 1 ? suffix : ''}`
  }
}

export const StripHtmlTags = (data: string = '') => {
  const regex = /(<([^>]+)>)/gi
  return data.replace(regex, '')
}

export const isValidEmail = (value: string) => {
  if (!value) return false
  const reg = /^\w+([\.+-]?\w+)*@\w+([\.+-]?\w+)*(\.\w{2,3})+$/
  return reg.test(value)
}

export const countLines = (target: Element) => {
  var style = window.getComputedStyle(target, null);
  var height = parseInt(style.getPropertyValue("height"));
  var font_size = parseInt(style.getPropertyValue("font-size"));
  var line_height = parseInt(style.getPropertyValue("line-height"));
  var box_sizing = style.getPropertyValue("box-sizing");

  if (isNaN(line_height)) line_height = font_size * 1.2;

  if (box_sizing == 'border-box') {
    var padding_top = parseInt(style.getPropertyValue("padding-top"));
    var padding_bottom = parseInt(style.getPropertyValue("padding-bottom"));
    var border_top = parseInt(style.getPropertyValue("border-top-width"));
    var border_bottom = parseInt(style.getPropertyValue("border-bottom-width"));
    height = height - padding_top - padding_bottom - border_top - border_bottom
  }
  var lines = Math.ceil(height / line_height);
  return lines;
}
export const FormatPhoneNumber = (value: string = '') => {
  if (!value) return ''
  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '')

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`
  }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`
}

export const googleCalLink = (
  title: string,
  dates: [Date | string, Date | string],
  details: string,
  location: string,
) => {
  const dateString = `${moment(dates[0]).format('YYYYMMDDTHHmmSSZ')}/${moment(dates[1]).format('YYYYMMDDTHHmmSSZ')}`

  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
    title,
  )}&dates=${encodeURIComponent(dateString)}&details=${encodeURIComponent(details)}&location=${encodeURIComponent(
    location,
  )}`
}

export const outlookCalLink = (
  title: string,
  dates: [Date | string, Date | string],
  details: string,
  location: string,
) => {
  return `https://outlook.office.com/calendar/0/deeplink/compose?path=/calendar/action/compose&rru=addevent&subject=${encodeURIComponent(
    title,
  )}&startdt=${moment(dates[0]).format('YYYY-MM-DDTHH:mm:SSZ')}&enddt=${moment(dates[1]).format(
    'YYYY-MM-DDTHH:mm:SSZ',
  )}&body=${encodeURIComponent(details)}&location=${location}`
}

export const yahooCalLink = (
  title: string,
  dates: [Date | string, Date | string],
  details: string,
  location: string,
) => {
  const dateString = `${moment(dates[0]).format('YYYYMMDDTHHmmSSZ')}/${moment(dates[1]).format('YYYYMMDDTHHmmSSZ')}`
  return `https://www.google.com/calendar/render?action=TEMPLATE&text=${title}&dates=${dateString}&details=${details}&location=${location}`
}

export const iCalLink = (title: string, dates: [Date | string, Date | string], details: string, location: string) => {
  const start = moment(dates[0])
  const end = moment(dates[1])
  const event = {
    start: [start.year(), start.month() + 1, start.date(), start.hour(), start.minute()],
    end: [end.year(), end.month() + 1, end.date(), end.hour(), end.minute()],
    title: title,
    description: details,
    location: location,
  }

  return new Promise<string>((resolve, reject) => {
    ics.createEvent(event, (error, value) => {
      if (!error) {
        reject(error)
      } else {
        resolve(`data:text/calendar;charset=utf8,${encodeURIComponent(value as string)}`)
      }
    })
  })

  // const icsFile = ics.createEvent()
  // if (!icsFile.error) {
  //   return `data:text/calendar;charset=utf8,${encodeURIComponent(icsFile.value as string)}`
  // }
}
