import { useMemo } from 'react'
import { useMediaQuery } from 'react-responsive'
import { BreakpointMin } from '../components/screen/Breakpoint'
import { usePublicConfig } from '../contexts/Config'

export default function useLoginLogo() {
  const {
    props: { layout, logo },
  } = usePublicConfig()
  const isDesktop = useMediaQuery({ minWidth: BreakpointMin.MD })

  let loginLogo = useMemo(() => {
    const logoVersion = isDesktop ? layout.loginLogo?.desktop : layout.loginLogo?.mobile
    if (!logoVersion) return undefined
    let justify = logoVersion.justify ?? 'left'
    return {
      src: logoVersion.src,
      width: logoVersion.width ?? 260,
      justify
      // justify: justify === 'left' ? 'start' : justify === 'right' ? 'end' : 'center',
    }
  }, [isDesktop])

  return loginLogo
}
