const colorNames = [
  'AliceBlue',
  'AntiqueWhite',
  'Aqua',
  'Aquamarine',
  'Azure',
  'Beige',
  'Bisque',
  'Black',
  'BlanchedAlmond',
  'Blue',
  'BlueViolet',
  'Brown',
  'BurlyWood',
  'CadetBlue',
  'Chartreuse',
  'Chocolate',
  'Coral',
  'CornflowerBlue',
  'Cornsilk',
  'Crimson',
  'Cyan',
  'DarkBlue',
  'DarkCyan',
  'DarkGoldenRod',
  'DarkGray',
  'DarkGreen',
  'DarkKhaki',
  'DarkMagenta',
  'DarkOliveGreen',
  'Darkorange',
  'DarkOrchid',
  'DarkRed',
  'DarkSalmon',
  'DarkSeaGreen',
  'DarkSlateBlue',
  'DarkSlateGray',
  'DarkTurquoise',
  'DarkViolet',
  'DeepPink',
  'DeepSkyBlue',
  'DimGray',
  'DodgerBlue',
  'FireBrick',
  'FloralWhite',
  'ForestGreen',
  'Fuchsia',
  'Gainsboro',
  'GhostWhite',
  'Gold',
  'GoldenRod',
  'Gray',
  'Green',
  'GreenYellow',
  'HoneyDew',
  'HotPink',
  'IndianRed ',
  'Indigo  ',
  'Ivory',
  'Khaki',
  'Lavender',
  'LavenderBlush',
  'LawnGreen',
  'LemonChiffon',
  'LightBlue',
  'LightCoral',
  'LightCyan',
  'LightGoldenRodYellow',
  'LightGrey',
  'LightGreen',
  'LightPink',
  'LightSalmon',
  'LightSeaGreen',
  'LightSkyBlue',
  'LightSlateGray',
  'LightSteelBlue',
  'LightYellow',
  'Lime',
  'LimeGreen',
  'Linen',
  'Magenta',
  'Maroon',
  'MediumAquaMarine',
  'MediumBlue',
  'MediumOrchid',
  'MediumPurple',
  'MediumSeaGreen',
  'MediumSlateBlue',
  'MediumSpringGreen',
  'MediumTurquoise',
  'MediumVioletRed',
  'MidnightBlue',
  'MintCream',
  'MistyRose',
  'Moccasin',
  'NavajoWhite',
  'Navy',
  'OldLace',
  'Olive',
  'OliveDrab',
  'Orange',
  'OrangeRed',
  'Orchid',
  'PaleGoldenRod',
  'PaleGreen',
  'PaleTurquoise',
  'PaleVioletRed',
  'PapayaWhip',
  'PeachPuff',
  'Peru',
  'Pink',
  'Plum',
  'PowderBlue',
  'Purple',
  'Red',
  'RosyBrown',
  'RoyalBlue',
  'SaddleBrown',
  'Salmon',
  'SandyBrown',
  'SeaGreen',
  'SeaShell',
  'Sienna',
  'Silver',
  'SkyBlue',
  'SlateBlue',
  'SlateGray',
  'Snow',
  'SpringGreen',
  'SteelBlue',
  'Tan',
  'Teal',
  'Thistle',
  'Tomato',
  'Turquoise',
  'Violet',
  'Wheat',
  'White',
  'WhiteSmoke',
  'Yellow',
  'YellowGreen',
]
export const IsValidColor = (color: string) => {
  if (!color) return false
  return IsHex(color) || IsRGB(color) || colorNames.some((v) => v.toLowerCase() === color.toLowerCase())
}

export const ToRGB = (str: string) => {
  if (!str) return undefined
  if (IsRGB(str)) return str
  if (IsHex(str)) return HexToRGBString(str)
  return str
}

export const ToCommaSeparatedRGB = (str: string) => {
  let rgbValue: { r: number | string; g: number | string; b: number | string } | null | undefined
  if (IsRGB(str)) {
    rgbValue = extractRGB(str)
  } else if (IsHex(str)) {
    rgbValue = HexToRGB(str)
  }

  return rgbValue ? `${rgbValue.r}, ${rgbValue.g}, ${rgbValue.b}` : undefined
}

export const HexToRGB = (hex: string) => {
  if (!hex) return undefined
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b
  })

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    : null
}

export const extractRGB = (color: string) => {
  const rgbString = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
  const colorSplit = color.split(',')

  if (rgbString) {
    return {
      r: rgbString[1],
      g: rgbString[2],
      b: rgbString[3],
    }
  } else if (colorSplit.length >= 3) {
    return {
      r: colorSplit[0],
      g: colorSplit[1],
      b: colorSplit[2],
    }
  }
}

export const HexToRGBString = (hex: string) => {
  const rgb = HexToRGB(hex)
  return `rgb(${rgb?.r}, ${rgb?.g}, ${rgb?.b})`
}

export const RGBtoHex = (r: number, g: number, b: number) => {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)
}

export const RGBAToHex = (orig) => {
  var a,
    rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
    alpha = Number(((rgb && rgb[4]) || '').trim()),
    hex = rgb
      ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
      (rgb[2] | (1 << 8)).toString(16).slice(1) +
      (rgb[3] | (1 << 8)).toString(16).slice(1)
      : orig

  if (alpha) {
    a = alpha
  } else {
    a = 0o1
  }
  // multiply before convert to HEX
  a = ((a * 255) | (1 << 8)).toString(16).slice(1)
  hex = hex + a

  return '#' + hex
}

export const IsHex = (color: string) => {
  if (color.match(/^#/)) {
    return true
  }

  return color.split(',').length >= 3
}

export const IsRGB = (color: string) => {
  if (typeof color !== 'string') return false
  if (!color) return false
  if (color.match(/^rgb/)) return true
  return color.split(',').length >= 3
}

export const IsRGBA = (color: string) => {
  if (typeof color !== 'string') return false
  if (color.match(/^rgba/)) return true

  return color.split(',').length === 4
}

export const IsLightColor = (color: string) => {
  var rgb
  if (IsRGB(color)) {
    rgb = extractRGB(color)
  } else {
    rgb = HexToRGB(color)
  }
  if (!rgb) return false

  const hsp = Math.sqrt(0.299 * (rgb.r * rgb.r) + 0.587 * (rgb.g * rgb.g) + 0.114 * (rgb.b * rgb.b))

  return hsp > 127.5 ? true : false
}
