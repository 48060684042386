import moment from 'moment'
import React from 'react'
import { InitTags } from 'src/sdk/components/filter/FilterForm'
import { DailySlots, TimeSlotEntity } from 'src/sdk/datasource/scheduler'

/**
 * Converts date object to string, formatted as "YYYY-MM-DDTHH:MM:SS"
 * @param date
 * @param time
 * @param type
 */
export const getFormattedDate: (date: Date, time: Date, type?: 'start' | 'end') => string = (
  date,
  time,
  type = 'start',
): string => {
  const formattedDate = `${date.getFullYear()}-${
    String(date.getMonth()).length === 1 ? '0' + Number(date.getMonth() + 1) : Number(date.getMonth() + 1)
  }-${String(date.getDate()).length === 1 ? '0' + date.getDate() : date.getDate()}`

  const formattedTime = time
    ? `${String(time.getHours()).length === 1 ? '0' + time.getHours() : time.getHours()}:${
        String(time.getMinutes()).length === 1 ? '0' + time.getMinutes() : time.getMinutes()
      }:00`
    : `${type === 'start' ? '00:00:00' : '23:59:00'}`

  return `${formattedDate}T${formattedTime}`
}

export const createInitTagState = (initTags: InitTags): { [key in keyof typeof initTags | string]: Set<React.Key> } => {
  return Object.keys(initTags).reduce(
    (accumulator, current) => ({
      ...accumulator,
      [current]: new Set(initTags[current as keyof typeof initTags]?.map((value: Data.Identified) => value.id)),
    }),
    {},
  )
}

export function slotsByDay(data: TimeSlotEntity[]): DailySlots[] {
  const slots = data.reduce((slots: Record<string, TimeSlotEntity[]>, slot: TimeSlotEntity) => {
    const date = moment(slot.endDateLocal.date).format('dddd, DD MMMM')

    if (!slots[date]) {
      slots[date] = []
    }
    slots[date].push(slot)
    return slots
  }, {})

  return Object.keys(slots).map((date) => ({
    date: moment(date).add(20, 'year'),
    items: slots[date],
  }))
}

export const getLocalTime = (dateString: string | Date) => {
  const date = typeof dateString === 'string' ? dateString : dateString.toISOString()
  return date.substr(-1).toLowerCase() === 'z' ? moment(date).toDate() : moment(date + 'Z').toDate()
}

export const getTimeZone = (country: string, offset: number) => {
  const timeZones = moment.tz.zonesForCountry(country, true)

  if (timeZones && timeZones.length > 0) {
    return timeZones.find((z) => z.offset === (offset < 0 ? offset * -1 : offset * 60))
  }
}
