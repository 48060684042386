import { ConfigProvider } from 'antd'
import { merge } from 'lodash'
import { tz } from 'moment-timezone'
import { FC } from 'react'
import { Helmet } from 'react-helmet'
import { IntlProvider } from 'react-intl'
import { useCompanyConfig, usePublicConfig } from 'src/sdk/contexts/Config'
import { messages } from '../../intl'
import { renderEmpty } from '../components/empty'
import { IntlConfig, PropsConfig } from '../datasource/config'
import { IsRGB, IsRGBA, IsValidColor, RGBAToHex, ToCommaSeparatedRGB, ToRGB } from '../helpers/colors'
import { DefaultTheme } from '../themes/default'

const GenerateCSSProperties = (lessVars: object) => {
  Object.entries(lessVars).map((item) => {
    const key = item[0]
    const value = item[1]
    if (IsRGBA(value) && !value.includes('var(--')) {
      lessVars[key] = RGBAToHex(value)
    }
  })
  const mergedVars = { ...DefaultTheme, ...lessVars }
  const dynamicRGBVars: string[] = []

  const colors = Object.keys(mergedVars)
    .filter((key) => typeof mergedVars[key] === 'string')
    .reduce((acc, key) => {
      let value: string = mergedVars[key]
      const variableName = key.replace('@', '')
      const cssVarValue = value.match(/var\(--(.*?)\)/)

      // property value is referencing another CSS Variable
      if (cssVarValue !== null && cssVarValue.length > 1) {
        const cssVarName = cssVarValue[1]
        // Check if the variable value exists
        // Only address the variables that are referencing an auto generated RGB var
        if (
          !mergedVars[`@${cssVarName}`] &&
          cssVarName.includes('-rgb') &&
          !dynamicRGBVars.some((v) => v === cssVarName)
        ) {
          const cssVarStandardValue = mergedVars[`@${cssVarName.replace('-rgb', '')}`]
          if (cssVarStandardValue) {
            dynamicRGBVars.push(cssVarName)
            acc.push(`--${cssVarName}: ${ToCommaSeparatedRGB(cssVarStandardValue)}`)
          }
        }
      }

      acc.push(`--${variableName}: ${value}`)

      // If the variable is referencing another CSS Variable - replace the value with the actual value
      if (cssVarValue !== null && cssVarValue.length > 1) {
        value = mergedVars[`@${cssVarValue[1].replace('-rgb', '')}`]
      }
      if (
        IsValidColor(value) &&
        !variableName.includes('-rgb') &&
        !dynamicRGBVars.some((v) => v === `${variableName}-rgb`) &&
        !IsRGB(value)
      ) {
        const rgbColor = ToRGB(value)
        if (rgbColor) {
          dynamicRGBVars.push(`${variableName}-rgb`)
          const rgbStrValue = rgbColor.replace('rgb(', '').replace(')', '')
          acc.push(`--${variableName}-rgb: ${rgbStrValue}`)
        }
      }
      return acc
    }, [] as string[])

  return `:root,
          ::after,
          ::before {
      ${colors.join(';')}
    }`
}

const AppProvider: FC = ({ children }) => {
  const { props, intl, analytics, features, lessVars } = usePublicConfig()
  const company = useCompanyConfig()
  const International = IntlConfig.fromJS(intl)
  tz.load({ version: '2021a', links: [], zones: [] })
  const propsConfig = PropsConfig.fromJS(props)

  const fontFaceCSS: any = propsConfig.fontList
    .filter((item) => /\.(ttf|otf|woff|woff2)$/.test(item.href))
    .map((item, index) => {
      const fontName = item.href.split('/').pop()?.split('.')[0]

      return `@font-face {
          font-family: '${fontName}';
          src: url('${item.href}') format('${item.href.split('.').pop()}');
          font-style: normal;
          font-weight: 100 900;
      }`
    })

  return (
    <ConfigProvider
      componentSize={'large'}
      renderEmpty={renderEmpty}
      direction={intl.direction}
      locale={{ locale: intl.locale }}
    >
      <Helmet
        title={`${company.name} Member Portal`}
        async={false}
        meta={[<meta name={'viewport'} content={'width=device-width, initial-scale=1, maximum-scale=1'} />]}
        link={[
          ...propsConfig.fontList,
          {
            href: `/assets/theme/theme-bundle.css`,
            rel: 'stylesheet',
            type: 'text/css',
          },
          {
            href: propsConfig.favicon ? propsConfig.favicon : '/favicon.svg',
            rel: 'icon',
            type: 'image/png',
          },
        ]}
        style={[
          {
            cssText: GenerateCSSProperties(lessVars),
          },
          ...fontFaceCSS.map((item) => ({ cssText: item })),
        ]}
        script={[
          ...(analytics.google
            ? [
                {
                  src: `https://www.googletagmanager.com/gtag/js?id=${analytics.google}`,
                },
                {
                  innerHTML: `window.dataLayer = window.dataLayer || [];
                              function gtag(){dataLayer.push(arguments);}
                              gtag('js', new Date());
                              gtag('config', '${analytics.google}');`,
                },
              ]
            : []),
        ]}
      >
        {features?.appId && (
          <meta
            name='apple-itunes-app'
            content={`app-id=${features.appId}, app-argument=pvapp:///redirect?to=${window.location.pathname}`}
          />
        )}
      </Helmet>
      <IntlProvider
        messages={merge(messages[International.languageLocale], intl.messages[International.languageLocale])}
        defaultLocale={'en'}
        onError={(e) => {
          if (e.message.includes('using id as fallback') || e.message.includes('using default message as fallback')) {
            return
          }
          console.error(e)
        }}
        locale={International.locale}
        timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
        
      >
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export { AppProvider }
