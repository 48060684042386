import {
  useState,
  createContext,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from 'react'
import { usePublicConfig } from 'src/sdk/contexts/Config'
import DebugProps from './DebugProps'
import { HexToRGBString, IsRGB, IsValidColor } from 'src/sdk/helpers/colors'
import DebugWindow from './DebugWindow'
import { DashCase } from 'src/sdk/helpers/strings'
import { DebugAuthProvider } from './DebugAuth'
import './Debug.less'

export type ThemeProp = {
  name: string
  value: string
  isColor: boolean
}

type DebugProps = {
  setValue: (item: ThemeProp, value: string) => void
  reset: () => void
  changes: ThemeProp[]
  setVersion: (versionNo: number) => void
  setCompanyId: (companyId: number) => void
  companyId: number
  getJson: () => string
  version: number
  props?: ThemeProp[]
}

const defaultValues: DebugProps = {
  setValue: (v1, v2) => {},
  reset: () => {},
  changes: [],
  setVersion: (value) => value,
  setCompanyId: (value: number) => value,
  companyId: 0,
  version: 0,
  getJson: () => '',
  props: [],
}

const Debug = createContext(defaultValues)

const ValidColor = (value: string) => IsValidColor(value) || /var\(--(.*?)\)/.test(value)

function DebugProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const { companyId: id, debug, theme } = usePublicConfig()
  const DebugEnabled = process.env.NODE_ENV === 'development'
  const [currentVersion, setCurrentVersion] = useState(0)
  const [changes, setChanges] = useState<ThemeProp[]>([])
  const [companyId, setCompanyId] = useState<number>(typeof id === 'number' ? id : parseInt(id))
  const [active, _setActive] = useState(false)

  const activeRef = useRef(active)
  const setActive = (data) => {
    activeRef.current = data
    _setActive(data)
  }

  useEffect(() => {
    if (!DebugEnabled) return
    const debugWrapper = document.getElementsByClassName('ant-debug-wrapper')[0]

    function escListener(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        setActive(!activeRef.current)
      }
    }

    function clickListener(e: MouseEvent) {
      if (e.target instanceof HTMLElement) {
        if (debugWrapper !== e.target && !debugWrapper?.contains(e.target)) {
          setActive(false)
        }
      }
    }

    window.addEventListener('click', clickListener)
    window.addEventListener('keyup', escListener)

    return () => {
      window.removeEventListener('click', clickListener)
      window.removeEventListener('keyup', escListener)
    }
  }, [])

  const [props, setProps] = useState(
    theme
      ? Object.entries(theme).map(
          ([name, value]) =>
            ({
              name: name,
              value: value,
              isColor: typeof value === 'string' ? ValidColor(value) || /var\(--(.*?)\)/.test(value) : false,
            } as ThemeProp),
        )
      : undefined,
  )

  const reset = () => {
    setProps(
      Object.entries(theme).map(
        ([name, value]) =>
          ({
            name: name,
            value: value,
            isColor: typeof value === 'string' ? ValidColor(value) || /var\(--(.*?)\)/.test(value) : false,
          } as ThemeProp),
      ),
    )
    setChanges([])
    setCurrentVersion(0)
    document.documentElement.removeAttribute('style')
  }

  const getJson = () => {
    const reduced = props?.reduce((acc, item) => {
      return {
        ...acc,
        [DashCase(item.name)]: item.value,
      }
    }, {} as { [key in string]: string })
    return JSON.stringify(reduced, null, 2)
  }

  const setValue = (item: ThemeProp, newValue: string) => {
    setChanges((prevState) => [
      ...prevState,
      {
        ...item,
        value: newValue,
      },
    ])
    setPropertyValue(item, newValue)
    setCurrentVersion(currentVersion + 1)
  }

  const setPropertyValue = (item: ThemeProp, newValue: string) => {
    const isColor = ValidColor(newValue)
    setProps(props?.map((c) => (c.name === item.name ? { ...c, value: newValue, isColor: isColor } : c)))
    const propertyName = DashCase(item.name).replace('@', '--')
    const currentValue = document.documentElement.style.getPropertyValue(propertyName)
    if (currentValue === newValue) return
    document.documentElement.style.setProperty(propertyName, newValue)
    if (isColor) {
      const rgbColor = IsRGB(newValue) ? newValue : HexToRGBString(newValue)
      document.documentElement.style.setProperty(`${propertyName}-rgb`, rgbColor.replace('rgb(', '').replace(')', ''))
    }
  }

  const setVersion = (versionNo: number) => {
    if (versionNo < 0 || versionNo > changes.length - 1) return
    if (versionNo === 0) {
      reset()
    } else {
      const change = changes[versionNo]
      if (change) {
        console.log(change)
        setPropertyValue(change, change.value)
        setCurrentVersion(versionNo)
      }
    }
  }

  return debug || process.env.NODE_ENV === 'development' ? (
    <DebugAuthProvider>
      <Debug.Provider
        value={{
          setValue,
          reset,
          getJson,
          setVersion,
          setCompanyId,
          companyId,
          version: currentVersion,
          changes,
          props,
        }}
      >
        {children}
        {DebugEnabled && <DebugWindow active={active} setActive={setActive} />}
      </Debug.Provider>
    </DebugAuthProvider>
  ) : (
    <>{children}</>
  )
}

const useDebug: () => typeof defaultValues = () => useContext(Debug)

export { DebugProvider, useDebug }
