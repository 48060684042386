import { useCompanyConfig, useIntlFormatter, usePropsConfig } from '../contexts/Config'

export function usePDFDependencies() {
  const intlFormatter = useIntlFormatter()
  const company = useCompanyConfig()
  const props = usePropsConfig()

  return {
    company,
    intlFormatter,
    logo: props.layout.pdfLogo ?? props.logo.standard?.text
  }

}