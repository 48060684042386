import { notification as antNotification, Typography } from 'antd'
import { ArgsProps } from 'antd/lib/notification'
import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'
import { ApiError } from '../api/error'
import IvyIcon from '../components/icon'
import { v4 } from 'uuid'
import '../components/notification/Notification.less'
import { withPrefix } from './Config'
import { VerticalSpace } from '../components/layout'

type ContextProps = {
  error: ApiError | undefined
  notify: Dispatch<SetStateAction<ArgsProps | undefined>>
  notifyOnError: Dispatch<SetStateAction<ApiError | undefined>>
}

const Context = createContext<ContextProps>({
  error: undefined,
  notify: () => undefined,
  notifyOnError: () => undefined,
})

const useNotification: () => ContextProps = () => useContext(Context)

const NotificationProvider: FC = ({ children }) => {
  const [error, notifyOnError] = useState<ApiError>()
  const [notification, notify] = useState<ArgsProps>()
  const [api, context] = antNotification.useNotification()

  useEffect(() => {
    error &&
      error.errorDescription &&
      api.error({
        icon: <IvyIcon type={'symbol/close-circle'} />,
        message: error.title,
        description: (
          <VerticalSpace>
          <span>{error.errorDescription}</span>
          {error.requestId && (
            <span>{`Request ID: ${error.requestId}`}</span>
          )}
          </VerticalSpace>
        ),
      })
  }, [api, error])

  useEffect(() => {
    if (notification) {
      const key = notification.key ?? v4()
      api.open({
        ...notification,
        key: key,
        className: notification.onClick ? withPrefix('notification-has-click') : '',
        duration: 20,
        onClick: () => {
          notification.onClick && notification.onClick()
          antNotification.close(key)
        },
      })
    }
  }, [api, notification])

  return (
    <Context.Provider value={{ error, notifyOnError, notify }}>
      {context}
      {children}
    </Context.Provider>
  )
}

export { NotificationProvider, useNotification }
