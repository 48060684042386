import { Avatar as Wrapped } from 'antd'
import { FC } from 'react'
import { PlaceholderProps } from './index'
import './Avatar.less'
import { withPrefix } from 'src/sdk/contexts/Config'

export type AvatarProps = typeof Wrapped.defaultProps &
  PlaceholderProps & {
    background?: 'primary' | 'warning' | 'danger' | 'info' | 'light' | 'dark' | 'none'
  }

export const Avatar: FC<AvatarProps> = ({
  placeholderSrc = 'empty',
  size = 160,
  text,
  background,
  className = '',
  ...baseProps
}) => {
  return (
    <Wrapped
      className={className?.concat(background ? withPrefix(`avatar-background-${background}`) : '')}
      size={size}
      shape={'circle'}
      style={{
        objectFit: 'cover',
        objectPosition: 'center',
        margin: '0 auto',
        ...baseProps.style,
      }}
      key={baseProps.key}
      src={baseProps.src ?? baseProps.icon ?? placeholderSrc ?? text}
      {...baseProps}
    >
      {text}
    </Wrapped>
  )
}
