import { Typography } from "antd"
import { FC } from "react"
import { CarouselItems } from "src/sdk/components/carousel/Carousel"
import { VerticalSpace } from "src/sdk/components/layout"
import { Title } from "src/sdk/components/text"
import { usePropsConfig, withPrefix } from "src/sdk/contexts/Config"

const SloganCarousel: FC = () => {
    const { slogans } = usePropsConfig()
    const mobileSlogan = slogans.filter(s => s.mobile)
    return (
        <div className={withPrefix('slogan-carousel')}>
            {slogans?.length > 0 && (
                <CarouselItems navigation={false} dots={false} draggable={false} autoplay={true} autoplaySpeed={5000} infinite>
                {slogans.filter((s) => !s.mobileOnly).map((slogan, index) => (
                    <div key={`${slogan.title}-${index}`} className={withPrefix('slogan-carousel-item')}>
                        <div className={withPrefix('slogan-carousel-item-content', slogan.image && (slogan.title || slogan.subtitle) ? 'slogan-carousel-item-content-bg' : '')} style={{ backgroundImage: slogan.image ? `url('${slogan.image}')` : ''}}>
                            <VerticalSpace className={withPrefix('slogan-carousel-item-text')}>
                                <Title style={{ marginBottom: 0}} level={1}>{slogan.title}</Title>
                                <Typography.Paragraph>{slogan.subtitle}</Typography.Paragraph>
                            </VerticalSpace>
                        </div>
                    </div>
                ))}
            </CarouselItems>
            )}
        </div>
    )
}

export default SloganCarousel