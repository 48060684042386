import { useResource } from 'rest-hooks'
import { AccountEntity, QRCode } from './account/account'
import { ApiResource } from './entity'

export type PassEntityType = '' | 'guestpass' | 'reservation' | 'appointment' | 'event' | 'category'

class PassEntity extends ApiResource implements Data.Identified, Data.Named {
  readonly id: string = ''
  readonly scanCode: string = ''
  readonly scanFormat: string = ''
  readonly redemptionPin: string = ''
  readonly name: string = ''
  readonly description: string = ''
  readonly finePrint: string = ''
  readonly graphic: string = ''
  readonly foregroundColor: string = ''
  readonly backgroundColor: string = ''
  readonly allow_sending: boolean = false
  readonly referenceType: PassEntityType = ''
  readonly dateCutoff: Date = new Date()
  readonly value: number = 0
  readonly transferable: boolean = false
  readonly transferred: boolean = false

  get image(): string {
    return this.graphic
  }

  get images(): [string] {
    return [this.graphic]
  }
}

export type GuestPassStatus = '' | 'active' | 'archived' | 'redeemed' | 'registered'
export type PassOwner = {
  firstName: string
  lastName: string
}

class GuestPassResource extends ApiResource {
  static readonly urlRoot = `/api/passes/guest-pass`
  readonly id: Data.ID = ''
  readonly passId: number = 0
  readonly ownerId: number = 0
  readonly serial: string = ''
  readonly expiresOn: Date = new Date()
  readonly isExpired: boolean = false
  readonly validOn: Date = new Date()
  readonly status: GuestPassStatus = ''
  readonly pass: PassEntity = PassEntity.fromJS({})
  readonly customer: AccountEntity = AccountEntity.fromJS()
  readonly createdOn: Date = new Date()
  readonly transferedOn: Date = new Date()
  readonly passOwner?: PassOwner

  static getById(params: Data.Identified): GuestPassResource {
    return useResource(this.detail(), params)
  }

  get image(): string {
    return this.pass.graphic
  }

  get images(): [string] {
    return [this.pass.graphic]
  }
}

export { PassEntity, GuestPassResource }
