import {
  useEffect,
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react'
import Cookies from 'js-cookie'
import { AccountEntity, AdminEntity } from '../../datasource/account/account'
import { UserCredentials } from '../../datasource/token'
import { useController } from 'rest-hooks'
import { CompanyEntity } from 'src/sdk/datasource/company'

type AdminAuthType = 'company' | 'user_company'

type ContextProps = {
  switchCompany: (companyId: number) => Promise<CompanyEntity>
  authenticate: (credentials: UserCredentials) => Promise<AdminEntity>
  authenticated: boolean
  logout: () => void
  adminUser?: AdminEntity
  authType?: AdminAuthType
}

const defaultValues = {
  switchCompany: (value) => value,
  authenticate: (value) => value,
  authenticated: false,
  logout: () => {},
  authType: undefined,
}

const DebugAuth = createContext<ContextProps>(defaultValues)

const GetAuthCookie = () => {
  return Cookies.get('pv.admin.session_id')
}

function DebugAuthProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const { fetch } = useController()
  const [authType, setAuthType] = useState<AdminAuthType>()
  const [adminUser, setAdminUser] = useState<AdminEntity>()
  const [authCookie, setAuthCookie] = useState<string | undefined>(GetAuthCookie())
  const [loggedIn, setLoggedIn] = useState<AdminEntity | undefined>()

  const logout = async () => {
    await AccountEntity.logout()
    setAuthCookie(undefined)
    setLoggedIn(undefined)
  }

  useEffect(() => {
    if (authCookie && !loggedIn) {
      console.log('authCookie, not logged in', authCookie)
    }
  }, [])

  const Authenticate = async (credentials: UserCredentials) => {
    const response = await fetch(AdminEntity.login(), credentials)
    response && setLoggedIn(response)
    return response
  }

  const SwitchCompany = (companyId: number) => {
    return fetch(AdminEntity.switchCompany(), { companyId: companyId }).then((response) => {
      setAuthType('user_company')
      return response
    })
  }

  useEffect(() => {
    if (loggedIn !== undefined) {
      setAuthCookie(GetAuthCookie())
    }
  }, [loggedIn])

  return (
    <DebugAuth.Provider
      value={{
        switchCompany: SwitchCompany,
        authenticate: Authenticate,
        authenticated: !!authCookie,
        adminUser,
        logout,
        authType,
      }}
    >
      {children}
    </DebugAuth.Provider>
  )
}

function useDebugAuth(): ContextProps {
  return useContext(DebugAuth)
}

export { DebugAuthProvider, DebugAuth, useDebugAuth }
