import { Col, List, Row, Typography } from 'antd'
import React, { PropsWithChildren } from 'react'
import { FC, ReactNode } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { ApiResource } from 'src/sdk/datasource/entity'
import Card from '../card'
import { Image } from 'src/sdk/components/image'
import { Flex, HorizontalSpace } from '../layout/Grid'
import { Result } from '../result/Result'
import DrawerDetailLoader from './DrawerDetailLoader'
import { DetailListProps, DetailProps, DynamicComponentType } from './DrawerDetailTypes'
import AddToCalendar from '../calendar/AddToCalendar'
import { PrintButton } from './PrintButton'
import IvyIcon from '../icon'
import { ListItemMeta } from '../list/ListItem'
import { useMediaQuery } from 'react-responsive'
import './DrawerDetail.less'

const ResolveListData = (data: DetailListProps[]) => {
  return data
    .filter((item) => !!item.title)
    .map((item) => ({
      ...item,
      avatar: item.icon && <IvyIcon size={16} type={item.icon} />,
    }))
}

const ChildWithProp = (child: ReactNode, props: Partial<any> = {}) => {
  if (React.isValidElement(child)) {
    const children = child.props.children
    return React.cloneElement(child, { ...children, ...props })
  }
}

function DrawerDetail<T extends ApiResource>({ loading, data, header, main, card, onError }: DetailProps<T>) {
  const isMobile = useMediaQuery({ maxWidth: 768 })

  const ResolveSection: FC<
    PropsWithChildren<{ section: DynamicComponentType; render?: (data: any, index: number) => ReactNode }>
  > = ({ section, render, children }) => {
    const resolved = typeof section === 'function' ? section(data) : section

    return Array.isArray(resolved) && render
      ? resolved.map((item, index) => render(item, index))
      : typeof resolved === 'string'
      ? ChildWithProp(children, {
          children: resolved,
        })
      : resolved
  }

  return loading ? (
    <DrawerDetailLoader />
  ) : data ? (
    <Row gutter={[32, 32]} className={'drawer-detail'} style={{ justifyContent: 'center' }}>
      {header && (
        <Col span={!!card || isMobile ? 24 : 12} order={0}>
          <ResolveSection section={header.title}>
            <Typography.Title level={2} />
          </ResolveSection>
          {header.subTitle && (
            <ResolveSection section={header.subTitle}>
              <Typography.Title level={5} type={'secondary'} />
            </ResolveSection>
          )}
        </Col>
      )}

      {card && (
        <Col span={24} order={1} md={{ span: 9, order: 3 }}>
          <Card
            type={'primary'}
            className={withPrefix('drawer-detail-card')}
            title={
              <HorizontalSpace align={'center'} justify={card.title ? 'space-between' : 'right'}>
                {card.title && (
                  <Typography.Title level={5} style={{ marginBottom: 0 }}>
                    {card.title}
                  </Typography.Title>
                )}
                {card.headerActions && (
                  <Flex
                    direction={'horizontal'}
                    justify={'right'}
                    size={8}
                    className={withPrefix('drawer-detail-icon-buttons')}
                  >
                    {card.headerActions.print && card.headerActions.print(data) && (
                      <PrintButton key={`drawer-details-print-${data?.id}`} template={card.headerActions.print(data)} />
                    )}
                    {card.headerActions.calendar && (
                      <AddToCalendar
                        key={`drawer-details-calendar-${data?.id}`}
                        buttonType={'icon'}
                        {...card.headerActions.calendar(data)}
                      />
                    )}
                  </Flex>
                )}
              </HorizontalSpace>
            }
            description={card.description}
            cover={card.cover}
            shape='square'
          >
            {card.list && (
              <List
                bordered={false}
                split={false}
                itemLayout={'vertical'}
                className={withPrefix('drawer-detail-card-list')}
                dataSource={
                  typeof card.list === 'function' ? ResolveListData(card.list(data)) : ResolveListData(card.list)
                }
                renderItem={(props, index) => (
                  <ListItemMeta
                    key={`drawer-detail-card-list-item-${index}`}
                    title={props.title}
                    description={props.description}
                    icon={props.avatar}
                  />
                )}
              />
            )}
            {card.footerButtons && (
              <Flex direction={'vertical'} size={16}>
                {card.footerButtons(data).map((item) => item)}
              </Flex>
            )}
          </Card>
        </Col>
      )}

      <Col span={24} order={2} md={{ span: 15, order: 2 }}>
        <Flex direction={'vertical'} size={16}>
          {header.image && header.image(data).src && (
            <div className={withPrefix('drawer-detail-image')}>
              <Image src={header.image(data).src} />
            </div>
          )}
          {typeof main === 'function' ? main(data) : main}
        </Flex>
      </Col>
    </Row>
  ) : onError ? (
    <ResolveSection section={onError}>
      <Typography.Title level={5} type={'secondary'} />
    </ResolveSection>
  ) : (
    <Result.NotFound />
  )
}

export { DrawerDetail }
