import { Col, Row, Typography } from 'antd'
import { FC } from 'react'
import { FormattedDate } from 'react-intl'
import { useDLE } from 'rest-hooks'
import { VerticalSpace } from 'src/sdk/components/layout'
import { SectionLoader } from 'src/sdk/components/loader'
import { Result } from 'src/sdk/components/result/Result'
import { Tag } from 'src/sdk/components/tag'
import { useCompanyConfig } from 'src/sdk/contexts/Config'
import { OrderEntity } from 'src/sdk/datasource/order'
import { OrderDetails, StatementSummaryList } from './OrderDetails'

interface IOrder {
  id: Data.ID
  includePayments?: boolean
}

const Order: FC<IOrder> = ({ id, includePayments = true }) => {
  const { data, loading } = useDLE(OrderEntity.detail(), { id: id })
  const company = useCompanyConfig()

  const orderTransactionNo = (order: OrderEntity) => [
    {
      id: 1,
      title: 'Order No',
      description: id,
    },
    {
      id: 3,
      title: 'Status',
      description:
        order.status === 'completed' ? (
          <Tag size='small' type='primary'>
            {order.status}
          </Tag>
        ) : order.status === 'processing' ? (
          <Tag size='small' type='processing'>
            {order.status}
          </Tag>
        ) : (
          <Tag size='small' type='error'>
            {order.status}
          </Tag>
        ),
    },
  ]

  const orderStatus = (order: OrderEntity) => [
    {
      id: 4,
      title: 'Placed On',
      description: <FormattedDate value={`${order.createdOn}Z`} />,
    },
    {
      id: 5,
      title: 'Via',
      description: company.name,
    },
  ]

  return loading ? (
    <SectionLoader />
  ) : data ? (
    <VerticalSpace size={16}>
      <Row>
        <Col span={24}>
          <Typography.Title level={2}>Your Order </Typography.Title>
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={50}>
        <Col xs={{ span: 0 }} lg={{ span: 11 }}>
          <StatementSummaryList rowKey={(item) => `${item.id}`} dataSource={orderTransactionNo(data)} />
        </Col>
        <Col xs={{ span: 0 }} lg={{ span: 11 }}>
          <StatementSummaryList rowKey={(item) => `${item.id}`} dataSource={orderStatus(data)} />
        </Col>
        <Col xs={{ span: 24 }} lg={{ span: 0 }}>
          <StatementSummaryList
            rowKey={(item) => `${item.id}`}
            dataSource={[...orderTransactionNo(data), ...orderStatus(data)]}
          />
        </Col>
      </Row>
      <Row justify={'space-between'} gutter={50}>
        <Col xs={{ span: 24 }} lg={{ span: 11 }}>
          {data.hasProducts && data.shippingAddress && (
            <StatementSummaryList
              rowKey={(item) => `${item.id}`}
              header={'Delivery Information'}
              dataSource={[
                {
                  id: 6,
                  title: 'Name',
                  description: data.shippingAddress.fullName(),
                },
                {
                  id: 7,
                  title: 'Method',
                  description: data.shippingAddress.type,
                },
                {
                  id: 8,
                  title: 'Address',
                  description: (
                    <Typography.Paragraph>
                      <Typography.Text>{data.shippingAddress.address}</Typography.Text>
                      <br />
                      <Typography.Text>
                        {data.shippingAddress.city}, {data.shippingAddress.state} {data.shippingAddress.zipCode}
                      </Typography.Text>
                    </Typography.Paragraph>
                  ),
                },
              ]}
            />
          )}
        </Col>
      </Row>
      <OrderDetails order={data} includePayments={includePayments} />
    </VerticalSpace>
  ) : (
    <Result.NotFound />
  )
}

export default Order
