import { FC } from 'react'
import { withPrefix } from 'src/sdk/contexts/Config'
import { Card, CardProps } from './Card'
import './CheckableCard.less'

export type CheckableCardProps = {
  isChecked?: boolean
  enabled?: boolean
  enableUncheck?: boolean
  onChange: (checked: boolean) => void
  radio?: boolean
} & Partial<CardProps>

export const CheckableCard: FC<CheckableCardProps> = ({
  isChecked = false,
  onChange,
  enabled = true,
  enableUncheck = true,
  radio = false,
  className,
  children,
  ...props
}) => {
  const handleChange = () => {
    if (isChecked) {
      enableUncheck && onChange(false)
    } else {
      onChange(true)
    }
  }
  return (
    <Card
      className={[
        className,
        withPrefix(
          'card-checkable',
          `${isChecked ? 'card-checkable-checked' : ''}`,
          !enabled ? 'card-checkable-disabled' : '',
          radio ? 'card-checkable-with-radio' : '',
        ),
      ].join(' ')}
      onClick={enabled ? handleChange : undefined}
      {...props}
    >
      {children}
      {radio && (
        <div className={withPrefix('card-checkable-radio', isChecked ? 'card-checkable-radio-selected' : '')} />
      )}
    </Card>
  )
}
