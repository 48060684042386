const RoutingNumbers = [
  {
    bankName: 'ally-bank',
    routingNumbers: ['124003116'],
  },
  {
    bankName: 'bank-of-america',
    routingNumbers: [
      '051000017',
      '051000017',
      '082000073',
      '122101706',
      '121000358',
      '123103716',
      '011900571',
      '054001204',
      '031202084',
      '061000052',
      '051000017',
      '073000176',
      '123103716',
      '071214579',
      '101100045',
      '051000017',
      '051000017',
      '011000138',
      '052001633',
      '011200365',
      '072000805',
      '071214579',
      '051000017',
      '053000196',
      '051000017',
      '051000017',
      '051000017',
      '011400495',
      '011400495',
      '021200339',
      '021200339',
      '107000327',
      '122400724',
      '122400724',
      '021000322',
      '051000017',
      '103000017',
      '323070380',
      '031202084',
      '011500010',
      '053904483',
      '051000017',
      '064000020',
      '051000017',
      '051000017',
      '051000017',
      '125000024',
      '051000017',
      '051000017',
      '051000017',
    ],
  },
  {
    bankName: 'truist',
    routingNumbers: [
      '062203984',
      '054001547',
      '263191387',
      '061113415',
      '083974289',
      '083900680',
      '055003308',
      '053101121',
      '031204710',
      '042102267',
      '031309123',
      '053201607',
      '064208165',
      '111017694',
      '051404260',
      '051503394',
    ],
  },
  {
    bankName: 'bbva-compass',
    routingNumbers: [
      '062001186',
      '122105744',
      '321170538',
      '107005319',
      '063013924',
      '107000783',
      '062001186',
      '113010547',
    ],
  },
  {
    bankName: 'capital-one-bank',
    routingNumbers: ['051405515'],
  },
  {
    bankName: 'citibank',
    routingNumbers: [
      '321171184',
      '322271724',
      '221172610',
      '021272655',
      '266086554',
      '271070801',
      '052002166',
      '221172610',
      '322271724',
      '021272655',
      '021000089',
      '021272655',
      '113193532',
      '254070116',
    ],
  },
  {
    bankName: 'citizens-bank',
    routingNumbers: [
      '211170114',
      '031101143',
      '211070175',
      '241070417',
      '011401533',
      '036076150',
      '021313103',
      '021313103',
      '241070417',
      '036076150',
      '011500120',
      '021313103',
    ],
  },
  {
    bankName: 'fifth-third-bank',
    routingNumbers: ['263190812', '053100737', '064103833'],
  },
  {
    bankName: 'hsbc-bank-usa',
    routingNumbers: ['021001088'],
  },
  {
    bankName: 'jp-morgan-chase-bank',
    routingNumbers: [
      '122100024',
      '322271627',
      '102001017',
      '021100361',
      '267084131',
      '061092387',
      '123271978',
      '071000013',
      '083000137',
      '065400137',
      '072000326',
      '021202337',
      '322271627',
      '044000037',
      '325070760',
      '111000614',
      '124001545',
      '325070760',
      '075000019',
      '051900366',
    ],
  },
  {
    bankName: 'keybank',
    routingNumbers: [
      '125200879',
      '307070267',
      '041001039',
      '124101555',
      '041001039',
      '011200608',
      '041001039',
      '123002011',
      '124000737',
      '211672531',
      '125000574',
    ],
  },
  {
    bankName: 'navy-federal-credit-union',
    routingNumbers: ['256074974'],
  },
  {
    bankName: 'pnc-bank',
    routingNumbers: [
      '031100089',
      '054000030',
      '071921891',
      '083000108',
      '041000124',
      '071921891',
      '031207607',
      '043000096',
      '043000096',
      '031312738',
      '031300012',
      '043300738',
      '031000053',
      '043000096',
      '071921891',
    ],
  },
  {
    bankName: 'regions-bank',
    routingNumbers: [
      '062000019',
      '082000109',
      '063104668',
      '061101375',
      '073900438',
      '071122661',
      '074014213',
      '083901744',
      '065403626',
      '081001387',
      '065305436',
      '053012029',
      '053201814',
      '064000017',
      '111900785',
      '051009296',
    ],
  },
  {
    bankName: 'td-bank',
    routingNumbers: [
      '011103093',
      '031201360',
      '067014822',
      '211370545',
      '054001725',
      '211274450',
      '053902197',
      '011400071',
      '031201360',
      '036001808',
      '211370545',
      '053902197',
      '054001725',
      '011600033',
    ],
  },
  {
    bankName: 'us-bank',
    routingNumbers: [
      '082000549',
      '122105155',
      '123103729',
      '074900783',
      '101000187',
      '091000022',
      '092900383',
      '091300023',
      '104000029',
      '107002312',
      '121201694',
      '123000220',
      '091000022',
      '091408501',
      '064000059',
      '124302150',
      '125000105',
      '075000022',
      '307070115',
    ],
  },
  {
    bankName: 'wells-fargo-bank',
    routingNumbers: [
      '125200057',
      '062000080',
      '111900659',
      '122105278',
      '121042882',
      '102000076',
      '021101108',
      '054001220',
      '031100869',
      '063107513',
      '061000227',
      '121042882',
      '073000228',
      '124103799',
      '071101307',
      '074900275',
      '101089292',
      '121042882',
      '121042882',
      '121042882',
      '055003201',
      '121042882',
      '091101455',
      '091000019',
      '121042882',
      '062203751',
      '092905278',
      '053000219',
      '091300010',
      '104000058',
      '121042882',
      '021200025',
      '107002192',
      '321270742',
      '026012881',
      '041215537',
      '121042882',
      '123006800',
      '031000503',
      '121042882',
      '053207766',
      '091400046',
      '064003768',
      '124002971',
      '051400549',
      '121042882',
      '125008547',
      '075911988',
      '121042882',
      '102301092',
    ],
  },
]

const ValidateRouting = (routingNumber: string) => {
  return new Promise<string | undefined>((resolve, reject) => {
    if (!routingNumber || routingNumber.length < 9) {
      reject()
      return
    }
    const multipliers = [3, 7, 1, 3, 7, 1, 3, 7, 1]
    const checkSum = routingNumber
      .split('')
      .map((v, index) => Number(v) * multipliers[index])
      .reduce((prev, curr) => prev + curr)

    if (checkSum % 10 === 0) {
      const bank = RoutingNumbers.find((bank) => bank.routingNumbers.includes(routingNumber))
      resolve(bank?.bankName)
    } else {
      reject('Invalid Routing Number Provided')
    }
  })
}
export { RoutingNumbers, ValidateRouting }
