import { useResource } from 'rest-hooks'
import { ApiResource } from 'src/sdk/datasource/entity'

export type DayOfWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday'
export const DaysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const

export class Country extends ApiResource implements Data.Named {
  static readonly urlRoot = `/api/static/countries`
  readonly name: string = ''
  readonly abbreviation: string = ''
  readonly alpha3: string = ''
  readonly isoCode: string = ''
  readonly currency: string = ''
  readonly phoneCode: string = ''
  readonly postalValidator?: string

  static getAll(): Country[] {
    return useResource(this.list(), {})
  }

  pk(): string {
    return this.abbreviation
  }
}

export class State extends ApiResource implements Data.Named {
  static readonly urlRoot = `/api/static/countries/us/states`
  readonly name: string = ''
  readonly abbreviation: string = ''

  static getAll() {
    return useResource(this.list(), {})
  }

  pk(): string {
    return this.abbreviation
  }
}
