import React, {
  Dispatch,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'

export type FilterContextType = {
  filterQuery: Record<string, any>
  didSearch: boolean
  setFilterQuery: Dispatch<SetStateAction<Record<string, any>>>
  setDidSearch: Dispatch<SetStateAction<boolean>>
}

const defaultValues: FilterContextType = {
  filterQuery: {},
  didSearch: false,
  setFilterQuery: (value) => value,
  setDidSearch: (value) => value,
}

export const FilterContext = React.createContext<FilterContextType>(defaultValues)

export function FilterProvider({ children }: PropsWithChildren<ReactNode>): ReactElement {
  const [filterQuery, setFilterQuery] = useState<Record<string, any>>({})
  const [didSearch, setDidSearch] = useState(false)

  useEffect(() => {
    if (filterQuery && Object.values(filterQuery).some((v) => v !== undefined || v !== null || v.length > 0)) {
      setDidSearch(true)
    }
  }, [filterQuery])

  return (
    <FilterContext.Provider
      value={{
        filterQuery,
        didSearch,
        setFilterQuery,
        setDidSearch,
      }}
    >
      {children}
    </FilterContext.Provider>
  )
}

export const useFilter: () => FilterContextType = () => useContext(FilterContext)
