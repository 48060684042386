import { Resource } from '@rest-hooks/rest'
import { useResource } from 'rest-hooks'
import { PageRequest } from 'src/sdk/api/request'
import { AccountEntity } from './account/account'
import { ApiResource } from './entity'
import { Passbook } from './passbook'
import { PaymentMethod } from './payment'
import { ScanData } from './scanData'
import { SubscriptionEntity } from './subscription'
import { TransactionEntity, TransactionQuery } from './transaction'

export type MembershipType = '' | 'add-on' | 'id' | 'subscription'

export type MembershipStatusType = 'active' | 'inactive'

class MembershipQuery extends PageRequest {
  readonly type: MembershipType = ''
}

class MembershipEntity extends ApiResource {
  static urlRoot = `/api/memberships`
  readonly id: string = ''
  readonly status: MembershipStatusType = 'active'
  readonly summary: string = ''
  readonly title: string = ''
  readonly details: string = ''
  readonly type: MembershipType = ''
  readonly backgroundColor: string = ''
  readonly foregroundColor: string = ''
  readonly surveyId: number = 0

  pk(): string | undefined {
    return this.id
  }

  static getAll(params: Partial<MembershipQuery> = {}): MembershipEntity[] {
    return useResource(this.list(), params)
  }
}

export type MembershipCardStatusType = '' | 'active' | 'cancelled' | 'expired' | 'failed' | 'pending' | 'archived'

class AbstractMembershipCard extends ApiResource {
  readonly membershipId: number = 0
  readonly customerId: number = 0
  readonly cardId: number = 0
  readonly status: MembershipCardStatusType = ''
  readonly expires: boolean = true
  readonly active: boolean = true
  readonly expirationDate: Date = new Date(0)
  readonly parentMembershipCardId: number = 0
  readonly maxInvites: number = 0
  readonly remainingInvites: number = 0
  readonly canInvite: boolean = false
  readonly barcode: string = ''
  readonly pkPass: string = ''
  readonly passbook: Passbook = Passbook.fromJS()
  readonly title?: string
  readonly summary?: string
  readonly type?: MembershipType
  readonly membership: MembershipEntity = MembershipEntity.fromJS({})
  readonly subscriptionId: number = 0
  readonly subscription?: SubscriptionEntity = SubscriptionEntity.fromJS()
  readonly customer: Omit<AccountEntity, 'isMember'> = AccountEntity.fromJS()
  readonly scanData: ScanData = ScanData.fromJS()
  readonly theme: {
    backgroundColor: string
    foregroundColor: string
    logo?: string
  } = {
      backgroundColor: '',
      foregroundColor: '',
    }
  // readonly subMembershipCards: SubMembershipCard[] = []

  // get frequency(): string {
  //   if (this.subscription) {
  //     if (this.subscription.frequency === 'annually') {
  //       return 'annual'
  //     } else {
  //       return this.subscription.frequency
  //     }
  //   }
  //   return ''
  // }

  get download(): Promise<Response> {
    return fetch(this.pkPass, {
      method: 'GET',
    })
  }

  get period(): string {
    switch (this.subscription?.frequency) {
      case 'daily':
        return 'day'
      case 'weekly':
        return 'week'
      case 'monthly':
        return 'month'
      case 'quarterly':
        return 'quarter'
      case 'semi':
        return '6 months'
      case 'annually':
        return 'year'
      default:
        return ''
    }
  }

  get isSubMember(): boolean {
    return this.parentMembershipCardId !== 0
  }
  static getSubscriptionMemberships(membershipCards: MembershipCardEntity[]): MembershipCardEntity[] {
    return membershipCards.filter((m) => m.type === 'subscription')
  }

  static getAddOnMemberships(membershipCards: MembershipCardEntity[]): MembershipCardEntity[] {
    return membershipCards.filter((m) => m.type === 'add-on')
  }

  static getAll(params: Partial<Data.Paginated & MembershipQuery> = {}): MembershipCardEntity[] {
    return useResource(this.list(), params)
  }

  static getById(params: Data.Identified): MembershipCardEntity {
    return useResource(this.detail(), params)
  }
}

export type SubMemberCreateProps = {
  id?: string | number | undefined
  first_name: string
  last_name: string
  email: string
  mobileNumber: string
  profile_photo?: string | undefined
}

type MembershipCardSendResponse = {
  success: boolean
  message: string
}

class SubMembershipCard extends ApiResource {
  static urlRoot = `/api/account/memberships/:parentCardId/members/:id`
  readonly id: Data.ID = 0
  readonly status: MembershipCardStatusType = ''
  readonly customerId: number = 0
  readonly graphic: string = ''
  readonly title: string = ''
  readonly type?: MembershipType
  readonly membershipId: number = 0
  readonly subscriptionId?: number
  readonly cardId: string = ''
  readonly serial: string = ''
  readonly parentCardId: number = 0
  readonly barcode: string = ''
  readonly passbook: Passbook = Passbook.fromJS()
  readonly expires: boolean = false
  readonly expirationDate: Date = new Date()
  readonly createdOn: Date = new Date()
  readonly customer: Omit<AccountEntity, 'isMember'> = AccountEntity.fromJS()

  static schema = {
    customer: AccountEntity
  }

  static get key() {
    return 'sub-members'
  }

  static listUrl(params: Record<string, any>) {
    return this.url(params)
  }

  static url(params: Record<string, any>) {
    let url = SubMembershipCard.urlRoot
    Object.keys(params).forEach(key => {
      url = url.replace(`:${key}`, params[key])
    })

    return url.replaceAll(/(\/:\w+)/g, '')
  }

  static getAll<T extends typeof Resource>(this: T) {
    const endpoint = this.list()
    return endpoint.extend({
      key(parentCardId: Data.ID) {
        return SubMembershipCard.url({ parentCardId: parentCardId })
      },
      fetch: (parentCardId: Data.ID) => {
        return endpoint.fetch.call(
          endpoint,
          { parentCardId: parentCardId },
        )
      },
      schema: [SubMembershipCard],
    })
  }

  static update<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return this.partialUpdate().extend({
      // key(parentCardId: Data.ID) {
      //   return SubMembershipCard.url({ parentCardId: parentCardId })
      // },
      fetch: (parentCardId: Data.ID, props: SubMemberCreateProps) => {
        return endpoint.fetch.call(
          endpoint,
          {
            parentCardId: parentCardId,
            id: props.id
          },
          props
        )
      },
      schema: SubMembershipCard,
    })
  }
  static add<T extends typeof Resource>(this: T) {
    const endpoint = this.create()
    const list = this.list()
    return this.create().extend({
      fetch: (parentCardId: Data.ID, props: SubMemberCreateProps) => {
        return endpoint.fetch.call(
          endpoint,
          {
            parentCardId: parentCardId
          },
          props
        )
      },
      schema: SubMembershipCard,
      update: (id: Data.ID, parentCardId: Data.ID) => {
        return ({
          [list.key(SubMembershipCard.url({ parentCardId: parentCardId }))]: (resourceIds: string[] = []) => [...resourceIds, id],
        })
      },
    })
  }

  static Remove<T extends typeof Resource>(this: T) {
    const endpoint = this.partialUpdate()
    return endpoint.extend({
      fetch: (parentCardId: Data.ID, id: Data.ID) => {
        return endpoint.fetch.call(
          endpoint.extend({
            method: 'DELETE'
          }),
          {
            parentCardId: parentCardId,
            id: id
          },
          null
        )
      },
      schema: SubMembershipCard
    })
  }
}

class MembershipCardEntity extends AbstractMembershipCard {
  static urlRoot = `/api/account/memberships`
  static async send(params: Data.Identified, action: string): Promise<MembershipCardSendResponse> {
    let endpoint = `${this.urlRoot}/${params.id}/send`
    return await super
      .fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify({
          action: action,
        }),
      })
      .then<MembershipCardSendResponse>()
  }

  static subscription<T extends typeof Resource>(this: T) {
    const endpoint = SubscriptionEntity.detail()
    return endpoint.extend({
      fetch: (id: Data.ID) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}/subscription`,
          }),
          {},
        )
      },
      schema: SubscriptionEntity,
    })
  }

  static payments<T extends typeof Resource>(this: T) {
    const endpoint = TransactionEntity.list()
    return endpoint.extend({
      fetch: (id: Data.ID, params: TransactionQuery = {}) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}/payments`,
          }),
          params,
        )
      },
      schema: [TransactionEntity],
    })
  }

  static pay<T extends typeof Resource>(this: T) {
    const endpoint = TransactionEntity.create()
    return endpoint.extend({
      fetch: (id: Data.ID, payment_method: PaymentMethod) => {
        return endpoint.fetch.call(
          endpoint.extend({
            url: () => `${this.urlRoot}/${id}/payments`,
          }),
          null,
          payment_method,
        )
      },
      schema: TransactionEntity,
    })
  }
}

AbstractMembershipCard.schema = {
  // subscription: SubscriptionEntity,
}

export { MembershipCardEntity, MembershipEntity, SubMembershipCard }

